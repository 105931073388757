import React, { useEffect, useState } from "react";

const FullscreenContext = React.createContext();
export default FullscreenContext;

export const FullscreenProvider = props => {
  const [isFullscreen, setIsFullscreen] = useState();

  useEffect(() => {
    if (isFullscreen !== undefined) {
      if (!isFullscreen) {
        exitFullscreen();
      } else {
        enterFullscreen(document.getElementById("trackmap"));
      }
    }
  }, [isFullscreen]);

  const enterFullscreen = element => {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    }
    document.getElementById("trackmap").classList.add("is-fullscreen");
  };

  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
    document.getElementById("trackmap").classList.remove("is-fullscreen");
  };

  return (
    <FullscreenContext.Provider
      value={{
        isFullscreen,
        setIsFullscreen
      }}
    >
      {props.children}
    </FullscreenContext.Provider>
  );
};
