import React from "react";

import { useNavigate } from "react-router-dom";
import Button from "../../../Trackalyze/Button";

const TracksInfoButton = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      disabled={!props.trackItems.length}
      icon
      {...props}
      symbol="info"
      onClick={() => {
        navigate("/tracks/details");
      }}
    />
  );
};

export default React.memo(TracksInfoButton);
