import React, { useContext } from "react";

import ChartsSwitch from "../../Components/Charts/ChartsSwitch";
import TrackContext from "../../../../Context/TrackContext";
import Card from "../../../Trackalyze/Card";
import Typo from "../../../Trackalyze/Typo";
import { useTranslation } from "react-i18next";

const ELEVATION_LEVEL = 1;

const TrackProfiles = (props) => {
  const { t } = useTranslation();

  const { track } = useContext(TrackContext);

  return track !== undefined ? (
    <div className="ta-is-overflow scroll-snap scroll-snap--y ">
      <div className="trackprofiles_content ta-flex ta-flex--column">
        <Card
          elevation={ELEVATION_LEVEL}
          className="scroll-snap_item ta-padding_topbottom-s"
        >
          <Typo type="label" size="l" className="ta-margin_left-s">
            {t("track.elevation")}
          </Typo>
          <ChartsSwitch
            type={"elevation"}
            totalItems={[
              {
                key: "elevationUp",
                value: track.$.elevationUp,
              },
              {
                key: "elevationDown",
                value: track.$.elevationDown,
              },
              {
                key: "elevationMin",
                value: track.$.elevationMin,
              },
              {
                key: "elevationMax",
                value: track.$.elevationMax,
              },
            ]}
          />
        </Card>
        {"timeStart" in track.$ && (
          <>
            <Card
              elevation={ELEVATION_LEVEL}
              className="scroll-snap_item ta-padding_topbottom-s"
            >
              <Typo type="label" size="l" className="ta-margin_left-s">
                {t("track.pace")}
              </Typo>
              <ChartsSwitch
                type={"pace"}
                totalItems={[
                  {
                    key: "paceMax",
                    value: track.$.paceMax,
                  },
                  { key: "pace", value: track.$.paceAvg },
                  {
                    key: "paceMin",
                    value: track.$.paceMin,
                  },
                ]}
              />
            </Card>
            <Card
              elevation={ELEVATION_LEVEL}
              className="scroll-snap_item ta-padding_topbottom-s"
            >
              <Typo type="label" size="l" className="ta-margin_left-s">
                {t("track.speed")}
              </Typo>
              <ChartsSwitch
                type={"speed"}
                totalItems={[
                  {
                    key: "speedMax",
                    value: track.$.speedMax,
                  },
                  { key: "speed", value: track.$.speedAvg },
                  {
                    key: "speedMin",
                    value: track.$.speedMin,
                  },
                ]}
              />
            </Card>
          </>
        )}
        {"heartrateAvg" in track.$ && (
          <Card
            elevation={ELEVATION_LEVEL}
            className="scroll-snap_item ta-padding_topbottom-s"
          >
            <Typo type="label" size="l" className="ta-margin_left-s">
              {t("track.heartrate")}
            </Typo>
            <ChartsSwitch
              type={"heartrate"}
              totalItems={[
                {
                  key: "hrMin",
                  value: track.$.heartrateMin,
                },
                {
                  key: "hrAvg",
                  value: track.$.heartrateAvg,
                },
                {
                  key: "hrMax",
                  value: track.$.heartrateMax,
                },
              ]}
            />
          </Card>
        )}
      </div>
    </div>
  ) : null;
};

export default React.memo(TrackProfiles);
