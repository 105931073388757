import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typo from "../../../Trackalyze/Typo";
import AllVisibilityContext from "../../../../Context/AllVisiblityContext";
import TrackVisiblityContext from "../../../../Context/TrackVisiblityContext";
import TracksVisiblityContext from "../../../../Context/TracksVisiblityContext";

const VisibilitySelector = () => {
  const { t } = useTranslation();

  const { isAllVisible, setAllVisibility } = useContext(AllVisibilityContext);
  const { isTrackVisible, setTrackVisibility } = useContext(
    TrackVisiblityContext
  );
  const { isTracksVisible, setTracksVisibility } = useContext(
    TracksVisiblityContext
  );
  const [selectionItems, setSelectionItems] = useState([]);

  useEffect(() => {
    console.log("selectionItems use", selectionItems);
  }, [selectionItems]);

  useEffect(() => {
    let tmpItems = [];

    if (selectionItems.length > 0) {
      tmpItems = [...selectionItems];

      for (let i = 0; i < tmpItems.length; i++) {
        if (tmpItems[i].id === "track") {
          tmpItems[i].active = isTrackVisible;
        }
        if (tmpItems[i].id === "tracks") {
          tmpItems[i].active = isTracksVisible;
        }
        if (tmpItems[i].id === "all") {
          tmpItems[i].active = isAllVisible;
        }
      }
    } else {
      tmpItems = [
        {
          id: "track",
          label: "Aktive Strecke",
          active: isTrackVisible,
        },
        {
          id: "tracks",
          label: "Streckenvorschau",
          active: isTracksVisible,
        },
      ];
    }

    setSelectionItems(tmpItems);
  }, [isAllVisible, isTrackVisible, isTracksVisible]);

  const toggleSelection = (itemId) => {
    if (itemId === "all") {
      setAllVisibility(!isAllVisible);
    }
    if (itemId === "track") {
      setTrackVisibility(!isTrackVisible);
    }
    if (itemId === "tracks") {
      setTracksVisibility(!isTracksVisible);
    }
  };

  // console.log("selectionItems final", isAllVisible);
  return (
    <div className="maptypes_items">
      {selectionItems.map((value) => {
        return (
          <div
            key={value.id}
            className={"maptypes_item"}
            onClick={() => {
              toggleSelection(value.id);
            }}
          >
            <img
              style={{
                opacity: value.active === true && isAllVisible ? "1" : ".5",
              }}
              className={
                "maptypes_img ta-shape_l ta-padding_xxxs " +
                (value.active && isAllVisible ? "maptype--active" : "")
              }
              src={"/images/overlay_" + value.id + ".png"}
            />
            <Typo
              size="s"
              className={
                "ta-flexitem ta-margin_top-xxxs ta-is-center " +
                (value.active && isAllVisible
                  ? "ta-color-text_primary"
                  : "ta-color-text_on-surface-low")
              }
            >
              {t(value.label)}
            </Typo>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(VisibilitySelector);
