import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import { FullscreenProvider } from "../../../Context/FullscreenContext";
import { TracksVisiblityProvider } from "../../../Context/TracksVisiblityContext";
import { TrackVisiblityProvider } from "../../../Context/TrackVisiblityContext";
import { TrackMarkerVisibilityProvider } from "../../../Context/TrackMarkerVisibilityContext";
import { RoundsProvider } from "../../../Context/RoundsContext";
import { MapTypeProvider } from "../../../Context/MapTypeContext";
import { MapPanProvider } from "../../../Context/MapPanContext";
import { MapDragProvider } from "../../../Context/MapDragContext";
import { GuiSplitProvider } from "../../../Context/GuiSplitContext";
import { MapLabelProvider } from "../../../Context/MapLabelContext";

import TrackMap from "./TrackMap";
import { LocationProvider } from "../../../Context/LocationContext";
import { AllVisiblityProvider } from "../../../Context/AllVisiblityContext";
import { OverlayVisibilityProvider } from "../../../Context/OverlayVisibilityContext";

const TrackMapWrapper = () => {
  const [mounted, setMounted] = useState(true);

  return (
    mounted && (
      <FullscreenProvider>
        <OverlayVisibilityProvider>
          <AllVisiblityProvider>
            <TracksVisiblityProvider>
              <TrackVisiblityProvider>
                <TrackMarkerVisibilityProvider>
                  <MapPanProvider>
                    <MapTypeProvider>
                      <RoundsProvider>
                        <MapLabelProvider>
                          <MapDragProvider>
                            <GuiSplitProvider>
                              <LocationProvider>
                                <TrackMap />
                                <Outlet />
                              </LocationProvider>
                            </GuiSplitProvider>
                          </MapDragProvider>
                        </MapLabelProvider>
                      </RoundsProvider>
                    </MapTypeProvider>
                  </MapPanProvider>
                </TrackMarkerVisibilityProvider>
              </TrackVisiblityProvider>
            </TracksVisiblityProvider>
          </AllVisiblityProvider>
        </OverlayVisibilityProvider>
      </FullscreenProvider>
    )
  );
};

export default React.memo(TrackMapWrapper);
