import React, { useState } from "react";

const LocationContext = React.createContext();
export default LocationContext;

export const LocationProvider = (props) => {
  const [showMyLocation, setShowMyLocation] = useState(false);

  return (
    <LocationContext.Provider
      value={{
        showMyLocation,
        setShowMyLocation,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};
