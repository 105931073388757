import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import DeviceContext from "../../../../Context/DeviceContext";
import Button from "../../../Trackalyze/Button";
import guiManager from "../../../utils/guiManager";
import VisibilityPanel from "./VisibilityPanel";
const VisibilityOptionsButton = (props) => {
  const navigate = useNavigate();
  const [isRenderSheet, setIsRenderSheet] = useState(false);
  const { isDeviceTablet } = useContext(DeviceContext);

  return isDeviceTablet ? (
    <>
      <Button
        text
        icon
        symbol="layers"
        {...props}
        onClick={() => {
          setIsRenderSheet(!isRenderSheet);
        }}
      />
      {guiManager(
        <VisibilityPanel
          onClose={() => setIsRenderSheet(!isRenderSheet)}
          animate={isRenderSheet}
        />,
        "root"
      )}
    </>
  ) : (
    <Button
      text
      icon
      symbol="layers"
      {...props}
      onClick={() => {
        navigate("/");

        window.setTimeout(() => {
          navigate("/visibility");
        }, 100);
      }}
    />
  );
};

export default React.memo(VisibilityOptionsButton);
