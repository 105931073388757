import React, { useState } from "react";

import Typo from "../../Trackalyze/Typo";
import Card from "../../Trackalyze/Card";
import Icon from "../../Trackalyze/Icon";
import Expandable from "../../Trackalyze/Expandable";
import Button from "../../Trackalyze/Button";

const CardExpandable = (props) => {
  const [isExpanded, setExpand] = useState(false);

  return (
    <>
      <Card
        className={
          "ta-flex ta-flex--column " +
          props.className +
          (isExpanded && props.fill ? " details_card--expand" : "")
        }
        elevation={props.elevation}
      >
        <div
          onClick={() => setExpand(!isExpanded)}
          className="ta-padding_leftright-xxs ta-flex ta-flex--itemscenter"
        >
          <Icon
            symbol={props.symbol}
            className="ta-margin_left-xs ta-color-text_on-surface-low"
          />

          <Typo className="ta-margin_left-s ta-flexitem">{props.title}</Typo>
          {props.fill ? (
            <Button icon symbol={isExpanded ? "unfold_less" : "unfold_more"} />
          ) : (
            <Button icon symbol={isExpanded ? "expand_less" : "expand_more"} />
          )}
        </div>
        <Expandable animate={isExpanded}>{props.children}</Expandable>
      </Card>
      {isExpanded && props.fill && (
        <div className="details_card--expandscrim " />
      )}
    </>
  );
};

export default CardExpandable;
