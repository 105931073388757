import React, { useState, useEffect } from "react";
import Button from "./Button";

const RadioButton = (props) => {
  const buildClasses = () => {
    const classes = [];
    props.disabled && classes.push("ta-is-disabled");

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <Button
      icon
      symbol={
        props.checked === props.value
          ? "control_radio_checked"
          : "control_radio_unchecked"
      }
      onClick={() => props.callBack(props.value)}
      className={buildClasses()}
      text
    />
  );
};

export default RadioButton;
