import React from "react";
import SVG from "react-inlinesvg";

const Icon = (props) => {
  const buildClasses = () => {
    const classes = ["ta-icon"];
    props.size && classes.push("ta-icon--size-" + props.size);
    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  const icon = require("../../svg/" + props.symbol + ".svg");
  return (
    <SVG
      preProcessor={(code) =>
        code.replace(/viewBox=".*?"/g, 'viewBox="0 0 24 24"')
      }
      className={buildClasses()}
      style={props.style}
      src={icon}
    />
  );
};

export default Icon;
