import React from "react";
import Button from "../../../Trackalyze/Button";
import Typo from "../../../Trackalyze/Typo";

const ToggleButton = (props) => {
  return (
    <div
      className={
        "ta-flex ta-flex--column ta-flex--itemscenter ta-flex--center " +
        (props.disabled ? "ta-is-disabled" : "") +
        (props.small ? "" : " ta-margin_xxs")
      }
      onClick={() => {
        props.onClick();
      }}
    >
      {props.symbol && (
        <Button
          icon
          symbol={props.symbol}
          outline={!props.active}
          primary={props.active}
          small={props.small}
        />
      )}
      {!props.small && (
        <Typo size="s" className={"ta-flexitem ta-margin_top-xxs"}>
          {props.label}
        </Typo>
      )}
    </div>
  );
};

export default React.memo(ToggleButton);
