import React, { useContext } from "react";

import TrackContext from "../../../../Context/TrackContext";
import TracksImport from "../../Components/Tracks/TracksImport";
import Button from "../../../Trackalyze/Button";
import { useTranslation } from "react-i18next";

const TracksImportButton = (props) => {
  const { t } = useTranslation();

  const { setImportDone } = useContext(TrackContext);

  return (
    <TracksImport
      callBack={() => {
        setImportDone(true);
      }}
    >
      <>
        {props.simple && <Button icon symbol="add" tonal />}
        {props.fab && (
          <Button symbol="add" icon primary elevation={3} fab {...props} />
        )}
        {!props.simple && !props.fab && (
          <Button tonal symbol="playlist_add" {...props}>
            {t("import.button_add")}
          </Button>
        )}
      </>
    </TracksImport>
  );
};

export default React.memo(TracksImportButton);
