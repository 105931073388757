import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../Trackalyze/Button";
import Icon from "../../../Trackalyze/Icon";
import Typo from "../../../Trackalyze/Typo";

const ChartsPointInfo = ({ payLoad, onClose }) => {
  const { t } = useTranslation();

  return Object.keys(payLoad).length ? (
    <div className="ta-flex ta-flex--center ta-flex--itemscenter">
      {onClose && (
        <Button
          pure
          onClick={() => {
            onClose && onClose();
          }}
          className="ta-margin_right-xxs"
        >
          <Icon symbol="close" size="s" />
        </Button>
      )}

      <BuildPayloadItem
        id="distance"
        unit={t("units.distance")}
        payLoad={payLoad.distanceAddedKm}
      />

      {"durationSeconds" in payLoad.$ && (
        <BuildPayloadItem
          id="duration"
          unit=""
          payLoad={payLoad.addedDuration}
        />
      )}
      {"elevationHeight" in payLoad && (
        <BuildPayloadItem
          id="elevation"
          unit={t("units.elevation")}
          payLoad={payLoad.elevationHeight}
        />
      )}
      {"hrVal" in payLoad && (
        <BuildPayloadItem
          id="heartrate"
          unit={t("units.heartrate")}
          payLoad={payLoad.heartrateAvg}
        />
      )}
    </div>
  ) : null;
};

const BuildPayloadItem = (props) => {
  console.log("ptrops", props);
  return (
    <div className="ta-margin_leftright-xxxs ta-flex ta-flex--itemscenter">
      <Icon
        symbol={props.id}
        size="s"
        className={"ta-margin_right-xxxs details-color_" + props.id}
      />
      <Typo size="s" className="ta-color-text_on-surface-low">
        {props.payLoad} {props.unit}
      </Typo>
    </div>
  );
};
export default React.memo(ChartsPointInfo);
