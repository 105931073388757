import React, { useContext, useState } from "react";

import { useNavigate } from "react-router-dom";
import VisibilityOverlays from "./VisibilityOverlays";
import DeviceContext from "../../../../Context/DeviceContext";
import { useTranslation } from "react-i18next";
import MapTypes from "./MapTypes";
import Typo from "../../../Trackalyze/Typo";
import Toolbar from "../../../Trackalyze/Toolbar";
import Button from "../../../Trackalyze/Button";

const VisibilityPanel = (props) => {
  const { t } = useTranslation();
  const { windowWidth } = useContext(DeviceContext);

  const [isRenderSheet, setIsRenderSheet] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    if (isRenderSheet) {
      setIsRenderSheet(false);
      navigate(-1);
    }
  };

  return (
    <div
      className={
        "layout_panel layout_panel--topright ta-elevation_1 ta-elevation--shadow " +
        (props.animate ? " layout_panel--popup-animate" : "")
      }
    >
      <Toolbar title={t("mapoverlay.title")} symbol="layers">
        <Button icon symbol="close" onClick={() => props.onClose()} />
      </Toolbar>

      <div className="ta-margin_s">
        <Typo className="ta-margin_bottom-s">{t("mapoverlay.title_maps")}</Typo>
        <MapTypes />
      </div>
      <VisibilityOverlays />
    </div>
  );
};

export default React.memo(VisibilityPanel);
