import React, { useContext } from "react";
import GuiSplitContext from "../../../Context/GuiSplitContext";
import DetailsHeader from "../Components/Details/DetailsHeader";
import MainNavigationTrack from "./GuiMap/MainNavigationTrack";
import TrackProfiles from "./GuiMap/TrackProfiles";

const ProfilesPanel = (props) => {
  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  return (
    <div
      id="guiSplit"
      className={
        "ta-is-relative layout_panel layout_panel--profiles ta-flexitem " +
        (guiSplit.expanded ? "" : " layout_panel--sidemax ")
      }
    >
      <div className="ta-is-relative ta-flex ta-flex--column ta-flexitem ta-height_full">
        <DetailsHeader
          simple
          callBack={() =>
            setGuiSplit({
              ...guiSplit,
              profiles: false,
              expanded: false,
            })
          }
        />
        <TrackProfiles />
        <div className="ta-margin_top-xxs ta-margin_leftright-xxs">
          <MainNavigationTrack />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ProfilesPanel);
