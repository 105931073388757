import React, { useState } from "react";

const MapTypeContext = React.createContext();
export default MapTypeContext;

const MAP_TYPES = [
  {
    id: "OSM",
    osm: true,
    tilesUrl: "https://tile.openstreetmap.org/",
    label: "OpenStreet",
  },
  {
    id: "OTM",
    osm: true,
    tilesUrl: "https://tile.opentopomap.org/",
    label: "OpenTopo",
  },
  {
    id: "terrain",
    label: "Terrain",
  },
  {
    id: "roadmap",
    label: "Roadmap",
  },
  {
    id: "hybrid",
    label: "Satellite",
  },
];
// osm
// https://tile.openstreetmap.org/
// Overlay hiking tracks
// https://tile.waymarkedtrails.org/hiking
// opentopomap
// https://tile.opentopomap.org/
// https://opentopomap.org/about#verwendung

export const MapTypeProvider = (props) => {
  const [mapType, setMapType] = useState({
    id: "OSM",
    label: "OpenStreet",
  });

  return (
    <MapTypeContext.Provider
      value={{
        mapType,
        setMapType,
        MAP_TYPES,
      }}
    >
      {props.children}
    </MapTypeContext.Provider>
  );
};
