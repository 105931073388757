import {
  Area,
  AreaChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import React from "react";
import ChartsPointInfo from "./ChartsPointInfo";

const Chart = (props) => {
  // const axisFill = theme !== true ? "#000" : "#00b87a";
  const axisFill = "#888888";

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      //  props.onToolTip && props.onToolTip(payload[0].payload);
      console.log("payload[0].payload", payload[0].payload);
      //   return null;
      return (
        <div className="ta-color_secondary-container ta-padding_xxs ta-shape_xl">
          <ChartsPointInfo payLoad={payload[0].payload} />
        </div>
      );
    }
    return null;
  };

  const onClickChart = (payload) => {
    if ("activePayload" in payload && payload.activePayload.length) {
      console.log("payload 1", payload);
      props.onClick && props.onClick(payload.activePayload[0].payload);
    }
    return null;
  };

  return props.data.length > 0 ? (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        onClick={(value) => onClickChart(value)}
        isAnimationActive={false}
        data={props.data}
        margin={{
          top: 0,
          right: -16,
          left: 24,
          bottom: 0,
        }}
      >
        {/*         <CartesianGrid strokeDasharray="1000" vertical={false} />
         */}
        <XAxis
          dataKey={props.xAxis}
          padding={{ left: 0, right: 0 }}
          width={0}
          height={32}
          tickMargin={8}
          tickSize={8}
          tickLine={{ stroke: "#444" }}
          tick={{ fontSize: "10px", fill: "#777" }}
          orientation="bottom"
          unit={" " + props.xUnit}
          type="number"
          domain={props.xDomain}
          interval="preserveStartEnd"
          tickCount={20}
          allowDecimals={false}
          allowDataOverflow={false}
        />
        <YAxis
          orientation="right"
          padding={{ left: 0, top: 0 }}
          axisLine={false}
          tickLine={false}
          dataKey={props.yAxis}
          tick={{ fontSize: "10px", fill: "#777" }}
          domain={props.yDomain}
          interval="preserveStartEnd"
          unit={" " + props.yUnit}
          tickCount={5}
          tickFormatter={(tick) => tick.toFixed(0)}
          allowDecimals={false}
          xlabel={{ value: "Meter", position: "top" }}
          reversed={props.yFlip}
          scale="linear"
          allowDataOverflow={false}
        />
        <Tooltip content={<CustomTooltip />} isAnimationActive={false} />

        <Area
          dataKey={props.yAxis}
          stroke={props.stroke ? props.stroke : axisFill}
          strokeWidth={1.5}
          fillOpacity={1}
          fill={"url(#grad" + props.id + ")"}
          unit=" m"
          isAnimationActive={false}
        />
        <ReferenceLine
          x={props.trackPoint}
          stroke={"white"}
          strokeDasharray="4 4"
        />

        <defs>
          <linearGradient id={"grad" + props.id} x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={props.fill ? props.fill : axisFill}
              stopOpacity={0.075}
            />
            <stop
              offset="75%"
              stopColor={props.fill ? props.fill : axisFill}
              stopOpacity={0.075}
            />
          </linearGradient>
        </defs>
      </AreaChart>
    </ResponsiveContainer>
  ) : null;
};

export default React.memo(Chart);
