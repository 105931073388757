import React from "react";
import Button from "./Button";
import Icon from "./Icon";
import Typo from "./Typo";

const Toolbar = (props) => {
  const buildClasses = () => {
    const classes = ["ta-toolbar"];

    !props.title && classes.push("ta-toolbar--notitle");
    classes.push(
      props.action || props.children
        ? "ta-padding_leftright-xxxs"
        : "ta-padding_leftright-xxxs"
    );
    props.elevation && classes.push("ta-elevation_" + props.elevation);

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <nav className={buildClasses()} style={props.style}>
      {props.action && (
        <Button
          icon
          iconProps={{ className: "ta-color-text_on-surface-low" }}
          symbol={props.action.symbol || "close"}
          onClick={props.action.onClick}
        />
      )}
      {props.symbol && (
        <Icon
          symbol={props.symbol}
          className="ta-margin_left-xs ta-color-text_on-surface-low"
        />
      )}
      {props.title && (
        <Typo noWrap type="title" size="l" className="ta-toolbar_label">
          {props.title}
        </Typo>
      )}

      <div className="ta-toolbar_children">{props.children}</div>
    </nav>
  );
};

export default Toolbar;
