import React, { useState, useContext, useEffect } from "react";
import TrackContext from "../../../../Context/TrackContext";
import MapDragContext from "../../../../Context/MapDragContext";
import Button from "../../../Trackalyze/Button";
import Badge from "../../../Trackalyze/Badge";
import LongPressButton from "../../../utils/LongPressButton";

const TrackFitsButton = (props) => {
  const { mapDrag, setMapDrag } = useContext(MapDragContext);
  const { tracks } = useContext(TrackContext);

  const [type, setType] = useState("");

  useEffect(() => {
    console.log("drag", mapDrag);
  }, [mapDrag]);

  return (
    <div className="ta-is-relative">
      <LongPressButton
        duration={500}
        onPress={() => {
          console.log("press");
          setType("track");
          setMapDrag({
            ...mapDrag,
            dragged: false,
            type: "track",
          });
        }}
        onLongPress={() => {
          if (tracks.length > 1) {
            setType("tracks");
            setMapDrag({
              ...mapDrag,
              dragged: false,
              type: "tracks",
            });
          }
        }}
      >
        <Button
          elevation
          icon
          tonal
          symbol={type === "tracks" ? "tracks_fit" : "track_fit"}
          disabled={tracks.length < 1}
          {...props}
        />
        {!mapDrag.dragged && (
          <>
            {type === "tracks" && (
              <Badge className="ta-is-absolute ta-is-topright">
                {tracks.length}
              </Badge>
            )}
            {type === "track" && (
              <Badge
                style={{
                  top: "var(--ta-ref-spacing-1)",
                  right: "var(--ta-ref-spacing-1)",
                }}
                small
                className="ta-is-absolute ta-is-topright"
              />
            )}
          </>
        )}
      </LongPressButton>
    </div>
  );
};

export default React.memo(TrackFitsButton);
