import "babel-polyfill";

import { Route, BrowserRouter, Routes } from "react-router-dom";

import { ConfigProvider } from "../Context/ConfigContext";
import Gui from "./Gui";
import { GuiProvider } from "../Context/GuiContext";
import React, { lazy, Suspense } from "react";
import { TrackProvider } from "../Context/TrackContext";
import styles from "../sass/styles.scss";
import i18next from "../js/locale/i18n";

import Settings from "./Views/Settings/Settings";
import TrackRoundsPage from "./Views/Track/TrackRoundsPage";
import LabelsModal from "./Views/Labels/LabelsModal";
import TrackMapWrapper from "./Views/MapWrapper/TrackMapWrapper";
import TracksInfoModal from "./Views/MapWrapper/GuiMap/TracksInfoModal";
import MapTypesModal from "./Views/MapWrapper/GuiMap/MapTypesModal";
import VisibilityModal from "./Views/MapWrapper/GuiMap/VisibilityModal";
import OptionsModal from "./Views/MapWrapper/GuiMap/OptionsModal";
import OpensourceModal from "./Views/Settings/OpensourceModal";
import ProgressIndicator from "./Trackalyze/ProgressIndicator";
import PositionAbsolute from "./Trackalyze/patterns/PositionAbsolute";

/* const TrackMapWrapper = lazy(() =>
  import("./Views/MapWrapper/TrackMapWrapper")
);
const Settings = lazy(() => import("./Views/Settings/Settings"));
const Components = lazy(() => import("./Views/Components"));
const TrackalyzeGuide = lazy(() => import("./Views/TrackalyzeGuide"));
 */
// remove app loader from dom
document.getElementById("appLoader").remove();
// end remove app loader from dom

// tests
if ("launchQueue" in window) {
  launchQueue.setConsumer((launchParams) => {
    handleFiles(launchParams.files);
  });
} else {
  console.error("File Handling API is not supported!");
}

// set language from localstorage
const lngCheck =
  localStorage.getItem("language") !== null
    ? JSON.parse(localStorage.getItem("language"))
    : "";
if (lngCheck !== "") {
  i18next.changeLanguage(lngCheck);
}
console.log("lang", i18next.options.languages);
// end set language from localstorage

async function handleFiles(files) {
  for (const file of files) {
    const blob = await file.getFile();
    blob.handle = file;
    const text = await blob.text();
  }
}
// end of tests

export const App = () => {
  return (
    <BrowserRouter>
      <ConfigProvider>
        <GuiProvider>
          <Gui>
            <TrackProvider>
              <Routes>
                {/*                 <Route path="/guide" element={<TrackalyzeGuide />} />
                 */}
                {/*                 <Route path="/components" element={<Components />} />
                 */}
                <Route path="/settings" element={<Settings />}>
                  <Route path="opensource" element={<OpensourceModal />} />
                </Route>
                <Route path="/*" element={<TrackMapWrapper />}>
                  <Route path="trackrounds" element={<TrackRoundsPage />} />

                  <Route path="tracks/details" element={<TracksInfoModal />} />
                  <Route path="labels" element={<LabelsModal />} />
                  <Route path="maps" element={<MapTypesModal />} />
                  <Route path="visibility" element={<VisibilityModal />} />
                  <Route path="options" element={<OptionsModal />} />
                  {/*     <Route path="track/details" element={<TrackDetailsPage />} />
                   */}
                </Route>
              </Routes>
            </TrackProvider>
          </Gui>
        </GuiProvider>
      </ConfigProvider>
    </BrowserRouter>
  );
};
