import React, { useContext, useEffect, useState } from "react";

import ConfigContext from "../../../Context/ConfigContext";

import Typo from "../../Trackalyze/Typo";
import Button from "../../Trackalyze/Button";
import TextField from "../../Trackalyze/TextField";
import RadioButton from "../../Trackalyze/RadioButton";
import Expandable from "../../Trackalyze/Expandable";

import { useTranslation } from "react-i18next";

const SettingsProfile = () => {
  const { t } = useTranslation();

  const [expandProfileInfo, setExpandProfileInfo] = useState(false);
  const { profile, setProfile } = useContext(ConfigContext);
  const [profileTemp, setProfileTemp] = useState(profile);

  useEffect(() => {
    console.log("settongs profile", profile);
    if (profile !== null) {
      setProfileTemp(profile);
    }
  }, [profile]);

  return profileTemp !== null ? (
    <>
      <div className="ta-margin_topbottom-s ta-flex ta-flex--eve ta-flex--itemscenter">
        <div
          onClick={() => setProfile({ ...profile, gender: "male" })}
          className="ta-flex ta-flex--itemscenter ta-margin_leftright-s"
        >
          <RadioButton name="gender" value="male" checked={profile.gender} />
          <Typo>{t("profile.form_gender_male")}</Typo>
        </div>
        <div
          onClick={() => setProfile({ ...profile, gender: "female" })}
          className="ta-flex ta-flex--itemscenter ta-margin_leftright-s"
        >
          <RadioButton name="gender" value="female" checked={profile.gender} />
          <Typo>{t("profile.form_gender_female")}</Typo>
        </div>
      </div>
      <div className="ta-flex ta-flexitem ta-flex--itemsstart">
        <TextField
          label={t("profile.form_age_label")}
          value={profile.age}
          onChange={(value) => {
            setProfile({ ...profile, age: value });
          }}
          className="ta-margin_leftright-xxxs"
          support={t("profile.form_age_support")}
        />

        <TextField
          label={t("profile.form_height_label")}
          value={profileTemp.height}
          onChange={(value) => {
            setProfile({ ...profile, height: value });
          }}
          support={t("profile.form_height_support")}
          className="ta-margin_leftright-xxxs"
        />
        <TextField
          label={t("profile.form_weight_label")}
          value={profileTemp.weight}
          onChange={(value) => {
            setProfile({ ...profile, weight: value });
          }}
          support={t("profile.form_weight_support")}
          className="ta-margin_leftright-xxxs"
        />
      </div>
      <div className="ta-margin_topbottom-s ta-flex ta-flexitem ta-flex--itemsstart">
        <TextField
          label={t("profile.form_step_label")}
          value={profile.step}
          onChange={(value) => {
            setProfile({ ...profile, step: value });
          }}
          className="ta-margin_leftright-xxxs"
          support={t("profile.form_step_support")}
        />

        <TextField
          label={t("profile.form_pulse_label")}
          value={profileTemp.hrMax}
          onChange={(value) => {
            setProfile({ ...profile, hrMax: value });
          }}
          support={t("profile.form_pulse_support")}
          className="ta-margin_leftright-xxxs"
        />
      </div>
      <div onClick={() => setExpandProfileInfo(!expandProfileInfo)}>
        <Typo
          size="m"
          className="ta-color-text_on-surface-lower ta-flex ta-flex--itemscenter"
        >
          <Button icon symbol="info" /> {t("profile.formulas_title")}
        </Typo>
      </div>
      <Expandable animate={expandProfileInfo}>
        <div className="ta-padding_xxxs">
          <Typo type="label">{t("profile.formulas_calories_title")}</Typo>
          <Typo
            type="body"
            size="s"
            className="ta-margin_bottom-s ta-color-text_on-surface-low"
          >
            {t("profile.formulas_calories_description")}
          </Typo>
          <Typo type="label"> {t("profile.formulas_steps_title")}</Typo>
          <Typo
            type="body"
            size="s"
            className="ta-margin_bottom-s ta-color-text_on-surface-low"
          >
            {t("profile.formulas_steps_description")}
          </Typo>
          <Typo type="label"> {t("profile.formulas_heartrate_title")}</Typo>

          <Typo
            type="body"
            size="s"
            className="ta-margin_bottom-s ta-color-text_on-surface-low"
          >
            {t("profile.formulas_heartrate_description")}
          </Typo>
        </div>
      </Expandable>
    </>
  ) : null;
};

export default React.memo(SettingsProfile);
