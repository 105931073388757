import React, { useContext, useEffect, useState } from "react";

import TrackContext from "../../../../Context/TrackContext";

import { ACTIVITY_TYPES } from "../../../config/mapTypes";

import Typo from "../../../Trackalyze/Typo";
import Card from "../../../Trackalyze/Card";
import Icon from "../../../Trackalyze/Icon";
import ProgressIndicator from "../../../Trackalyze/ProgressIndicator";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import CardExpandable from "../CardExpandable";
import BreaksList from "../Rounds/BreaksList";
import WaypointsList from "../Rounds/WaypointsList";
import DeviceContext from "../../../../Context/DeviceContext";
import TrackLabelsList from "../../MapWrapper/GuiMap/TrackLabelsList";

const Details = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isDevicePortrait } = useContext(DeviceContext);

  const [contentVisible, setContentVisible] = useState(false);
  const [type, setType] = useState("");
  const [quickItems, setQuickItems] = useState([]);
  const { track, trackLabels } = useContext(TrackContext);

  useEffect(() => {
    console.log("trackLabels", trackLabels);
    buildQuickItems();

    window.setTimeout(() => {
      setContentVisible(true);
    }, 100);
  }, []);

  const buildQuickItems = () => {
    const tmpItems = [];
    tmpItems.push({
      id: "distance",
      value: track.$.formatted.distanceKm,
      unit: t("units.distance"),
      label: t("track.distance"),
      fill: "distance",
      symbol: "distance",
      featured: true,
    });
    "timeStart" in track.$ &&
      tmpItems.push({
        id: "duration",
        value: track.$.formatted.duration.durationFixed,
        unit: "hh/mm/ss",
        label: t("track.time_duration"),
        fill: "duration",
        symbol: "duration",
        more: true,
        notQuick: true,
      });
    "elevationUp" in track.$ &&
      track.$.elevationUp > 0 &&
      tmpItems.push({
        id: "elevation",
        value: track.$.elevationUp,
        unit: t("units.elevation"),
        label: t("track.elevation"),
        fill: "elevation",
        symbol: "elevation_up",
        more: true,
      });
    "timeStart" in track.$ &&
      tmpItems.push({
        id: "pace",
        value: track.$.paceAvg,
        unit: t("units.pace"),
        label: t("track.pace"),
        fill: "pace",
        symbol: "pace",
        more: true,
      });
    "timeStart" in track.$ &&
      tmpItems.push({
        id: "speed",
        value: track.$.speedAvg,
        unit: t("units.speed"),
        label: t("track.speed"),
        fill: "speed",
        symbol: "speed",
        more: true,
      });
    "heartrateAvg" in track.$ &&
      tmpItems.push({
        id: "heartrate",
        value: track.$.heartrateAvg,
        unit: t("units.heartrate"),
        label: t("track.heartrate"),
        fill: "heartrate",
        symbol: "heartrate",
        more: true,
      });
    tmpItems.push({
      id: "steps",
      value: track.$.fitnessSteps,
      unit: t("units.steps"),
      label: t("track.steps"),
      fill: "other",
      symbol: "steps",
    });
    tmpItems.push({
      id: "calories",
      value: track.$.fitnessKCal,
      unit: t("units.energy"),
      label: t("track.energy"),
      fill: "other",
      symbol: "calories",
    });

    setQuickItems(tmpItems);
  };

  useEffect(() => {
    if (track !== undefined) {
      console.log("ACTIVITY_TYPES", ACTIVITY_TYPES);
      console.log(
        "ACTIVITY_TYPES track.$.activityType",
        track.$.activityType.toLowerCase()
      );
      let actType = ACTIVITY_TYPES.filter(
        (value) => value.id === track.$.activityType
      );
      if (actType.length) {
        //  actType = actType[0].label;
        actType = t("activities." + track.$.activityType);
      } else {
        actType = "";
      }
      setType(actType);
      buildQuickItems();

      document.getElementById("detailstop").scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [track]);
  return track !== undefined ? (
    <>
      <div
        className={
          "ta-flexitem details_container xta-margin_xs ta-fadein ta-is-overflow scroll-snap scroll-snap--both " +
          (contentVisible ? "ta-fadein--animate" : "")
        }
      >
        <div id="detailstop"></div>
        <div className=" ta-flex ta-flex--wrap details_cardcontainer">
          {"timeStart" in track.$ && (
            <DetailList
              id="duration"
              headline={t("track.time")}
              unit={t("units.duration_hhmm")}
              items={[
                {
                  symbol: "time_start",
                  symbolFill: "details-color_duration",
                  header: t("track.time_start"),
                  headerRight: track.$.formatted.timeStart.dateTime,
                },
                {
                  symbol: "time_end",
                  symbolFill: "details-color_duration",
                  header: t("track.time_end"),
                  headerRight: track.$.formatted.timeEnd.dateTime,
                },
                {
                  symbol: "duration",
                  symbolFill: "details-color_duration",
                  header: t("track.time_duration"),
                  headerRight: track.$.formatted.duration.durationVerbose,
                },
                {
                  symbol: "duration",
                  symbolFill: "details-color_duration",
                  header: t("track.time_duration_inmotion"),
                  headerRight:
                    track.$.durationInMotionSeconds > 0
                      ? track.$.formatted.durationInMotion.durationVerbose
                      : track.$.formatted.duration.durationVerbose,
                },
                {
                  symbol: "rest",
                  symbolFill: "details-color_duration",
                  header: t("track.time_stops"),
                  headerRight:
                    track.$.durationInMotionSeconds > 0
                      ? track.$.formatted.durationStops.durationVerbose
                      : "--",
                },
              ]}
            />
          )}
          {"elevationUp" in track.$ && track.$.elevationUp > 0 && (
            <DetailList
              id="elevation"
              chart
              headline={t("track.elevation")}
              unit={t("units.elevation_long")}
              items={[
                {
                  symbol: "elevation_min",
                  symbolFill: "details-color_elevation",
                  header: t("track.elevation_start"),
                  headerRight: track.$.elevationStart,
                },
                {
                  symbol: "elevation_max",
                  symbolFill: "details-color_elevation",
                  header: t("track.elevation_end"),
                  headerRight: track.$.elevationEnd,
                },
                {
                  symbol: "elevation_up",
                  symbolFill: "details-color_elevation",
                  header: t("track.elevation_up"),
                  headerRight: track.$.elevationUp,
                },
                {
                  symbol: "elevation_down",
                  symbolFill: "details-color_elevation",
                  header: t("track.elevation_down"),
                  headerRight: track.$.elevationDown,
                },
                {
                  symbol: "elevation_min",
                  symbolFill: "details-color_elevation",
                  header: t("track.elevation_min"),
                  headerRight: track.$.elevationMin,
                },
                {
                  symbol: "elevation_max",
                  symbolFill: "details-color_elevation",
                  header: t("track.elevation_max"),
                  headerRight: track.$.elevationMax,
                },
              ]}
            />
          )}
          {"paceAvg" in track.$ && (
            <DetailList
              id="pace"
              chart
              headline={t("track.pace")}
              unit={t("units.pace")}
              items={[
                {
                  symbol: "pace",
                  symbolFill: "details-color_pace",
                  header: t("track.pace_avg"),
                  headerRight: track.$.formatted.paceAvg.duration,
                },
                {
                  symbol: "pace",
                  symbolFill: "details-color_pace",
                  header: t("track.pace_avg_inmotion"),
                  headerRight:
                    track.$.paceAvgInMotion > 0
                      ? track.$.formatted.paceAvgInMotion.duration
                      : track.$.formatted.paceAvg.duration,
                },
                {
                  symbol: "pace_min",
                  symbolFill: "details-color_pace",
                  header: t("track.pace_max"),
                  headerRight: track.$.formatted.paceMin.duration,
                },
                {
                  symbol: "pace_max",
                  symbolFill: "details-color_pace",
                  header: t("track.pace_min"),
                  headerRight: track.$.formatted.paceMax.duration,
                },
              ]}
            />
          )}
          {"speedAvg" in track.$ && (
            <DetailList
              id="speed"
              chart
              headline={t("track.speed")}
              unit={t("units.speed")}
              items={[
                {
                  symbol: "speed",
                  symbolFill: "details-color_speed",
                  header: t("track.speed_avg"),
                  headerRight: track.$.speedAvg,
                },
                {
                  symbol: "speed",
                  symbolFill: "details-color_speed",
                  header: t("track.speed_avg_inmotion"),
                  headerRight:
                    track.$.speedAvginMotion > 0
                      ? track.$.speedAvginMotion
                      : track.$.speedAvg,
                },
                {
                  symbol: "speed_min",
                  symbolFill: "details-color_speed",
                  header: t("track.speed_min"),
                  headerRight: track.$.speedMin,
                },
                {
                  symbol: "speed_max",
                  symbolFill: "details-color_speed",
                  header: t("track.speed_max"),
                  headerRight: track.$.speedMax,
                },
              ]}
            />
          )}
          {"heartrateAvg" in track.$ && (
            <>
              <DetailList
                id="heartrate"
                chart
                headline={t("track.heartrate")}
                unit={t("units.heartrate")}
                items={[
                  {
                    symbol: "heartrate_average",
                    symbolFill: "details-color_heartrate",
                    header: t("track.heartrate_avg"),
                    headerRight: track.$.heartrateAvg,
                  },
                  {
                    symbol: "heartrate_average",
                    symbolFill: "details-color_heartrate",
                    header: t("track.heartrate_avg_frommax"),
                    headerRight: track.$.fitnessHeartratePercent + "%",
                  },
                  {
                    symbol: "heartrate_min",
                    symbolFill: "details-color_heartrate",
                    header: t("track.heartrate_min"),
                    headerRight: track.$.heartrateMin,
                  },
                  {
                    symbol: "heartrate_max",
                    symbolFill: "details-color_heartrate",
                    header: t("track.heartrate_max"),
                    headerRight: track.$.heartrateMax,
                  },
                ]}
              />
            </>
          )}
          {"markerWaypoints" in track.$ && (
            <CardExpandable
              fill
              className="details_card ta-flexitem--start scroll-snap_item"
              elevation={2}
              title={t("track.waypoints")}
              symbol="track_marker"
            >
              <div className="ta-is-overflow ta-height_full">
                <WaypointsList />
              </div>
            </CardExpandable>
          )}

          {"markerStops" in track.$ && (
            <CardExpandable
              fill
              className="details_card ta-flexitem--start scroll-snap_item"
              elevation={2}
              title={t("track.stops")}
              symbol="coffee"
            >
              <div className="ta-is-overflow ta-height_full">
                <BreaksList />
              </div>
            </CardExpandable>
          )}

          {trackLabels.length > 0 && (
            <CardExpandable
              className="details_card ta-flexitem--start scroll-snap_item"
              elevation={2}
              title={t("labels.title")}
              symbol="label"
            >
              <div className="ta-is-overflow ta-height_full">
                <TrackLabelsList callBack={() => navigate("labels")} />
              </div>
            </CardExpandable>
          )}
        </div>
      </div>
    </>
  ) : (
    <div className="ta-is-center">
      <ProgressIndicator circular />
    </div>
  );
};

export default React.memo(Details);

const DetailList = (props) => {
  return (
    <>
      <a name={props.id} id={props.id}></a>

      <Card
        elevation={2}
        className={
          "details_card ta-padding_bottom-xxs xdetails_itemlist scroll-snap_item  _details-background_" +
          props.id
        }
      >
        <Typo
          type="label"
          className={"ta-padding_s ta-flex ta-flex--itemscenter ta-flex--out"}
        >
          <div className="ta-flexitem">{props.headline}</div>
          <Typo type="label">{props.unit}</Typo>
        </Typo>
        {props.items.map((item, idx) => {
          return (
            <React.Fragment key={"detailslist" + idx}>
              <div className="ta-flex ta-flex--itemscenter">
                {item.symbol && (
                  <Icon
                    className={
                      "ta-margin_leftright-s ta-margin_topbottom-xxs " +
                      (item.symbolFill ? item.symbolFill : "")
                    }
                    symbol={item.symbol}
                  />
                )}
                <Typo
                  size="m"
                  className="ta-color-text_on-surface-low ta-flexitem"
                >
                  {item.header}
                </Typo>
                {"headerRight" in item && (
                  <Typo size="m" className="ta-margin_right-s">
                    {item.headerRight}
                  </Typo>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </Card>
      <a name={props.id} id={props.id}></a>
    </>
  );
};
