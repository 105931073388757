import React, { useContext, useEffect, useState } from "react";
import TrackContext from "../../../../Context/TrackContext";
import {
  labelsGetCollection,
  labelsRefsGet,
  labelsRefsUpdate,
  labelsSetCollection,
} from "../../../utils/localStorage";

import Labels from "./Labels";
import LabelsEditor from "./LabelsEditor";

const LabelsWrapper = (props) => {
  const { trackId, setUpdateTrack } = useContext(TrackContext);

  const [labelsList, setLabelsList] = useState([]);
  const [labelRefsList, setLabelRefsList] = useState([]);

  useEffect(() => {
    getLabels();
    getLabelRefs(trackId);
  }, [trackId]);

  const getLabels = async () => {
    const tmpList = await labelsGetCollection();
    setLabelsList(tmpList);
  };

  const getLabelRefs = async (id) => {
    const tmpList = await labelsRefsGet(id);
    setLabelRefsList(tmpList);
  };

  const updateLabels = async (labels, refs) => {
    await labelsSetCollection(labels);
    setLabelsList(labels);

    await labelsRefsUpdate(trackId, refs, labels);
    setLabelRefsList(refs);
    setUpdateTrack(true);

    props.onDone && props.onDone();
  };

  const updateLabelList = async (labels, refs) => {
    await labelsSetCollection(labels);
    setLabelsList(labels);

    await labelsRefsUpdate(trackId, refs, labels);
    setLabelRefsList(refs);
    setUpdateTrack(true);
  };

  return (
    <LabelsEditor
      edit
      trackId={trackId}
      labelRefsList={labelRefsList}
      labelsList={labelsList}
      onClose={() => props.onDone()}
      onUpdate={(labls, rfs) => updateLabelList(labls, rfs)}
    />
  );
};

export default React.memo(LabelsWrapper);
