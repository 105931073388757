import React, { useContext } from "react";

import GuiSplitContext from "../../../../Context/GuiSplitContext";
import { useTranslation } from "react-i18next";

import Button from "../../../Trackalyze/Button";
import TracksNavigation from "./TracksNavigation";
import DeviceContext from "../../../../Context/DeviceContext";

const MainNavigationTrack = (props) => {
  const { t } = useTranslation();
  const { isDeviceTablet } = useContext(DeviceContext);
  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  /*   useEffect(() => {
    if (
      !guiSplit.tracks &&
      !guiSplit.details &&
      !guiSplit.profiles &&
      !guiSplit.rounds &&
      guiSplit.expanded
    ) {
      setGuiSplit({
        ...guiSplit,
        expanded: false,
      });
    } else if (
      !guiSplit.tracks &&
      !guiSplit.details &&
      !guiSplit.profiles &&
      !guiSplit.rounds
    ) {
    }
  }, [guiSplit]);
 */
  return (
    <>
      <div className="ta-flex ta-flex--out ta-margin_xxxs">
        {!props.simple && (
          <Button
            onClick={() =>
              setGuiSplit({
                ...guiSplit,
                details: false,
                rounds: false,
                profiles: false,
                tracks: false,
                labels: false,
                expanded: false,
              })
            }
            icon
            symbol="close"
            iconProps={{ className: "ta-color-text_on-surface-low" }}
          />
        )}
        <TracksNavigation />

        <div className="ta-flex">
          <Button
            symbol="info"
            icon
            tonal={guiSplit.details}
            onClick={() =>
              setGuiSplit({
                ...guiSplit,
                details: !guiSplit.details,
                rounds: false,
                labels: false,
                profiles: !isDeviceTablet ? false : guiSplit.profiles,
              })
            }
          />

          <Button
            symbol="rounds"
            icon
            tonal={guiSplit.rounds}
            onClick={() =>
              setGuiSplit({
                ...guiSplit,
                details: false,
                rounds: !guiSplit.rounds,
                labels: false,
                profiles: !isDeviceTablet ? false : guiSplit.profiles,
              })
            }
          />
          <Button
            symbol="chart"
            icon
            tonal={guiSplit.profiles}
            onClick={() =>
              setGuiSplit({
                ...guiSplit,
                rounds: !isDeviceTablet ? false : guiSplit.rounds,
                details: !isDeviceTablet ? false : guiSplit.details,
                profiles: !guiSplit.profiles,
              })
            }
          />
        </div>
        {!props.simple && (
          <Button
            onClick={() =>
              setGuiSplit({ ...guiSplit, expanded: !guiSplit.expanded })
            }
            icon
            iconProps={{ className: "ta-color-text_on-surface-low" }}
            symbol={!guiSplit.expanded ? "unfold_more" : "unfold_less"}
          />
        )}
      </div>
    </>
  );
};

export default MainNavigationTrack;
