import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import MapTypeContext from "../../../../Context/MapTypeContext";
import Typo from "../../../Trackalyze/Typo";

const MapTypes = (props) => {
  const { t } = useTranslation();

  const { mapType, setMapType, MAP_TYPES } = useContext(MapTypeContext);
  const renderItem = (value) => {
    return (
      <div
        key={value.id}
        className={"maptypes_item"}
        onClick={() => {
          setMapType(value);
          // props.callBack && props.callBack();
        }}
      >
        <img
          className={
            "maptypes_img ta-shape_l ta-padding_xxxs " +
            (mapType.id === value.id ? "maptype--active" : "")
          }
          src={"/images/map_" + value.id + ".jpg"}
        />
        <Typo
          size="s"
          className={
            "ta-flexitem ta-margin_top-xxxs " +
            (mapType.id === value.id
              ? "ta-color-text_primary"
              : "ta-color-text_on-surface-low")
          }
        >
          {t("maptypes." + value.id)}
        </Typo>
      </div>
    );
  };
  return (
    <div className="maptypes_items">
      {MAP_TYPES.map((value) => {
        return renderItem(value);
      })}
    </div>
  );
};

export default React.memo(MapTypes);
