import React, { useContext } from "react";

import TrackContext from "../../../../Context/TrackContext";
import TracksInfoButton from "../../MapWrapper/GuiMap/TracksInfoButton";
import Button from "../../../Trackalyze/Button";
import Toolbar from "../../../Trackalyze/Toolbar";
import { useTranslation } from "react-i18next";
import GuiSplitContext from "../../../../Context/GuiSplitContext";
import TextField from "../../../Trackalyze/TextField";

const TracksToolbar = (props) => {
  const { t } = useTranslation();
  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  const { setImportDone } = useContext(TrackContext);
  return (
    <Toolbar
      action={{ symbol: "close", onClick: () => props.onClose() }}
      title={props.tracksCount < 2 ? t("trackmanager.title_one") : ""}
    >
      <div className="ta-flexitem ta-flex ta-flex--itemscenter ta-flex--out">
        {props.tracksCount > 1 && (
          <TextField
            focus
            search
            xsymbol="search"
            reset
            value={props.searchKey}
            onChange={(value) => {
              props.setSearchKey(value);
            }}
            style={{ height: "48px" }}
            placeholder="Search in tracks"
          />
        )}
        <div className="ta-flex ta-flex--end ta-flex--itemscenter">
          {/*         <Button
          onClick={() => props.showFiltersToggle()}
          icon
          symbol="search"
          tertiary={props.showFilters}
          disabled={props.trackItems.length < 2}
        /> */}
          {props.searchKey === "" && props.tracksCount > 1 && (
            <TracksInfoButton trackItems={props.trackItems} />
          )}
          {/* 
          <Button
            onClick={() => props.onExpand()}
            icon
            symbol={!guiSplit.expanded ? "unfold_more" : "unfold_less"}
          /> */}
        </div>
      </div>
    </Toolbar>
  );
};

export default TracksToolbar;
