import React, { useContext, useEffect, useState, lazy, Suspense } from "react";

import MapLabelContext from "../../../Context/MapLabelContext";
import DeviceContext from "../../../Context/DeviceContext";
import { useJsApiLoader } from "@react-google-maps/api";
import GuiMapPortrait from "./GuiMap/GuiMapPortrait";
import Typo from "../../Trackalyze/Typo";
import ProgressIndicator from "../../Trackalyze/ProgressIndicator";
import PositionAbsolute from "../../Trackalyze/patterns/PositionAbsolute";
import GuiSplitContext from "../../../Context/GuiSplitContext";
import TrackContext from "../../../Context/TrackContext";

import LabelsWrapper from "./GuiMap/LabelsWrapper";
import PanelWrapper from "./PanelWrapper";
import Details from "../Components/Details/Details";
import TrackRounds from "../Components/Rounds/TrackRounds";
import TrackProfiles from "./GuiMap/TrackProfiles";
import guiManager from "../../utils/guiManager";
import Dialog from "../../Trackalyze/Dialog";
import Tracks from "../Components/Tracks/Tracks";

const MapGoogle = lazy(() => import("../GoogleMap/MapGoogle"));

const REACT_APP_GOOGLE_KEY = process.env.REACT_APP_GOOGLE_KEY;

const MapWrapper = (props) => {
  const { track, setTrackId } = useContext(TrackContext);

  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  const { trackPoint } = useContext(MapLabelContext);
  const { windowWidth, windowHeight, isDevicePortrait, isDeviceTablet } =
    useContext(DeviceContext);

  // *** map loaded
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: REACT_APP_GOOGLE_KEY, // ,
    // ...otherOptions
  });
  // *** END map loaded

  const [mapClicked, setMapClicked] = useState(1);
  const [mapLocation] = useState({});

  const [isGui, setIsGui] = useState(true);
  const [tracksVisible, setTracksVisible] = useState(true);
  const [infoItems, setInfoItems] = useState({});

  const [tracksPolyOpacity, setTracksPolyOpacity] = useState(1);

  const [mapCenter, setMapCenter] = useState({});
  const [mapBoundaries, setMapBoundaries] = useState({});

  useEffect(() => {
    console.log(
      "%c RENDERED mapwrapper",
      "background-color: red;padding: 4px;",
      props
    );
  }, []);

  useEffect(() => {
    console.log(
      "%c RENDERED mapwrapper",
      "background-color: red;padding: 4px;",
      props
    );
    console.log("device isDeviceTablet", isDeviceTablet);
    console.log("device isDevicePortrait", isDevicePortrait);
    console.log("device windowWidth", windowWidth);
    console.log("device windowWidth", windowHeight);
  }, [windowWidth, windowHeight, isDeviceTablet, isDevicePortrait]);

  // *** breaks

  // *** end breaks

  // *** track point
  useEffect(() => {
    if (isLoaded) {
      if ("latlong" in trackPoint) {
        setMapCenter({
          ...trackPoint,
          showMarker: true,
          lat: trackPoint.latlong.lat,
          lng: trackPoint.latlong.lng,
        });
        setInfoItems(trackPoint);
      }
    } else {
      setMapCenter({});
      setInfoItems({});
    }
  }, [trackPoint]);
  // *** END track point

  // *** tracksmap
  useEffect(() => {
    if (isLoaded && "tracksPoints" in props) {
      if (track !== undefined) {
        fitBoundaries(track.$.coordinatesBoundaries);
      } else if ("boundaries" in props.tracksPoints) {
        setMapCenter({});
        fitBoundaries(props.tracksPoints.boundaries);
      }
    }
  }, [isLoaded, props.tracksPoints]);
  // *** END tracks

  // *** track
  useEffect(() => {
    if (track !== undefined) {
      setInfoItems({});
    }
  }, [track]);
  // *** END track

  const fitBoundaries = (bounds) => {
    if (track != undefined) {
      setMapBoundaries(bounds);
    }
  };
  // *** END boundaries

  // *** map events
  const onMapZoomed = (value) => {
    if (track !== undefined && false) {
      // in view
      const trackBound = track.$.coordinatesBoundaries;
      let inView = false;

      if (
        (trackBound.latMin > value.viewBoundaries.latMin &&
          trackBound.latMin < value.viewBoundaries.latMax) ||
        (trackBound.latMax > value.viewBoundaries.latMin &&
          trackBound.latMax < value.viewBoundaries.latMax)
      ) {
        inView = true;
        console.log("inView ", true);
      }

      if (inView) {
        if (
          (trackBound.lngMin > value.viewBoundaries.lngMin &&
            trackBound.lngMin < value.viewBoundaries.lngMax) ||
          (trackBound.lngMax > value.viewBoundaries.lngMin &&
            trackBound.lngMax < value.viewBoundaries.lngMax)
        ) {
          inView = true;
          console.log("inView ", true);
        } else {
          console.log("inView ", false);
          inView = false;
        }
      }

      // opacity of polyline depending on zoom / in view
      let polyOpac = tracksPolyOpacity || 0.5;

      if (inView) {
        console.log("track ", track);
        console.log("value ", value);
        console.log("value.zoom ", value.zoom);
        console.log("value.viewBoundaries ", value.viewBoundaries);

        polyOpac = value.zoom < 12 ? 0.5 : polyOpac;
        polyOpac = value.zoom > 11 && value.zoom < 13 ? 0.25 : polyOpac;
        polyOpac = value.zoom > 12 && value.zoom < 14 ? 0.125 : polyOpac;
        polyOpac = value.zoom > 13 ? 0.075 : polyOpac;
      } else {
        polyOpac = 0.5;
      }

      setTracksPolyOpacity(polyOpac);
      console.log("polyOpac ", polyOpac);
    }
  };
  // *** END map events

  return (
    <div
      id="trackmap"
      className="ta-background ta-flex ta-flex--column appgui ta-height_full ta-width_full"
    >
      <div
        id="guiContainer"
        className="layout_panels guicontainer ta-flexitem ta-flex ta-is-overflow "
      >
        <div
          className={
            "ta-flexitem ta-flex " +
            (isDevicePortrait ? " ta-flex--column " : " ")
          }
        >
          {!guiSplit.expanded && (
            <div className="ta-flex ta-flex--column ta-flexitem">
              <div
                id="guiCenter"
                className="layout_panel layout_panel--center ta-flexitem ta-is-relative"
              >
                <GuiMapPortrait
                  isGui={isGui}
                  infoItems={infoItems}
                  mapLocation={mapLocation}
                  updateMapLocation={(value) => setMapCenter(value)}
                  mapClicked={mapClicked}
                  updateMapZoom={(value) =>
                    setMapCenter({ ...mapCenter, zoom: value })
                  }
                  ToggleTracksMap={() => setTracksVisible(!tracksVisible)}
                />

                <Suspense
                  fallback={
                    <PositionAbsolute center>
                      <div className="ta-surface ta-padding_s ta-shape_m">
                        <Typo className="ta-margin_bottom-xxs">
                          Loading map ...
                        </Typo>
                        <ProgressIndicator />
                      </div>
                    </PositionAbsolute>
                  }
                >
                  {isLoaded && (
                    <MapGoogle
                      mapBoundaries={mapBoundaries}
                      mapCenter={mapCenter}
                      onDragged={(value) => {
                        // setMapTracksFit(false);
                        onMapZoomed(value);
                        // setMapBoundaries({});
                      }}
                      onTrackClick={(value) => {
                        console.log("onTrackClick", value);
                        // setInfoItems(value);
                        // setMapCenter(value);
                      }}
                      onZoomed={(value) => {
                        //setIsDragged(true);
                        onMapZoomed(value);
                        setMapBoundaries({});
                      }}
                      onClick={(value) => {
                        mapClicked == 1 ? setMapClicked(0) : setMapClicked(1);
                        //  setIsGui(!isGui);
                        setInfoItems({});
                        setMapCenter({});
                        //  setTrackId(-1);
                      }}
                    />
                  )}
                </Suspense>
              </div>

              {track !== undefined && isDeviceTablet && guiSplit.profiles && (
                <>
                  <div
                    id="guiBottom"
                    className="layout_panel layout_panel--bottom"
                  >
                    <TrackProfiles />
                  </div>
                </>
              )}
            </div>
          )}

          {track !== undefined && !isDeviceTablet && guiSplit.profiles && (
            <PanelWrapper>
              <TrackProfiles />
            </PanelWrapper>
          )}
          {track !== undefined && guiSplit.labels && <LabelsWrapper />}
          {track !== undefined && guiSplit.details && (
            <>
              <PanelWrapper>
                <Details />
              </PanelWrapper>
            </>
          )}

          {track !== undefined && guiSplit.rounds && (
            <PanelWrapper>
              <TrackRounds />
            </PanelWrapper>
          )}
          {track !== undefined &&
            guiSplit.tracks &&
            guiManager(
              <Dialog
                className="tracks_sidebar"
                animate={guiSplit.tracks}
                fullScreen="slideFromLeft"
                noScrim
              >
                <Tracks
                  onTrackClick={(value) => {
                    setTrackId(value);
                    isDevicePortrait &&
                      !isDeviceTablet &&
                      setGuiSplit({ ...guiSplit, tracks: false });
                  }}
                />
              </Dialog>,
              "root"
            )}
        </div>
      </div>
      {/*       {track !== undefined && (
        <div className="ta-margin_top-xxs ta-padding_leftright-xxxs ta-elevation_1 ta-elevation--shadow ta-shape_l">
          <DetailsHeader />
          <MainNavigationTrack />
        </div>
      )} */}
      {/* 
      <MainNavigation /> */}
    </div>
  );
};

export default React.memo(MapWrapper);
