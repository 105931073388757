import React, { useEffect, useState } from "react";

import Scrim from "./Scrim";

const BottomSheet = (props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (props.animate !== animate && props.animate === false) {
      close();
    } else if (props.animate === true) {
      window.setTimeout(() => {
        setAnimate(true);
      }, 100);
    }
  }, [props.animate]);

  const close = () => {
    if (animate) {
      setAnimate(false);
      if (props.onAfterAnimate) {
        window.setTimeout(() => {
          props.onAfterAnimate();
        }, 500);
      }
    }
  };

  const buildClasses = () => {
    const classes = ["ta-bottomsheet"];
    !props.fullScreen &&
      !props.noFill &&
      (props.elevation
        ? classes.push("ta-elevation--shadow ta-elevation_" + props.elevation)
        : classes.push("ta-elevation_1 ta-elevation--shadow"));
    props.fullScreen && classes.push("ta-bottomsheet--fullscreen");
    if (typeof props.animate === "string") {
      classes.push("ta-bottomsheet--" + props.animate);
    }
    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <>
      {!props.noScrim && (
        <Scrim
          zIndex={props.zIndex || 2399}
          onClick={() => close()}
          animate={animate}
        />
      )}
      <div
        className={
          buildClasses() +
          (animate && !props.animateClass ? " ta-bottomsheet--animate " : "") +
          (animate && props.animateClass ? " " + props.animateClass : "")
        }
        style={props.zIndex ? { zIndex: props.zIndex + 1 } : {}}
      >
        {props.children}
      </div>
    </>
  );
};

export default BottomSheet;
