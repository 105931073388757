import React, { useContext, useState } from "react";

import RoundsSelectionMenu from "./RoundsSelectionMenu";
import RoundsTable from "./RoundsTable";
import TrackContext from "../../../../Context/TrackContext";
import ProgressIndicator from "../../../Trackalyze/ProgressIndicator";

const TrackRounds = () => {
  const { track } = useContext(TrackContext);
  const [index, setIndex] = useState(0);

  return track !== undefined ? (
    <>
      <>
        <div className="ta-margin_leftright-xs ta-flex ta-flex--itemscenter ta-flex--center">
          <RoundsSelectionMenu
            callBack={(value) => {
              setIndex(value);
            }}
          />
        </div>
        {track !== undefined && (
          <div className="ta-flexitem ta-is-overflow ta-margin_top-xxs ta-shape_m">
            <RoundsTable track={track} index={index} />
          </div>
        )}
      </>
    </>
  ) : (
    <div className="ta-is-center">
      <ProgressIndicator circular />
    </div>
  );
};

export default React.memo(TrackRounds);
