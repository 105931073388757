import React, { useContext } from "react";

import TrackContext from "../../../../Context/TrackContext";
import Button from "../../../Trackalyze/Button";

const TrackNextButton = (props) => {
  const { tracks, trackId, setTrackId } = useContext(TrackContext);

  const nextTrack = () => {
    let newId = 0;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].id === trackId) {
        newId = i + 1;
        if (newId >= tracks.length) {
          newId = 0;
        }
      }
    }
    setTrackId(tracks[newId].id);
  };

  return (
    <Button
      icon
      text
      disabled={props.disabled || tracks.length < 2}
      symbol="keyboard_arrow_right"
      onClick={() => nextTrack()}
      {...props}
    />
  );
};

export default React.memo(TrackNextButton);
