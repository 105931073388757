import React, { useContext, useEffect, useState } from "react";

import TrackContext from "../../../Context/TrackContext";
import guiManager from "../../utils/guiManager";

import Button from "../../Trackalyze/Button";
import Snackbar from "../../Trackalyze/Snackbar";

import { useTranslation } from "react-i18next";

const SettingsStorage = () => {
  const { t } = useTranslation();

  const { tracks, clearLocalStorage } = useContext(TrackContext);
  const [removalFeedback, setRemovalFeedback] = useState(-1);

  useEffect(() => {
    // setRemovalFeedback(tracks.length);
    if (removalFeedback === -1) {
      setRemovalFeedback(tracks.length);
    } else if (tracks.length === 0) {
      setRemovalFeedback(-2);
    }
  }, [tracks]);

  return (
    <>
      <div className="ta-is-center ta-margin_xxs">
        <Button
          onClick={() => clearLocalStorage()}
          symbol="delete_forever"
          disabled={tracks.length === 0}
          variant="outline"
        >
          {t("settings.storage.button_cache_clear")}
        </Button>
      </div>

      {removalFeedback === -2 &&
        guiManager(
          <Snackbar fixed animate timeOut>
            {t("settings.storage.snackbar_cache_cleared")}
          </Snackbar>
        )}
    </>
  );
};

export default React.memo(SettingsStorage);
