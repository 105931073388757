import React from "react";

import { useNavigate } from "react-router-dom";
import Button from "../../../Trackalyze/Button";

const SettingsButton = (props) => {
  const navigate = useNavigate();
  return (
    <Button
      elevation
      icon
      symbol="account_manage"
      tonal
      onClick={() => navigate("/settings")}
      {...props}
    />
  );
};

export default React.memo(SettingsButton);
