import React from "react";
import ProfilesPanel from "../../Views/MapWrapper/ProfilesPanel";

const PositionAbsolute = ({
  relative,
  top,
  left,
  right,
  center,
  bottom,
  className,
  children,
  style,
}) => {
  const classes = [];

  relative ? classes.push("ta-is-relative") : classes.push("ta-is-absolute");
  top && left && classes.push("ta-is-topleft");
  top && right && classes.push("ta-is-topright");
  top && center && classes.push("ta-is-topcenter");
  left && center && classes.push("ta-is-leftcenter");
  right && center && classes.push("ta-is-rightcenter");
  bottom && left && classes.push("ta-is-bottomleft");
  bottom && right && classes.push("ta-is-bottomright");
  bottom && center && classes.push("ta-is-bottomcenter");
  !bottom &&
    !top &&
    !left &&
    !right &&
    center &&
    classes.push("ta-is-centercenter");
  className && classes.push(className);

  const filteredClasses = classes.join(" ");

  return (
    <div style={{ ...style, zIndex: 1 }} className={filteredClasses}>
      {children}
    </div>
  );
};

export default PositionAbsolute;
