import React, { useState, useEffect } from "react";

const MapLabelContext = React.createContext();
export default MapLabelContext;

export const MapLabelProvider = (props) => {
  const [segment, setSegment] = useState({ start: 0, end: 0 });
  const [roundsSideSteps, setRoundsSideSteps] = useState(1);

  const [labelTrackChart, setLabelTrackChart] = useState();
  const [trackPoint, setTrackPoint] = useState({});
  const [quickInfoItems, setQuickInfoItems] = useState({});

  useEffect(() => {
    console.log("context setTrackPoint", trackPoint);
  }, [trackPoint]);
  return (
    <MapLabelContext.Provider
      value={{
        segment,
        setSegment,
        labelTrackChart,
        setLabelTrackChart,
        roundsSideSteps,
        setRoundsSideSteps,
        trackPoint,
        setTrackPoint,
        quickInfoItems,
        setQuickInfoItems,
      }}
    >
      {props.children}
    </MapLabelContext.Provider>
  );
};
