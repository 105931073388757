import simplify from "../utils/simplify";
import { TRACK_CLASSIFICATION_MAPPING } from "../../js/config/mapTypes";
import {
  formatSecondsToHHMMSS,
  formatDateTime,
  formatDuration,
  formatDistance,
  formatTime,
  roundNumber,
} from "../utils/formatter";
import { sysLog } from "./helper";

export const buildTrackObject = (gpxData) => {
  /*   const trackpoints = parseTCX(track);
  console.log("parse tcx", trackpoints); */

  const gpx = gpxDomParser(gpxData);
  console.log("tcx gpxDomParser", gpx);

  const tracks = buildTracksData(gpx);
  sysLog("buildtracksdata tracks", tracks, "red");
  return { tracks: tracks, gpx: gpx };
};

export const buildTracksData = (gpx) => {
  console.log("buildtracksdata gpx", gpx);
  const tracks = [];
  for (let i = 0; i < gpx.tracks.length; i++) {
    const calSimpTrack = simplify(gpx.tracks[i].points, 0.000025);

    // let statistics = calcSegment(gpx.tracks[i].points);
    let statistics = calcSegment(calSimpTrack);

    // legacy
    statistics.type = "";
    if ("time" in statistics) {
      const durParts = statistics.time.duration.split(":");
      if (durParts.length > 0) {
        statistics.time.parts = { days: 0, hours: 0, minutes: 0 };

        if (durParts.length > 2) {
          statistics.time.parts.days = Number(durParts[0]);
          statistics.time.parts.hours = Number(durParts[1]);
          statistics.time.parts.minutes = Number(durParts[2]);
        } else if (durParts.length > 1) {
          statistics.time.parts.hours = Number(durParts[0]);
          statistics.time.parts.minutes = Number(durParts[1]);
        } else if (durParts.length > 0) {
          statistics.time.parts.minutes = Number(durParts[0]);
        }
      }
    }

    // end of legacy

    const simTrck = simplify(gpx.tracks[i].points, 0.00005);

    const trackObject = {};

    trackObject.$ = statistics.$;
    trackObject.$.trackPointsSimple = simTrck;

    // waypoints

    if (gpx.waypoints.length) {
      trackObject.$.markerWaypoints = gpx.waypoints;
    }

    // end of waypoints

    // rounds
    const rounds = [];
    [0.5, 1, 2, 5, 10, 20, 50].filter((round) => {
      return (
        statistics.distance / 1000 > round && round !== 0 && rounds.push(round)
      );
    });
    trackObject.$.roundsIndex = rounds;

    const roundsRows = [];
    for (let ir = 0; ir < rounds.length; ir++) {
      roundsRows.push(calcRounds(gpx.tracks[i].points, rounds[ir]).rounds);
    }
    trackObject.$.roundsTrackPoints = roundsRows;
    // end of rounds

    // in motion

    // flattened
    if ("time" in statistics) {
      const inMotionStats = removeBreaks(gpx.tracks[i].points, 120, 40);
      if ("distanceInMotion" in inMotionStats.$) {
        trackObject.$ = { ...trackObject.$, ...inMotionStats.$ };
      }
    }

    // rounds
    const tmpTrackRoundsSummary = calcRounds(calSimpTrack, 0.3).summary;

    // flattened

    trackObject.$ = { ...trackObject.$, ...tmpTrackRoundsSummary.$ };

    // trackpoints/rounds for charts
    trackObject.$.chartsTrackPoints = calcRounds(calSimpTrack, 0.1);

    // flattened data

    trackObject.$ = {
      ...trackObject.$,
      metaDataDesc: gpx.metadata.desc,
      metaDataTime: gpx.metadata.time,
      metaDataLink: gpx.metadata.link,
      activityType:
        "type" in gpx.tracks[i].metadata
          ? gpx.tracks[i].metadata.type.toLowerCase()
          : "",
      activityName:
        "name" in gpx.tracks[i].metadata ? gpx.tracks[i].metadata.name : "",
    };

    if ("timeStart" in trackObject.$) {
      trackObject.$.activityStartTime = trackObject.$.timeStart;
    } else {
      trackObject.$.activityStartTime = new Date().getTime();
    }
    tracks.push(trackObject);
  }
  return tracks;
};

export const getPostTrackData = (tmpTrack, profile) => {
  // *** fitness
  let fitnessData = { kCal: 0, steps: 0 };
  if ("heartrateAvg" in tmpTrack && "timeStart" in tmpTrack) {
    fitnessData = buildFitnessData(
      {
        hours: Number(tmpTrack.durationSeconds / 60 / 60),
        minutes: Number(tmpTrack.durationSeconds / 60),
        km: Number(tmpTrack.distance / 1000),
        hr: tmpTrack.heartrateAvg,
      },
      profile
    );
  } else {
    fitnessData = buildFitnessData(
      { hours: 0, minutes: 0, km: Number(tmpTrack.distance / 1000), hr: 0 },
      profile
    );
  }

  const fitData = {
    fitnessKCal: fitnessData.kCal,
    fitnessSteps: fitnessData.steps,
    fitnessHeartratePercent: fitnessData.hrPercent,
  };

  // formatted data
  const formattedData = {
    ...formatDistance(tmpTrack.distance),
    paceAvg: formatDuration(tmpTrack.paceAvg * 60),
    paceAvgInMotion: formatDuration(tmpTrack.paceAvgInMotion * 60),
    paceMin: formatDuration(tmpTrack.paceMin * 60),
    paceMax: formatDuration(tmpTrack.paceMax * 60),
    duration: formatDuration(tmpTrack.durationSeconds),
    durationInMotion: formatDuration(tmpTrack.durationInMotionSeconds),
    durationStops: formatDuration(tmpTrack.durationStopsSeconds),
    timeStart: formatDateTime(tmpTrack.timeStart),
    timeEnd: formatDateTime(tmpTrack.timeEnd),
  };
  // end of formatted data

  const postData = fitData;
  postData.formatted = formattedData;

  // activity type
  const actType = tmpTrack.activityType;
  if (actType in TRACK_CLASSIFICATION_MAPPING) {
    postData.activityType = TRACK_CLASSIFICATION_MAPPING["" + actType];
  } else {
    console.log("no activity found", actType);
  }
  return postData;
  // *** END fitness
};

const buildFitnessData = (
  data = { hours: 0, minutes: 0, km: 0, hr: 0 },
  profile = {}
) => {
  // für Männer: Grundumsatz = [Körpergewicht in kg] * 24 * 1,0. für Frauen: Grundumsatz = [Körpergewicht in kg] * 24 * 0,9.
  // easy formula
  // Energieumsatz = 1 kcal pro kg Körpergewicht und Kilometer
  // männer
  // [(Alter x 0.2017) + (Gewicht x 0.09036) + (Herzfrequenz x 0.6309) - 55.0969] x Laufdauer / 4.184
  // frauen
  // [(Alter x 0.074) + (Gewicht x 0.05741) + (Herzfrequenz x 0.4472) - 20.4022] x Laufdauer / 4.184

  // hr in percent
  let hrPercent = 0;
  if ("hrMax" in profile && data.hr !== 0) {
    hrPercent = parseInt((data.hr * 100) / profile.hrMax);
  }

  let baseKCal = 0;
  if (data.hours > 0) {
    if (profile.gender === "male") {
      baseKCal = profile.weight * data.hours * 1;
    } else {
      baseKCal = profile.weight * data.hours * 0.9;
    }
  }

  // simple calories
  let kCal = profile.age * data.km * 0.9;

  if (data.hr > 0 && data.minutes > 0) {
    const hRate = data.hr;

    if (profile.gender === "male") {
      kCal =
        ((profile.age * 0.2017 +
          profile.weight * 0.09036 +
          hRate * 0.6309 -
          55.0969) *
          data.minutes) /
        4.184;
    } else {
      kCal =
        ((profile.age * 0.074 +
          profile.weight * 0.05741 +
          hRate * 0.4472 -
          20.4022) *
          data.minutes) /
        4.184;
    }
  }
  kCal = kCal - baseKCal;

  return {
    kCal: parseInt(kCal),
    steps: parseInt((data.km * 1000 * 100) / profile.step),
    hrPercent: hrPercent,
  };
};

const transformGPXV2 = (gpxData) => {
  const gpxNew = GPX.parse(gpxData);
  const rawPoints = [];
  let tmpObj = {};
  for (let ix = 0; ix < gpxNew.trk[0].trkseg[0].trkpt.length; ix++) {
    tmpObj = {
      lat: parseFloat(gpxNew.trk[0].trkseg[0].trkpt[ix].$.lat),
      lng: parseFloat(gpxNew.trk[0].trkseg[0].trkpt[ix].$.lon),
    };

    if (gpxNew.trk[0].trkseg[0].trkpt[ix].time) {
      tmpObj.time = gpxNew.trk[0].trkseg[0].trkpt[ix].time;
    }

    if (gpxNew.trk[0].trkseg[0].trkpt[ix].ele) {
      tmpObj.ele = parseFloat(gpxNew.trk[0].trkseg[0].trkpt[ix].ele);
    }
    if (gpxNew.trk[0].trkseg[0].trkpt[ix].extensions) {
      if (
        gpxNew.trk[0].trkseg[0].trkpt[ix].extensions["ns3:TrackPointExtension"]
      ) {
        if (
          gpxNew.trk[0].trkseg[0].trkpt[ix].extensions[
            "ns3:TrackPointExtension"
          ]["ns3:hr"]
        ) {
          tmpObj.hr = parseInt(
            gpxNew.trk[0].trkseg[0].trkpt[ix].extensions[
              "ns3:TrackPointExtension"
            ]["ns3:hr"]
          );
        }
      }
    }
    rawPoints.push(tmpObj);
  }
  return {
    track: rawPoints,
    metadata: {
      creator: gpxNew.$.creator || "",
      name: gpxNew.trk[0].name || "",
      type: gpxNew.trk[0].type || "",
      time: gpxNew.metadata ? gpxNew.metadata.time || "" : "",
    },
  };
};

const transformGPX = (gpxData) => {
  const gpx = new gpxParser(); //Create gpxParser Object
  gpx.parse(gpxData); //parse gpx file from string data

  const rawPoints = [];
  for (let idx = 0; idx < gpx.tracks[0].points.length; idx++) {
    rawPoints.push({
      lat: gpx.tracks[0].points[idx].lat,
      lng: gpx.tracks[0].points[idx].lon,
      ele: gpx.tracks[0].points[idx].ele,
      time: gpx.tracks[0].points[idx].time,
    });
  }
  return { track: rawPoints, metaData: {} };
};

export const removeBreaks = (tmpTrack, cmpTime = 60, cmpDist = 30) => {
  // *** TODO: simplify track for calculations after building new track?
  const origDurationSeconds = parseInt(
    (new Date(tmpTrack[tmpTrack.length - 1].time).getTime() -
      new Date(tmpTrack[0].time).getTime()) /
      1000
  );

  // *** time in motion

  let secondsInMotion = 0;
  let distanceSum = 0;

  const breaks2 = [];
  let newTrack2 = [];

  let movingIdx = 0;
  let stopStartIdx = 0;
  let stopEndIdx = 0;
  let breakIdx = 0;
  let breakIdxEnd = 0;
  let breakDist = 0;
  let breakDistCur = 0;
  let movingDist = 0;
  let isMoving = true;
  let isMovingTmp = true;
  let movingTime = 0;
  let movingTimeTotal = 0;
  let movingPace = 0;

  for (let pidx = 0; pidx < tmpTrack.length; pidx++) {
    if (pidx > 0) {
      // add distances
      movingDist =
        movingDist +
        calcDistance(
          tmpTrack[pidx - 1].lat,
          tmpTrack[pidx - 1].lng,
          tmpTrack[pidx].lat,
          tmpTrack[pidx].lng
        );
      breakDistCur =
        breakDistCur +
        calcDistance(
          tmpTrack[pidx - 1].lat,
          tmpTrack[pidx - 1].lng,
          tmpTrack[pidx].lat,
          tmpTrack[pidx].lng
        );

      // check for distance moved
      if (movingDist >= 100) {
        movingTime =
          parseInt(
            new Date(tmpTrack[pidx].time).getTime() -
              new Date(tmpTrack[movingIdx].time).getTime()
          ) / 1000;
        movingPace = CalcPace(movingDist, movingTime / 60);

        // is moving?
        isMoving = movingPace > 24 ? false : true;

        // changing from not moving to moving
        if (isMoving && !isMovingTmp) {
          isMovingTmp = true;

          movingTimeTotal =
            movingTimeTotal +
            parseInt(
              new Date(tmpTrack[pidx].time).getTime() -
                new Date(tmpTrack[breakIdx].time).getTime()
            ) /
              1000;

          breaks2.push({
            ...tmpTrack[breakIdx],
            $: {
              ...tmpTrack[breakIdx],
              distance: parseInt(breakDistCur),
              durationSeconds:
                parseInt(
                  new Date(tmpTrack[pidx].time).getTime() -
                    new Date(tmpTrack[breakIdx].time).getTime()
                ) / 1000,
              durationMinutes: parseInt(
                (new Date(tmpTrack[pidx].time).getTime() -
                  new Date(tmpTrack[breakIdx].time).getTime()) /
                  1000 /
                  60
              ),
            },
          });
          stopEndIdx = pidx;
          breakIdxEnd = pidx;
        } else if (!isMoving && isMovingTmp) {
          // start of stop, not moving anymore

          isMovingTmp = false;
          breakDist = parseInt(breakDistCur / 1000);

          // seconds in motion to start of stop
          secondsInMotion =
            secondsInMotion +
            parseInt(
              (new Date(tmpTrack[pidx].time).getTime() -
                new Date(tmpTrack[breakIdxEnd].time).getTime()) /
                1000
            );

          // add new track from last stop to here
          // newTrack2 = newTrack2.concat(tmpTrack.slice(breakIdxEnd, pidx));
          breakIdx = pidx;
          if (stopEndIdx >= 0) {
            newTrack2 = newTrack2.concat(tmpTrack.slice(stopEndIdx, pidx));
          }

          stopStartIdx = pidx;
        }

        // reset distance check
        movingIdx = pidx;
        movingDist = 0;
      }
    }
  }

  /*   if (newTrack2.length) {
    newTrack2 = newTrack2.concat(
      tmpTrack.slice(newTrack2.length, tmpTrack.length - 1)
    );
  } */

  const breaksObject = {
    track: newTrack2,
    distance: Number(parseInt(breakDistCur / 1000)),
    seconds: Number(secondsInMotion),
    minutes: Number(secondsInMotion / 60),
    hours: Number(secondsInMotion / 60 / 60),
    duration: formatSecondsToHHMMSS(secondsInMotion),
    durationStops: origDurationSeconds - Number(secondsInMotion),
    pace: CalcPace(breakDistCur, secondsInMotion / 60),
    speed: CalcSpeed(breakDistCur, secondsInMotion / 60 / 60),
  };
  breaksObject.$ = {
    inMotionTrackPoints: newTrack2,
    distanceInMotion: parseInt(breakDistCur),
    durationInMotionSeconds: Number(secondsInMotion),
    durationStopsSeconds:
      Number(secondsInMotion) > 0
        ? Number(origDurationSeconds - Number(secondsInMotion))
        : 0,
    paceAvgInMotion: Number(CalcPace(breakDistCur, secondsInMotion / 60)),
    speedAvgInMotion: Number(
      CalcSpeed(breakDistCur, secondsInMotion / 60 / 60)
    ),
  };

  if (breaks2.length > 0) {
    breaksObject.breaks = breaks2;
    breaksObject.$.markerStops = breaks2;
  }

  return breaksObject;
};

// ** distanz zwischen zwei punkten

export const calcDistance = (lat1, lon1, lat2, lon2) => {
  const R = 6371000;
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const dLat1 = (lat1 * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(dLat1) * Math.cos(dLat1) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
};

export const calcElevation = (ele1, ele2) => {
  const elevation = {
    up: 0,
    down: 0,
    added: 0,
  };

  elevation.added = ele2 - ele1;
  elevation.average = (ele2 + ele1) / 2;

  if (ele2 > ele1) {
    elevation.up = ele2 - ele1;
  } else if (ele2 < ele1) {
    elevation.down = ele1 - ele2;
  }
  return elevation;
};

export const calcTime = (time1, time2) => {
  const seconds =
    (new Date(time2).getTime() - new Date(time1).getTime()) / 1000;
  const minutes = seconds / 60;
  const hours = minutes / 60;
  return { seconds: seconds, minutes: minutes, hours: hours };
};

export const CalcPace = (distance, minutes) => {
  let pace = minutes / (distance / 1000);
  const fullMinutes = parseInt(pace);
  const newSeconds = parseInt(1 * 60 * (pace - fullMinutes)).toString();
  return fullMinutes + "." + newSeconds.padStart(2, "0");
};

export const CalcSpeed = (distance, hours) => {
  let speed = distance / 1000 / hours;
  return Math.round(speed * Math.pow(10, 2)) / Math.pow(10, 2);
};

export const calcDuration = (time1, time2) => {
  var difference = time2 - time1;

  let durDays = new Date(difference).getUTCDay();
  let durHours = new Date(difference).getUTCHours();
  let durMinutes = new Date(difference).getUTCMinutes();
  let durSeconds = new Date(difference).getUTCSeconds();

  // durHours = durHours < 10 ? "0" + durHours : durHours;
  durSeconds = durSeconds < 10 ? "0" + durSeconds : durSeconds;

  let duration = 0;
  if (durHours > 0) {
    durMinutes = durMinutes < 10 ? "0" + durMinutes : durMinutes;
    duration += durHours;
    duration += ":" + durMinutes;

    //  duration += ":" + durSeconds;
  } else if (durMinutes > 0) {
    duration = "0:" + durMinutes; // + ":" + durSeconds;
  }
  return duration;
};

export const calcDurationInMotion = (seconds) => {
  return new Date(seconds * 1000).toISOString().substr(11, 8);
};

const DIM_THRESHOLD_DISTANCE = 2;
const DIM_THRESHOLD_DURATION = 20;

export const calcSegment = (segment) => {
  const calcedObject = {};
  let calcedObjectFlat = {};

  // start of calc

  let segmentDistance = 0;
  let lonMin = segment[0].lng;
  let lonMax = segment[0].lng;
  let latMin = segment[0].lat;
  let latMax = segment[0].lat;

  let hrMin = segment[0].hr || 0;
  let hrMax = segment[0].hr || 0;
  let hrAvg = 0;

  for (let sp = 0; sp < segment.length; sp++) {
    // *** boundaries
    segment[sp].lng < lonMin && (lonMin = segment[sp].lng);
    segment[sp].lat < latMin && (latMin = segment[sp].lat);
    segment[sp].lng > lonMax && (lonMax = segment[sp].lng);
    segment[sp].lat > latMax && (latMax = segment[sp].lat);
    // *** END boundaries

    // *** HR
    if ("hr" in segment[sp]) {
      segment[sp].hr < hrMin && (hrMin = segment[sp].hr);
      segment[sp].hr > hrMax && (hrMax = segment[sp].hr);

      hrAvg = hrAvg + segment[sp].hr;
    }
    // *** END HR

    if (sp > 0) {
      const lat1 = segment[sp - 1].lat;
      const lat2 = segment[sp].lat;
      const lon1 = segment[sp - 1].lng;
      const lon2 = segment[sp].lng;

      const distance = calcDistance(lat1, lon1, lat2, lon2);
      segmentDistance += Number.parseFloat(distance).toPrecision(2) * 1;
    }
  }

  calcedObject.distance = Math.ceil(segmentDistance);
  calcedObject.km = (Math.ceil(segmentDistance) / 1000).toFixed(1);

  // time calculations
  let startTime = "";
  let endTime = "";
  let startTimestamp = "";
  let endTimestamp = "";

  let segmentDuration = 0;
  let timeSplit = 0;
  let pace = 0;
  let speed = 0;

  if ("time" in segment[0] && "time" in segment[segment.length - 1]) {
    startTime = segment[0].time;
    endTime = segment[segment.length - 1].time;
    startTimestamp = new Date(startTime).getTime();
    endTimestamp = new Date(endTime).getTime();

    timeSplit = calcTime(startTime, endTime);
    pace = Number(CalcPace(segmentDistance, timeSplit.minutes));
    speed = CalcSpeed(segmentDistance, timeSplit.hours);

    segmentDuration = calcDuration(
      new Date(startTime).getTime(),
      new Date(endTime).getTime()
    );

    calcedObject.time = {
      duration: segmentDuration,
      pace: pace,
      speed: speed,
      startTime: startTime,
      endTime: endTime,
      durationSeconds: Number(parseInt((endTimestamp - startTimestamp) / 1000)),
      start: startTime ? formatTime(segment[0].time) : "",
      end: startTime ? formatTime(segment[segment.length - 1].time) : "",
      seconds: Number(startTime ? timeSplit.seconds : ""),
      minutes: Number(startTime ? timeSplit.minutes : ""),
      hours: Number(startTime ? timeSplit.hours : ""),
    };
    calcedObjectFlat = {
      ...calcedObjectFlat,
      durationSeconds: calcedObject.time.durationSeconds,
      timeStart: new Date(startTime).getTime(),
      timeEnd: new Date(endTime).getTime(),
      paceAvg: pace,
      speedAvg: speed,
    };
  } else {
    calcedObjectFlat = {
      ...calcedObjectFlat,
      durationSeconds: 0,
    };
  }

  // END time calculations

  // elevation calculations

  if ("ele" in segment[0]) {
    let segmentEleDown = 0;
    let segmentEleUp = 0;
    let segmentEle = 0;
    let eleMax = 0;
    let eleMin = 0;

    let segmentEleAverage = 0;
    let segmentEleAdded = 0;

    const elePoints = simplify(segment, 0.00006);

    eleMax = elePoints[0].ele;
    eleMin = elePoints[0].ele;

    for (let p2 = 0; p2 < elePoints.length; p2++) {
      if (p2 > 0) {
        const ele = calcElevation(elePoints[p2 - 1].ele, elePoints[p2].ele);
        segmentEle += ele.added;
        segmentEleAdded += elePoints[p2].ele;

        segmentEleUp += ele.up;
        segmentEleDown += ele.down;
        segmentEleAverage += ele.average;
        elePoints[p2].ele > eleMax && (eleMax = elePoints[p2].ele);
        elePoints[p2].ele < eleMin && (eleMin = elePoints[p2].ele);
      }
    }
    segmentEleAverage = segmentEleAverage / elePoints.length;

    /*    const elevationAngle = parseInt(
      (Math.atan2(
        elePoints[elePoints.length - 1].ele - elePoints[0].ele,
        calcedObject.distance
      ) *
        180) /
        Math.PI
    ); */
    calcedObject.elevation = {
      added: Number(parseInt(segmentEle)),
      up: Number(parseInt(segmentEleUp)),
      down: Number(parseInt(segmentEleDown)),
      min: Number(parseInt(eleMin)),
      max: Number(parseInt(eleMax)),
      angle: 0,
    };

    calcedObjectFlat = {
      ...calcedObjectFlat,
      elevationAdded: Number(parseInt(segmentEle)),
      elevationAvg: Number(parseInt((eleMin + eleMax) / 2)),
      elevationUp: Number(parseInt(segmentEleUp)),
      elevationDown: Number(parseInt(segmentEleDown)),
      elevationMin: Number(parseInt(eleMin)),
      elevationMax: Number(parseInt(eleMax)),
      elevationStart: Number(parseInt(elePoints[0].ele)),
      elevationEnd: Number(parseInt(elePoints[elePoints.length - 1].ele)),
      elevationAngle: 0,
    };
  } else {
    calcedObjectFlat = {
      ...calcedObjectFlat,
      elevationAdded: 0,
      elevationAvg: 0,
      elevationUp: 0,
      elevationDown: 0,
      elevationMin: 0,
      elevationMax: 0,
      elevationAngle: 0,
    };
  }

  // end of elevation

  if ("hr" in segment[0]) {
    hrAvg = hrAvg / segment.length;

    calcedObject.hr = {
      avg: Number(parseInt(hrAvg)),
      min: Number(parseInt(hrMin)),
      max: Number(parseInt(hrMax)),
    };

    calcedObjectFlat = {
      ...calcedObjectFlat,
      heartrateAvg: Number(parseInt(hrAvg)),
      heartrateMin: Number(parseInt(hrMin)),
      heartrateMax: Number(parseInt(hrMax)),
    };
  }
  // *** END HR

  // *** latlong
  calcedObject.latlong = {
    latFirst: segment[0].lat,
    lngFirst: segment[0].lng,
    lat: segment[segment.length - 1].lat,
    lng: segment[segment.length - 1].lng,
    latLast: segment[segment.length - 1].lat,
    lngLast: segment[segment.length - 1].lng,
    boundaries: {
      latMin: latMin,
      lngMin: lonMin,
      latMax: latMax,
      lngMax: lonMax,
    },
  };

  calcedObjectFlat = {
    ...calcedObjectFlat,
    coordinatesLatFirst: segment[0].lat,
    coordinatesLngFirst: segment[0].lng,
    coordinatesLatLast: segment[segment.length - 1].lat,
    coordinatesLngLast: segment[segment.length - 1].lng,
    coordinatesLatMin: latMin,
    coordinatesLngMin: lonMin,
    coordinatesLatMax: latMax,
    coordinatesLngMax: lonMax,
    coordinatesBoundaries: {
      latMin: latMin,
      lngMin: lonMin,
      latMax: latMax,
      lngMax: lonMax,
    },
  };

  // *** END latlong

  calcedObject.distance = Number(calcedObject.distance);
  calcedObjectFlat.distance = calcedObject.distance;

  calcedObject.$ = calcedObjectFlat;

  return calcedObject;
  // end of calc
};

export const calcRounds = (points, roundsCheck) => {
  // new rounds array
  const rounds = [];
  let roundIdx = 0;
  let roundDist = 0;
  let totalDist = 0;
  let roundsKm = 0;
  let distanceAdded = 0;

  let addedEleUp = 0;
  let addedEleDown = 0;

  let minMinutes = 0;
  let maxMinutes = 0;
  let minPace = -1;
  let maxPace = 0;
  let minSpeed = -1;
  let maxSpeed = 0;

  let lat1, lat2, lon1, lon2;

  let lonMin = points[0].lng;
  let lonMax = points[0].lng;
  let latMin = points[0].lat;
  let latMax = points[0].lat;

  let addedDuration = 0;
  const isTime = "time" in points[0] && "time" in points[points.length - 1];

  for (let p = 0; p < points.length; p++) {
    // *** boundaries
    points[p].lng < lonMin && (lonMin = points[p].lng);
    points[p].lat < latMin && (latMin = points[p].lat);
    points[p].lng > lonMax && (lonMax = points[p].lng);
    points[p].lat > latMax && (latMax = points[p].lat);
    // *** END boundaries

    if (p > 0) {
      lat1 = points[p - 1].lat;
      lat2 = points[p].lat;
      lon1 = points[p - 1].lng;
      lon2 = points[p].lng;

      const dist = calcDistance(lat1, lon1, lat2, lon2);
      roundDist += Number.parseFloat(dist).toPrecision(2) * 1;
      totalDist += dist;
      roundsKm += dist / 1000;

      if (roundDist >= roundsCheck * 1000) {
        // calc data for segment

        const segmentCalced = calcSegment(
          points.slice(roundIdx > 0 ? roundIdx - 1 : roundIdx, p)
        );
        distanceAdded += segmentCalced.$.distance;

        roundDist = 0;

        if ("time" in segmentCalced) {
          segmentCalced.pace = segmentCalced.time.pace;
          segmentCalced.paceAvg = segmentCalced.time.pace;
          segmentCalced.speed = segmentCalced.time.speed;
          segmentCalced.speedAvg = segmentCalced.time.speed;

          addedDuration = calcDuration(
            new Date(points[0].time).getTime(),
            new Date(points[p].time).getTime()
          );
          segmentCalced.addedDuration = addedDuration;

          if (minPace === -1) {
            minMinutes = segmentCalced.time.minutes;
            maxMinutes = segmentCalced.time.minutes;
            minPace = segmentCalced.time.pace;
            maxPace = segmentCalced.time.pace;
            minSpeed = segmentCalced.time.speed;
            maxSpeed = segmentCalced.time.speed;
          }

          if (segmentCalced.time.minutes < minMinutes) {
            minMinutes = segmentCalced.time.minutes;
            minPace = segmentCalced.time.pace;
          }
          if (segmentCalced.time.minutes > maxMinutes) {
            maxMinutes = segmentCalced.time.minutes;
            maxPace = segmentCalced.time.pace;
          }

          segmentCalced.paceMax = maxPace;
          segmentCalced.paceMin = minPace;

          if (segmentCalced.time.speed < minSpeed) {
            minSpeed = segmentCalced.time.speed;
          }
          if (segmentCalced.time.speed > maxSpeed) {
            maxSpeed = segmentCalced.time.speed;
          }

          segmentCalced.speedMax = maxSpeed;
          segmentCalced.speedMin = minSpeed;

          segmentCalced.minutes = parseFloat(segmentCalced.time.pace);
        }

        if ("elevation" in segmentCalced) {
          addedEleUp += segmentCalced.elevation.up;
          addedEleDown += segmentCalced.elevation.down;
          segmentCalced.elevationUp = addedEleUp;
          segmentCalced.elevationHeight = parseInt(
            (segmentCalced.elevation.min + segmentCalced.elevation.max) / 2
          );
          segmentCalced.elevationAngle = segmentCalced.elevation.angle;
        }

        if ("hr" in segmentCalced) {
          segmentCalced.hrVal = segmentCalced.hr.avg;
          segmentCalced.heartrateAvg = segmentCalced.hr.avg;
        }

        segmentCalced.start = roundIdx;
        segmentCalced.end = p;

        segmentCalced.distanceAdded = distanceAdded;
        segmentCalced.distanceAddedKm =
          formatDistance(distanceAdded).distanceKm;

        rounds.push(segmentCalced);
        roundIdx = p;
      }
    }
  }

  // calc and add rest of points
  if (roundIdx < points.length - 1) {
    const restSegment = points.slice(roundIdx, points.length - 1);
    const segmentCalced = calcSegment(restSegment);
    if (segmentCalced.$.distance > 0) {
      distanceAdded += segmentCalced.$.distance;

      roundsKm += segmentCalced.$.distance / 1000;

      if (isTime) {
        segmentCalced.addedDuration = calcDuration(
          new Date(points[0].time).getTime(),
          new Date(points[points.length - 1].time).getTime()
        );

        minMinutes =
          segmentCalced.time.minutes < minMinutes
            ? segmentCalced.time.minutes
            : minMinutes;
        maxMinutes =
          segmentCalced.time.minutes > maxMinutes
            ? segmentCalced.time.minutes
            : maxMinutes;

        segmentCalced.minutes = parseFloat(segmentCalced.time.pace);
      }

      if ("elevation" in segmentCalced) {
        addedEleUp += segmentCalced.elevation.up;
        addedEleDown += segmentCalced.elevation.down;
        segmentCalced.elevationHeight = parseInt(
          restSegment[restSegment.length - 1].ele
        );
      }

      segmentCalced.start = roundIdx;
      segmentCalced.end = points.length - 1;

      segmentCalced.distanceAdded = distanceAdded;
      segmentCalced.distanceAddedKm = formatDistance(distanceAdded).distanceKm;

      rounds.push(segmentCalced);
    }
  }

  // end of calc and add rest of points

  const summary = {
    km: roundsKm.toFixed(2),
    distance: distanceAdded,
    elevationUp: parseInt(addedEleUp),
    elevationDown: parseInt(addedEleDown),
    boundaries: {
      latMin: latMin,
      lngMin: lonMin,
      latMax: latMax,
      lngMax: lonMax,
    },
  };

  // flatten
  /*   summary.$ = {
    distance: parseInt(totalDist),
    elevationUp: parseInt(addedEleUp),
    elevationDown: parseInt(addedEleDown),
    boundaries: {
      latMin: latMin,
      lngMin: lonMin,
      latMax: latMax,
      lngMax: lonMax,
    },
  }; */

  if (isTime) {
    summary.duration = addedDuration;
  }

  // flatten
  /*   if (isTime) {
    summary.$.durationSeconds = parseInt(
      (new Date(points[points.length - 1].time).getTime() -
        new Date(points[0].time).getTime()) /
        1000
    );
  }
 */
  if (isTime) {
    const absStat = calcSegment(points);
    summary.paceStats = {
      minPace: minPace,
      pace: absStat.time.pace,
      maxPace: maxPace,
    };
    summary.speedStats = {
      minSpeed: minSpeed,
      speed: absStat.time.speed,
      maxSpeed: maxSpeed,
    };
    summary.minPace = minPace;
    summary.pace = summary.paceStats.pace;
    summary.maxPace = maxPace;
    summary.minSpeed = minSpeed;
    summary.maxSpeed = maxSpeed;
    summary.speed = summary.speedStats.speed;

    summary.minMinutes = parseFloat(minPace);
    summary.maxMinutes = parseFloat(maxPace);

    // flatten
    summary.$ = {
      ...summary.$,
      //   paceAvg: absStat.time.pace,
      paceMin: minPace,
      paceMax: maxPace,
      //   speedAvg: absStat.time.speed,
      speedMin: minSpeed,
      speedMax: maxSpeed,
    };
  }

  return {
    rounds: rounds,
    summary: summary,
  };
};

function parseTCX(tcxData) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(tcxData, "text/xml");

  const trackpoints = xmlDoc.querySelectorAll("Trackpoint");
  const trackpointData = [];

  const metadata = {
    activity: {},
    lap: {},
  };

  const activityNode = xmlDoc.querySelector("Activity");
  metadata.activity.sport = activityNode.getAttribute("Sport");

  const idNode = activityNode.querySelector("Id");
  metadata.activity.id = idNode ? idNode.textContent : "";

  const lapNode = xmlDoc.querySelector("Lap");
  metadata.lap.startTime = lapNode.getAttribute("StartTime");
  metadata.lap.totalTimeSeconds = parseFloat(
    lapNode.querySelector("TotalTimeSeconds")
      ? lapNode.querySelector("TotalTimeSeconds").textContent
      : 0
  );
  metadata.lap.distanceMeters = parseFloat(
    lapNode.querySelector("DistanceMeters")
      ? lapNode.querySelector("DistanceMeters").textContent
      : 0
  );
  metadata.lap.calories = parseInt(
    lapNode.querySelector("Calories")
      ? lapNode.querySelector("Calories").textContent
      : 0,
    10
  );
  metadata.lap.intensity = lapNode.querySelector("Intensity")
    ? lapNode.querySelector("Intensity").textContent
    : "";
  metadata.lap.triggerMethod = lapNode.querySelector("TriggerMethod")
    ? lapNode.querySelector("TriggerMethod").textContent
    : "";

  trackpoints.forEach((trackpoint) => {
    const timeNode = trackpoint.querySelector("Time");
    const time = timeNode ? timeNode.textContent : "";

    const positionNode = trackpoint.querySelector("Position");
    const latitude = positionNode
      ? parseFloat(
          positionNode.querySelector("LatitudeDegrees")
            ? positionNode.querySelector("LatitudeDegrees").textContent
            : 0
        )
      : 0;
    const longitude = positionNode
      ? parseFloat(
          positionNode.querySelector("LongitudeDegrees")
            ? positionNode.querySelector("LongitudeDegrees").textContent
            : 0
        )
      : 0;
    const altitudeMeters = parseFloat(
      trackpoint.querySelector("AltitudeMeters")
        ? trackpoint.querySelector("AltitudeMeters").textContent
        : 0
    );
    const distanceMeters = parseFloat(
      trackpoint.querySelector("DistanceMeters")
        ? trackpoint.querySelector("DistanceMeters").textContent
        : 0
    );
    const heartRateNode = trackpoint.querySelector("HeartRateBpm > Value");
    const heartRate = heartRateNode
      ? parseInt(heartRateNode.textContent || 0, 10)
      : 0;

    const entry = {
      time,
      lat: latitude,
      lng: longitude,
      ele: altitudeMeters,
      dist: distanceMeters,
      hr: heartRate,
    };

    trackpointData.push(entry);
  });

  return {
    metadata,
    trackpoints: trackpointData,
  };
}

const gpxDomParser = (fileData) => {
  const doc = new DOMParser().parseFromString(fileData, "text/xml");

  const globalMetaData = [...doc.getElementsByTagName("metadata")];
  const metaData = {};
  // global metadata

  if (globalMetaData.length > 0) {
    // time

    let check = "";

    check = gpxDomParserMeta(globalMetaData, "time");
    if (check !== "") {
      metaData.time = check;
    }

    check = gpxDomParserMeta(globalMetaData, "desc");
    if (check !== "") {
      metaData.desc = check;
    }

    check = gpxDomParserMeta(globalMetaData, "author");
    if (check !== "") {
      metaData.author = check;
    }

    if (globalMetaData[0].getElementsByTagName("link").length > 0) {
      metaData.link = globalMetaData[0]
        .getElementsByTagName("link")[0]
        .getAttribute("href");
    }

    // boundaries
    if (globalMetaData[0].getElementsByTagName("bounds").length > 0) {
      const bounds = globalMetaData[0].getElementsByTagName("bounds")[0];
      metaData.boundaries = {
        latMax: parseFloat(bounds.getAttribute("maxlat")),
        latMin: parseFloat(bounds.getAttribute("minlat")),
        lngMax: parseFloat(bounds.getAttribute("maxlon")),
        lngMin: parseFloat(bounds.getAttribute("minlon")),
      };
    }
  }

  const trks = [...doc.getElementsByTagName("trk")];

  const newTrcks = [];
  let newWaypts = [];

  for (let i = 0; i < trks.length; i++) {
    newTrcks.push([]);

    let trckIdx = newTrcks.length - 1;
    newTrcks[trckIdx].metadata = {};

    // name
    if (trks[i].getElementsByTagName("name").length > 0) {
      newTrcks[trckIdx].metadata.name =
        trks[i].getElementsByTagName("name")[0].childNodes[0].nodeValue;
    }

    // type
    if (trks[i].getElementsByTagName("type").length > 0) {
      newTrcks[trckIdx].metadata.type =
        trks[i].getElementsByTagName("type")[0].childNodes[0].nodeValue || "";
    }

    // waypoints
    const waypnts = [...doc.getElementsByTagName("wpt")];

    newWaypts = [];

    for (let i2 = 0; i2 < waypnts.length; i2++) {
      let pnt = {
        lat: parseFloat(waypnts[i2].getAttribute("lat")),
        lng: parseFloat(waypnts[i2].getAttribute("lon")),
      };
      //  console.dir(node);

      // time
      if (waypnts[i2].getElementsByTagName("time").length > 0) {
        pnt.time = parseFloat(
          waypnts[i2].getElementsByTagName("time")[0].childNodes[0].nodeValue
        );
      }

      // name
      if (waypnts[i2].getElementsByTagName("name").length > 0) {
        pnt.name =
          waypnts[i2].getElementsByTagName("name")[0].childNodes[0].nodeValue;
      }
      // desc
      if (
        waypnts[i2].getElementsByTagName("desc").length > 0 &&
        waypnts[i2].getElementsByTagName("desc")[0].childNodes.length > 0
      ) {
        pnt.desc =
          waypnts[i2].getElementsByTagName("desc")[0].childNodes[0].nodeValue;
      }

      // sym
      if (waypnts[i2].getElementsByTagName("sym").length > 0) {
        pnt.sym = parseFloat(
          waypnts[i2].getElementsByTagName("sym")[0].childNodes[0].nodeValue
        );
      }

      // ele
      if (waypnts[i2].getElementsByTagName("ele").length > 0) {
        pnt.ele = parseFloat(
          waypnts[i2].getElementsByTagName("ele")[0].childNodes[0].nodeValue
        );
      }

      newWaypts.push(pnt);
    }
    // trackpoints
    const trkpnts = [...trks[i].getElementsByTagName("trkpt")];

    newTrcks[trckIdx].points = [];

    for (let i2 = 0; i2 < trkpnts.length; i2++) {
      let pnt = {
        lat: parseFloat(trkpnts[i2].getAttribute("lat")),
        lng: parseFloat(trkpnts[i2].getAttribute("lon")),
      };
      //  console.dir(node);

      // ele
      if (trkpnts[i2].getElementsByTagName("ele").length > 0) {
        pnt.ele = parseFloat(
          trkpnts[i2].getElementsByTagName("ele")[0].childNodes[0].nodeValue
        );
      }

      // time
      if (trkpnts[i2].getElementsByTagName("time").length > 0) {
        pnt.time =
          trkpnts[i2].getElementsByTagName("time")[0].childNodes[0].nodeValue;
      }

      // hr
      if (trkpnts[i2].getElementsByTagName("ns3:hr").length > 0) {
        pnt.hr = parseInt(
          trkpnts[i2].getElementsByTagName("ns3:hr")[0].childNodes[0].nodeValue
        );
      }

      // atemp
      if (trkpnts[i2].getElementsByTagName("ns3:atemp").length > 0) {
        pnt.atemp =
          trkpnts[i2].getElementsByTagName(
            "ns3:atemp"
          )[0].childNodes[0].nodeValue;
      }

      newTrcks[trckIdx].points.push(pnt);
    }
  }
  return { metadata: metaData, tracks: newTrcks, waypoints: newWaypts };
};

const gpxDomParserMeta = (globalMetaData = null, elementName = "") => {
  console.log("globalMetaData", globalMetaData);
  if (globalMetaData[0].getElementsByTagName(elementName).length > 0) {
    if (
      globalMetaData[0].getElementsByTagName(elementName)[0].childNodes.length >
      0
    ) {
      return globalMetaData[0].getElementsByTagName(elementName)[0]
        .childNodes[0].nodeValue;
    }
  }
};
