import React, { useEffect, useState } from "react";

import Switch from "../../Trackalyze/Switch";

const ThemeSwitch = () => {
  const [theme, setTheme] = useState(
    localStorage.getItem("theme") !== null
      ? JSON.parse(localStorage.getItem("theme"))
      : "dark"
  );

  useEffect(() => {
    console.log("theme switch", theme);
    switchTheme(theme);
  }, [theme]);

  const switchTheme = (theme) => {
    if (theme === "dark") {
      document.getElementById("app").classList.add("ta-theme_dark");
      document.getElementById("app").classList.remove("ta-theme_light");
      localStorage.setItem("theme", JSON.stringify("dark"));
    } else {
      document.getElementById("app").classList.add("ta-theme_light");
      document.getElementById("app").classList.remove("ta-theme_dark");
      localStorage.setItem("theme", JSON.stringify("light"));
    }
  };

  return (
    <Switch
      switched={theme === "dark"}
      callBack={(value) => setTheme(value ? "dark" : "light")}
    />
  );
};

export default React.memo(ThemeSwitch);
