import React, { useEffect, useState, useContext } from "react";
import RoundsContext from "../../../../Context/RoundsContext";
import Button from "../../../Trackalyze/Button";

import { useTranslation } from "react-i18next";
import TrackContext from "../../../../Context/TrackContext";

const RoundsSelectionButton = (props) => {
  const { t } = useTranslation();
  const { track } = useContext(TrackContext);

  const { mapRounds, setMapRounds } = useContext(RoundsContext);

  const [roundsItems, setRoundsItems] = useState([]);
  const [roundsIndex, setRoundsIndex] = useState(
    mapRounds.auto ? -1 : mapRounds.selection
  );
  const [roundsIcon, setRoundsIcon] = useState(
    mapRounds.auto ? "auto" : mapRounds.selection
  );

  useEffect(() => {
    const items = [];

    for (let i = 0; i < track.$.roundsIndex.length; i++) {
      track.$.roundsIndex[i] !== "" &&
        items.push({
          label: track.$.roundsIndex[i].toString(),
          active:
            i === (mapRounds.auto ? mapRounds.index : mapRounds.selection),
        });
      setRoundsItems(items);
    }
  }, [mapRounds]);

  const updateRoundsIndex = () => {
    if (roundsIndex < roundsItems.length - 1) {
      setRoundsIndex(roundsIndex + 1);
    } else {
      setRoundsIndex(-1);
    }
  };

  useEffect(() => {
    if (roundsIndex < 0) {
      setMapRounds({
        ...mapRounds,
        auto: true,
        visible: true,
      });
      setRoundsIcon("auto");
    } else {
      setMapRounds({
        ...mapRounds,
        auto: false,
        selection: roundsIndex,
        visible: true,
      });
      if (roundsItems[roundsIndex].label === "0.5") {
        setRoundsIcon("05");
      } else {
        setRoundsIcon(roundsItems[roundsIndex].label);
      }
    }
  }, [roundsIndex]);

  return roundsItems.length > 0 && roundsItems !== undefined ? (
    <Button
      semi
      elevation
      icon
      symbol={"rounds_" + roundsIcon}
      onClick={() => updateRoundsIndex()}
    />
  ) : null;
};

export default React.memo(RoundsSelectionButton);
