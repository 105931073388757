import React, { useState } from "react";

const GuiContext = React.createContext();
export default GuiContext;

export const GuiProvider = (props) => {
  const [infoItems, setInfoItems] = useState([]);

  const [importURL, setImportURL] = useState("");
  const [showMyLocation, setShowMyLocation] = useState(false);

  return (
    <GuiContext.Provider
      value={{
        importURL,
        setImportURL,

        infoItems,
        setInfoItems,

        showMyLocation,
        setShowMyLocation,
      }}
    >
      {props.children}
    </GuiContext.Provider>
  );
};
