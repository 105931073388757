import React, { useContext, useEffect, useState } from "react";

import TrackContext from "../../../../Context/TrackContext";
import { updateFavorite } from "../../../utils/localStorage";
import Button from "../../../Trackalyze/Button";

const FavoriteButton = (props) => {
  const { track } = useContext(TrackContext);

  const [isFavorite, setFavorite] = useState(false);
  const { setUpdateTracks } = useContext(TrackContext);

  useEffect(() => {
    if (track !== undefined) {
      setFavorite(
        track !== undefined && "favorite" in track.$ ? track.$.favorite : false
      );
    }
  }, [track]);

  return (
    track !== undefined && (
      <Button
        onClick={() => {
          updateFavorite(track.$.id, !isFavorite);
          setFavorite(!isFavorite);
          setUpdateTracks(true);
        }}
        tonal={isFavorite}
        symbol={isFavorite ? "star" : "star_border"}
        icon
        {...props}
      />
    )
  );
};

export default React.memo(FavoriteButton);
