import React, { useEffect, useState } from "react";
import {
  labelsGetCollection,
  labelsRefsGetCollection,
} from "../../../utils/localStorage";

import { ACTIVITY_TYPES } from "../../../config/mapTypes";

import Toolbar from "../../../Trackalyze/Toolbar";
import Chip from "../../../Trackalyze/Chip";
import Typo from "../../../Trackalyze/Typo";
import Switch from "../../../Trackalyze/Switch";
import Divider from "../../../Trackalyze/Divider";
import Badge from "../../../Trackalyze/Badge";
import { useTranslation } from "react-i18next";

const FilterWrapper = (props) => {
  const { t } = useTranslation();

  console.log("RERENDER");

  const [isFavoriteFilter, setIsFavoriteFilter] = useState(false);
  const [favoriteFilter, setFavoriteFilter] = useState(false);
  const [labelsFilter, setLabelsFilter] = useState([]);

  const [activityFilter, setActivityFilter] = useState([]);

  useEffect(() => {
    console.log("FilterWrapper props", props);
    initFavoriteFilter(props.trackItems);
    initLabelsFilter(props.trackItems);
    initActivityFilter(props.trackItems);
  }, []);

  useEffect(() => {
    if (props.isFiltered) {
      if (props.filterItems) {
        console.log("props.filterItems", props.filterItems);
        if ("favorites" in props.filterItems && props.filterItems.favorites) {
          window.setTimeout(() => {
            setFavoriteFilter(props.filterItems.favorites);
          }, 100);
        }

        if (
          "labels" in props.filterItems &&
          props.filterItems.labels.length > 0
        ) {
          window.setTimeout(() => {
            setLabelsFilter(props.filterItems.labels);
          }, 200);
        }

        if (
          "activities" in props.filterItems &&
          props.filterItems.activities.length > 0
        ) {
          window.setTimeout(() => {
            setActivityFilter(props.filterItems.activities);
          }, 300);
        }
      }
    }
  }, [props.isFiltered]);

  useEffect(() => {
    if (
      labelsFilter.length > 0 ||
      activityFilter.length > 0 ||
      favoriteFilter
    ) {
      props.onFilter({
        labels: labelsFilter,
        activities: activityFilter,
        favorites: favoriteFilter,
      });
    }
  }, [labelsFilter, activityFilter, favoriteFilter]);

  const initLabelsFilter = async (tracksItems = []) => {
    console.log("FilterWrapper 3 initLabelsFilter()", initLabelsFilter);

    const tmpList = await labelsGetCollection();
    const tmpRefsList = await labelsRefsGetCollection();

    // build filter items
    const trckList = [];
    for (let i = 0; i < tmpRefsList.length; i++) {
      if (
        tracksItems.filter((ref) => ref.id === tmpRefsList[i].trackId).length >
        0
      ) {
        trckList.push(tmpRefsList[i]);
      }
    }

    const selList = [];
    for (let i = 0; i < tmpList.length; i++) {
      if (trckList.filter((ref) => ref.id === tmpList[i].id).length > 0) {
        tmpList[i].selected = false;

        selList.push(tmpList[i]);
      }
    }

    console.log("FilterWrapper 4 initLabelsFilter() selList", selList);
    setLabelsFilter(selList);
  };

  const toggleLabelItems = (id) => {
    const selList = [];

    for (let i = 0; i < labelsFilter.length; i++) {
      selList.push(labelsFilter[i]);

      if (selList[i].id === id) {
        selList[selList.length - 1].selected =
          !selList[selList.length - 1].selected;
      }
    }

    setLabelsFilter(selList);
  };

  // activity filter
  const initActivityFilter = (tracksItems = []) => {
    const selList = [];
    for (let i = 0; i < ACTIVITY_TYPES.length; i++) {
      if (
        tracksItems.filter((ref) => ref.activityType === ACTIVITY_TYPES[i].id)
          .length > 0
      ) {
        selList.push({ ...ACTIVITY_TYPES[i], selected: false });
      }
    }

    setActivityFilter(selList);
  };

  const toggleActivityItems = (id) => {
    const selList = [];

    for (let i = 0; i < activityFilter.length; i++) {
      selList.push(activityFilter[i]);

      if (selList[i].id === id) {
        selList[selList.length - 1].selected =
          !selList[selList.length - 1].selected;
      }
    }
    console.log("filter toggleActivityItems", selList);
    setActivityFilter(selList);
  };

  const filterReset = () => {
    props.onFilter({
      labels: [],
      activities: [],
      favorites: false,
    });
  };

  // favorite filter
  const initFavoriteFilter = (tracksItems = []) => {
    setIsFavoriteFilter(tracksItems.filter((trck) => trck.favorite).length > 0);
    setFavoriteFilter(false);
  };

  return (
    <>
      <Toolbar
        title={t("trackmanager.filter_title")}
        action={{ symbol: "close", onClick: () => props.onClose() }}
      >
        <Chip
          secondary
          disabled={!props.isFiltered}
          onClick={() => filterReset()}
        >
          {t("trackmanager.filter_button_reset")}
          {props.isFiltered && (
            <Badge className="ta-margin_left-xxs">
              {props.trackItems.length}
            </Badge>
          )}
        </Chip>
        {/*     <Chip
          primary
          disabled={!props.isFiltered}
          onClick={() => props.onClose()}
        >
          {t("trackmanager.filter_button_apply")&nbsp;{props.trackItems.length}
        </Chip> */}
      </Toolbar>

      <div className="ta-padding_s">
        <div
          onClick={() => {
            setFavoriteFilter(!favoriteFilter);
          }}
          className={
            "ta-margin_bottom-s ta-flex ta-flex--itemscenter" +
            (!isFavoriteFilter ? " ta-is-disabled" : "")
          }
        >
          <Typo className=" ta-color-text_on-surface-low ta-flexitem">
            {t("trackmanager.filter_options_favorites")}
          </Typo>
          <Switch switched={favoriteFilter} />
        </div>
        <Divider />
        <div
          className={
            "ta-margin_top-xxs ta-margin_bottom-s " +
            (activityFilter.length > 0 ? "" : "ta-is-disabled")
          }
        >
          <Typo className="ta-color-text_on-surface-low">
            {t("trackmanager.filter_options_activities")}
          </Typo>
          <div className="ta-flex ta-flex--wrap">
            {activityFilter.map((activity, idx) => {
              return (
                <Chip
                  symbol={activity.selected ? "check_circle" : activity.symbol}
                  key={idx}
                  onClick={() => toggleActivityItems(activity.id)}
                  outline={!activity.selected}
                  secondary={activity.selected}
                >
                  {activity.label}
                </Chip>
              );
            })}
          </div>
        </div>
        <Divider />

        <div
          className={
            "ta-margin_top-xxs ta-margin_bottom-s " +
            (labelsFilter.length > 0 ? "" : "ta-is-disabled")
          }
        >
          <Typo className="ta-color-text_on-surface-low">
            {t("trackmanager.filter_options_labels")}
          </Typo>
          <div className="ta-flex ta-flex--wrap">
            {labelsFilter.map((label, idx) => {
              return (
                <Chip
                  symbol={label.selected ? "check_circle" : "label"}
                  onClick={() => toggleLabelItems(label.id)}
                  key={idx}
                  outline={!label.selected}
                  secondary={label.selected}
                >
                  {label.label}
                </Chip>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterWrapper;
