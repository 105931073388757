import Localbase from "localbase";
import { buildTrackObject } from "./buildTrack";

// *** localStorage
// const DB_TracksIndex ="trackRefs";
const DB_TracksIndex = "tracksIndex";
const DB_TracksBase = "tracksBase";

export const handleGPXResult = async (track, fileInfo = {}) => {
  console.log("tcx track x", track);
  console.log("tcx fileInfo x", fileInfo);

  let db = new Localbase("GPXer");

  const buildResult = buildTrackObject(track);

  const tracks = buildResult.tracks;
  let trackObject = {};
  let trackId = -1;
  for (let i = 0; i < tracks.length; i++) {
    console.log("importfiles handleGPXResult prep track in loop");

    trackId = new Date().getTime();

    trackObject = { favorite: false, id: trackId };

    // flatten
    trackObject.$ = tracks[i].$;
    trackObject.$.id = trackId;
    trackObject.$.favorite = false;
    trackObject.$.fileName = fileInfo.fileName;
    trackObject.$.fileSize = fileInfo.fileSize;
    trackObject.$.fileUploadedTime = new Date().getTime();
    trackObject.$.fileCreated = fileInfo.fileUploadedTime;

    if (tracks[i].$.activityName === "") {
      trackObject.$.activityName = fileInfo.fileName.split(".")[0];
    }

    // store track in localstorage

    if (Object.keys(trackObject).length) {
      const success = await addToStorageTracks(
        {
          id: trackId,
          meta: buildResult.gpx.metadata,
          track: buildResult.gpx.tracks[i].points,
        },
        trackObject.$
      );
    }
    console.log("importfiles added to storage");
  }
  return trackId;
};

const addToStorageTracks = async (trackObject, tracksObject) => {
  let db = new Localbase("GPXer");

  await db.collection("tracksBase").add(trackObject);
  await db.collection("tracksIndex").add(tracksObject);
  return true;
};

const getStorageTotalSize = (upperLimit /*in bytes*/) => {
  var store = localStorage;
  testkey = "$_test"; // (NOTE: Test key is part of the storage!!! It should also be an even number of characters)
  var test = function (_size) {
    try {
      store.removeItem(testkey);
      store.setItem(testkey, new Array(_size + 1).join("0"));
    } catch (_ex) {
      return false;
    }
    return true;
  };
  var backup = {};
  for (var i = 0, n = store.length; i < n; ++i)
    backup[store.key(i)] = store.getItem(store.key(i));
  store.clear(); // (you could iterate over the items and backup first then restore later)
  var low = 0,
    high = 1,
    _upperLimit = (upperLimit || 1024 * 1024 * 1024) / 2,
    upperTest = true;
  while ((upperTest = test(high)) && high < _upperLimit) {
    low = high;
    high *= 2;
  }
  if (!upperTest) {
    var half = ~~((high - low + 1) / 2); // (~~ is a faster Math.floor())
    high -= half;
    while (half > 0) high += (half = ~~(half / 2)) * (test(high) ? 1 : -1);
    high = testkey.length + high;
  }
  if (high > _upperLimit) high = _upperLimit;
  store.removeItem(testkey);
  for (var p in backup) store.setItem(p, backup[p]);
  return high * 2; // (*2 because of Unicode storage)
};

export const pushToLocalStorage = (trackObject, sourceUrl) => {
  let id = "test";
  return id;
};

export const removeFromLocalStorage = async (items) => {
  for (let i = 0; i < items.length; i++) {
    await deleteFromStorage(items[i]);
  }
};

const deleteFromStorage = async (id) => {
  let db = new Localbase("GPXer");

  await db
    .collection(DB_TracksBase)
    .doc({ id: id })
    .delete()
    .then(() => {
      console.log("TRACK deleted");
    });
  await db
    .collection(DB_TracksIndex)
    .doc({ id: id })
    .delete()
    .then(() => {
      console.log("TRACKref deleted");
    });
};

export const getProfileConfig = () => {
  return localStorage.getItem("configProfile");
};

export const updateFavorite = async (id, favorite) => {
  let db = new Localbase("GPXer");

  await db.collection(DB_TracksIndex).doc({ id: id }).update({
    favorite: favorite,
  });
};

export const updateFavorites = async (items = []) => {
  console.log("updateFavoriteupdateFavoriteupdateFavorite", items);

  for (let i = 0; i < items.length; i++) {
    await updateFavorite(items[i].id, items[i].favorite);
  }
};

// *** labels
export const labelsSetCollection = async (labelsList) => {
  let db = new Localbase("GPXer");

  await db.collection("labels").set(labelsList);

  console.log("labelsSetCollection", "DONE");
};

export const labelsGetCollection = async () => {
  let db = new Localbase("GPXer");

  return db
    .collection("labels")
    .get()
    .then((tmpLabels) => {
      console.log("localstoragfe collection labels", tmpLabels);
      return tmpLabels;
    });
};

export const labelsRefsGet = async (id) => {
  let db = new Localbase("GPXer");

  return await db
    .collection("labelRefs")
    .get()
    .then((labelRefs) => {
      console.log("labelRefs id", id);
      console.log("labelRefs labelRefs", labelRefs);
      const tmpItems = [];
      for (let i = 0; i < labelRefs.length; i++) {
        if (labelRefs[i].trackId === id) {
          tmpItems.push(labelRefs[i].id);
        }
      }
      console.log("labelRefs labelRefs tmpItems", tmpItems);

      return tmpItems;
    })
    .catch((error) => {
      console.log("There was an error, do something else.");
    });
};

export const labelsRefsUpdate = async (id, refs, labels) => {
  let db = new Localbase("GPXer");

  await db
    .collection("labelRefs")
    .doc({ trackId: id })
    .delete()
    .then(() => {
      console.log("labelRefs deleted", id);
    })
    .catch((error) => {
      console.log("There was an error, do something else.");
    });

  for (let i = 0; i < refs.length; i++) {
    if (labels.filter((item) => item.id === refs[i]).length > 0) {
      await db.collection("labelRefs").add({ trackId: id, id: refs[i] });
    }
  }
};

export const labelsRefsGetCollection = async () => {
  let db = new Localbase("GPXer");

  return db
    .collection("labelRefs")
    .get()
    .then((tmpLabelRefs) => {
      console.log("collection tmpLabelRefs", tmpLabelRefs);
      return tmpLabelRefs;
    });
};
