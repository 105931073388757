// *** unused

import React, { useState, useEffect } from "react";

const RoundsContext = React.createContext();
export default RoundsContext;

export const RoundsProvider = (props) => {
  const [mapRounds, setMapRounds] = useState({
    auto: true,
    visible: true,
    rounds: [],
    selection: 0,
    index: 0,
    label: "0.5",
  });

  useEffect(() => {
    //
  }, [mapRounds]);
  return (
    <RoundsContext.Provider
      value={{
        mapRounds,
        setMapRounds,
      }}
    >
      {props.children}
    </RoundsContext.Provider>
  );
};
