import React, { useEffect, useState } from "react";

import Scrim from "./Scrim";

const Dialog = (props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (props.animate !== animate && props.animate === false) {
      close();
    } else if (props.animate === true) {
      window.setTimeout(() => {
        setAnimate(true);
      }, 100);
    }
  }, [props.animate]);

  const close = () => {
    if (animate) {
      setAnimate(false);
      if (props.onAfterAnimate) {
        window.setTimeout(() => {
          props.onAfterAnimate();
        }, 500);
      }
    }
  };

  const buildClasses = () => {
    const classes = ["ta-dialog"];
    props.elevation
      ? classes.push("ta-elevation--shadow ta-elevation_" + props.elevation)
      : classes.push("ta-elevation_3 ta-elevation--shadow");
    props.fullScreen && classes.push("ta-dialog--fullscreen");
    if (typeof props.fullScreen === "string") {
      classes.push("ta-dialog--" + props.fullScreen);
    }
    !props.noPadding && !props.fullScreen && classes.push("ta-padding_m");

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <>
      {!props.noScrim && (
        <Scrim
          zIndex={props.zIndex || 2399}
          onClick={() => close()}
          animate={animate}
          transparent={props.transparent}
        />
      )}
      <div
        className={`${buildClasses()} ${animate ? " ta-dialog--animate" : ""}`}
        style={props.zIndex ? { zIndex: props.zIndex + 1 } : {}}
      >
        {props.children}
      </div>
    </>
  );
};

export default Dialog;
