import React, { useState } from "react";

import TrackUpload from "../../Import/TrackUpload";
import guiManager from "../../../utils/guiManager";
import Snackbar from "../../../Trackalyze/Snackbar";
import { useTranslation } from "react-i18next";

const TracksImport = (props) => {
  const { t } = useTranslation();

  // delet items
  const [importFeedback, setImportFeedback] = useState(false);
  return (
    <>
      <div className="ta-is-overflow ta-is-relative ta-flex ta-flex--center">
        {props.children && props.children}
        <TrackUpload
          clicked={props.clicked}
          mini
          onDone={(value) => {
            setImportFeedback(value);
            console.log("impor done", value);
            props.callBack && props.callBack(value);
          }}
        />
      </div>

      {importFeedback > 0 &&
        guiManager(
          <Snackbar
            zIndex={9999}
            animate
            fixed
            timeOut
            onAfterAnimate={() => setImportFeedback(0)}
          >
            {t("import.done", { count: importFeedback })}
          </Snackbar>
        )}
    </>
  );
};

export default TracksImport;
