import React, { useContext, useEffect, useState } from "react";

import FilterButton from "../../MapWrapper/GuiMap/FilterButton";

import SortButton from "../../MapWrapper/GuiMap/SortButton";

import TrackContext from "../../../../Context/TrackContext";
import DeviceContext from "../../../../Context/DeviceContext";
import TracksSelectionToolbar from "./TracksSelectionToolbar";
import TracksList from "./TracksList";
import TracksToolbar from "./TracksToolbar";
import { Outlet } from "react-router-dom";
import TextField from "../../../Trackalyze/TextField";
import Typo from "../../../Trackalyze/Typo";
import Chip from "../../../Trackalyze/Chip";

import TracksDelete from "./TracksDelete";
import TracksFavorite from "./TracksFavorite";
import { useTranslation } from "react-i18next";
import PositionAbsolute from "../../../Trackalyze/patterns/PositionAbsolute";

import TracksImportButton from "../../MapWrapper/GuiMap/TracksImportButton";
import GuiSplitContext from "../../../../Context/GuiSplitContext";

const Tracks = (props) => {
  const { t } = useTranslation();
  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  const { isDeviceTablet } = useContext(DeviceContext);

  // delete items

  const [edit, setEdit] = useState(false);

  const [showFilters, setShowFilters] = useState(true);
  const [showSearch, setShowSearch] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [tracksSort, setTracksSort] = useState({});
  const [itemsCount, setItemsCount] = useState(0);
  const [trackItems, setTrackItems] = useState([]);
  const [deleteItems, setDeleteItems] = useState([]);
  const [favoriteItems, setFavoriteItems] = useState([]);

  // end delete items

  const {
    tracks,
    tracksOrder,
    setTracksOrder,
    tracksFilter,
    setTracksFilter,
    isFiltered,
  } = useContext(TrackContext);

  // build initial tracks list
  useEffect(() => {
    if (tracks !== undefined) {
      initTracks();
    } else {
      setEdit(false);
      setTrackItems([]);
    }
  }, [tracks]);

  useEffect(() => {
    setTracksSort(tracksOrder);
  }, [tracksOrder]);

  const initTracks = () => {
    const items = [];
    for (let i = 0; i < tracks.length; i++) {
      items.push({ ...tracks[i], checked: false });
    }
    setTrackItems(items);
  };

  const itemToggle = (id) => {
    if (tracks !== undefined) {
      const items = [];
      for (let i = 0; i < trackItems.length; i++) {
        if (trackItems[i].id === id) {
          items.push({ ...trackItems[i], checked: !trackItems[i].checked });
        } else {
          items.push({ ...trackItems[i] });
        }
      }
      setTrackItems(items);
    }
  };

  const removeSelection = () => {
    if (tracks !== undefined) {
      const items = [];
      for (let i = 0; i < trackItems.length; i++) {
        items.push({ ...trackItems[i], checked: false });
      }
      setTrackItems(items);
    }
  };

  const allSelection = () => {
    if (tracks !== undefined) {
      const items = [];
      for (let i = 0; i < trackItems.length; i++) {
        items.push({ ...trackItems[i], checked: true });
      }
      setTrackItems(items);
    }
  };

  const invertSelection = () => {
    if (tracks !== undefined) {
      const items = [];
      for (let i = 0; i < trackItems.length; i++) {
        items.push({ ...trackItems[i], checked: !trackItems[i].checked });
      }
      setTrackItems(items);
    }
  };

  useEffect(() => {
    if (trackItems.filter((item) => item.checked).length > 0) {
      setEdit(true);
      setItemsCount(trackItems.filter((item) => item.checked).length);
    } else {
      setEdit(false);
      setItemsCount(0);
    }
    //    setFilteredItems([]);
    // resetFilters();
  }, [trackItems]);

  const removeFromStorage = async () => {
    const items = [];
    for (let i = 0; i < trackItems.length; i++) {
      if (trackItems[i].checked) {
        items.push(trackItems[i].id);
      }
    }
    if (items.length) {
      setDeleteItems(items);
    }
  };

  const setFavoritesToStorage = async (value) => {
    const items = [];

    for (let i = 0; i < trackItems.length; i++) {
      if (trackItems[i].checked) {
        if (value === "favor") {
          trackItems[i].favorite = true;
        } else if (value === "unfavor") {
          trackItems[i].favorite = false;
        } else if (value === "flip") {
          trackItems[i].favorite = !trackItems[i].favorite;
        }

        items.push(trackItems[i]);
      }
    }
    if (items.length) {
      setFavoriteItems(items);
    }
  };

  const resetFilters = () => {
    setTracksFilter({
      labels: [],
      activities: [],
      favorites: false,
    });
  };

  useEffect(() => {
    searchTrackItems(searchKey);
  }, [searchKey]);

  const searchTrackItems = (value) => {
    if (value === "") {
      setTrackItems(tracks);
    } else {
      let tmpTracks = [];

      tmpTracks = tracks.filter((trck) =>
        trck.activityName.toLowerCase().includes(value.toLowerCase())
      );

      let filtered = false;
      if (tmpTracks.length !== tracks.length) {
        setTrackItems(tmpTracks);
        if (tmpTracks.length > 0) {
          filtered = true;
        }
      }
    }
    /*     setFilterItems({
      ...filterItems,
      search: { filtered: filtered, items: tmpTracks, keys: searchKey }
    }); */
  };

  return (
    <>
      <div className="ta-is-relative ta-height_full ta-flexitem ta-flex ta-flex--column">
        <div>
          {edit === true && (
            <TracksSelectionToolbar
              itemsCount={itemsCount}
              onClose={() => {
                removeSelection();
              }}
              removeSelection={() => removeSelection()}
              invertSelection={() => invertSelection()}
              selectAll={() => allSelection()}
              removeFromStorage={() => removeFromStorage()}
              setFavoritesToStorage={(value) => setFavoritesToStorage(value)}
            />
          )}

          {edit === false && (
            <TracksToolbar
              searchKey={searchKey}
              setSearchKey={(value) => setSearchKey(value)}
              showSearch={false}
              showSearchToggle={() => setShowSearch(!showSearch)}
              showFilters={showFilters}
              showFiltersToggle={() => setShowFilters(!showFilters)}
              tracksCount={tracks.length}
              trackItems={trackItems}
              onClose={() => {
                setGuiSplit({ ...guiSplit, tracks: false, expanded: false });
              }}
              onExpand={() => {
                setGuiSplit({ ...guiSplit, expanded: !guiSplit.expanded });
              }}
            />
          )}
          {showFilters && tracks.length > 1 && (
            <>
              {/*            <div className="ta-flex ta-flex--itemscenter ta-margin_leftright-m ta-margin_bottom-xxs">
                <TextField
                  focus
                  search
                  symbol="search"
                  reset
                  value={searchKey}
                  onChange={(value) => {
                    setSearchKey(value);
                  }}
                  className={edit === true ? " ta-is-disabled" : ""}
                />
              </div> */}

              <div
                className={
                  "ta-margin_left-s ta-flex ta-flex--out ta-flex--itemscenter" +
                  (edit === true ? " ta-is-disabled" : "")
                }
              >
                {/*                 <FilterButton
                  onFilterItems={(filter) => setTracksFilter(filter)}
                  filterItems={tracksFilter}
                  trackItems={tracks}
                  isFiltered={isFiltered}
                /> */}
                <Typo>
                  <strong>{trackItems.length}</strong>{" "}
                  <span className="ta-color-text_on-surface-low"> Tracks</span>
                </Typo>

                <SortButton
                  disabled={trackItems.length < 2}
                  iconRight
                  text
                  trackItems={trackItems}
                />
              </div>
            </>
          )}
        </div>

        {isFiltered && (
          <div className="ta-margin_leftright-xs ta-flex ta-flex--itemscenter ta-flex--out">
            <Typo>Results {trackItems.length}</Typo>
            <Chip
              small
              error
              remove
              input="cancel"
              onClick={() => {
                resetFilters();
              }}
              className=""
            >
              Reset
            </Chip>
          </div>
        )}

        <div className="ta-height_full ta-padding_bottom-xxxs4 ta-is-overflow scroll-snap scroll-snap--y ta-flexitem">
          <TracksList
            trackItems={trackItems}
            itemToggle={(id) => itemToggle(id)}
            edit={edit}
            callBack={(value) => {
              props.onTrackClick && props.onTrackClick(value);
            }}
          />
          {tracks.length === 0 && (
            <Typo className="ta-is-center ta-margin_xxs ta-color-text_on-surface-low">
              {t("trackmanager.notracks")}
            </Typo>
          )}
        </div>

        <PositionAbsolute bottom right>
          <div>
            <TracksImportButton fab />
          </div>
        </PositionAbsolute>
      </div>
      {deleteItems.length ? (
        <TracksDelete
          items={deleteItems}
          onDone={() => {
            // setUpdateTracks(true);
            setDeleteItems([]);
          }}
        />
      ) : null}

      {favoriteItems.length ? (
        <TracksFavorite
          items={favoriteItems}
          onDone={() => {
            setFavoriteItems([]);
          }}
        />
      ) : null}
    </>
  );
};

export default React.memo(Tracks);
