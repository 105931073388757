import React, { useContext, useEffect } from "react";

import DeviceContext from "../Context/DeviceContext";

const Gui = (props) => {
  const { isDevicePortrait, isDeviceTablet, isDeviceMobile } =
    useContext(DeviceContext);

  useEffect(() => {
    const theme =
      localStorage.getItem("theme") !== null
        ? JSON.parse(localStorage.getItem("theme"))
        : "dark";
    console.log("gui theme:", theme);
    console.log("gui theme check:", theme === "dark");

    if (theme === "dark") {
      console.log("gui theme set dark:", theme);

      document.getElementById("app").classList.add("ta-theme_dark");
      document.getElementById("app").classList.remove("ta-theme_light");
    } else {
      console.log("gui theme set light:", theme);

      document.getElementById("app").classList.add("ta-theme_light");
      document.getElementById("app").classList.remove("ta-theme_dark");
    }
  }, []);

  useEffect(() => {
    if (isDevicePortrait === true) {
      document.getElementById("root").classList.add("is-portrait");
      document.getElementById("root").classList.remove("is-landscape");
    } else {
      document.getElementById("root").classList.remove("is-portrait");
      document.getElementById("root").classList.add("is-landscape");
    }

    if (isDeviceTablet === true) {
      document.getElementById("root").classList.add("is-tablet");
    } else {
      document.getElementById("root").classList.remove("is-tablet");
    }

    if (isDeviceMobile === true) {
      document.getElementById("root").classList.add("is-mobile");
    } else {
      document.getElementById("root").classList.remove("is-mobile");
    }
  }, [isDevicePortrait]);

  return props.children;
};

export default React.memo(Gui);
