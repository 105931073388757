import React, { useContext } from "react";

import RoundsSelection from "./RoundsSelection";

import TrackMarkerVisibility from "./TrackMarkerVisibility";
import TrackContext from "../../../../Context/TrackContext";
import AllVisiblityContext from "../../../../Context/AllVisiblityContext";

import Divider from "../../../Trackalyze/Divider";
import VisibilitySelector from "./VisibilitySelector";
import Typo from "../../../Trackalyze/Typo";
import Button from "../../../Trackalyze/Button";
import { useTranslation } from "react-i18next";

const VisibilityOverlays = () => {
  const { t } = useTranslation();

  const { isAllVisible, setAllVisibility } = useContext(AllVisiblityContext);

  const { track, tracks } = useContext(TrackContext);

  return tracks.length ? (
    <>
      <Divider className="ta-margin_topbottom-s" />
      {track !== undefined && (
        <>
          <div className={"ta-margin_left-xxxs "}>
            <TrackMarkerVisibility />
            <Divider />
            <RoundsSelection roundsIndex={track.$.roundsIndex} />
          </div>
        </>
      )}
    </>
  ) : null;
};

export default React.memo(VisibilityOverlays);
