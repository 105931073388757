import React from "react";

import { useNavigate } from "react-router-dom";
import TrackRounds from "../Components/Rounds/TrackRounds";
import { RoundsProvider } from "../../../Context/RoundsContext";
import { MapLabelProvider } from "../../../Context/MapLabelContext";

const TrackRoundsPage = () => {
  const navigate = useNavigate();

  const goBack = () => {
    //  navigate("/");
    navigate(-1);
  };

  return (
    <RoundsProvider>
      <MapLabelProvider>
        <div className="trackrounds_dialog trackrounds_dialog--animate">
          <TrackRounds />
        </div>
      </MapLabelProvider>
    </RoundsProvider>
  );
};

export default React.memo(TrackRoundsPage);
