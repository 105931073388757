import React, { useContext, useEffect, useState } from "react";

import { generateUid } from "../../../utils/helper";
import guiManager from "../../../utils/guiManager";
import Toolbar from "../../../Trackalyze/Toolbar";
import Button from "../../../Trackalyze/Button";
import Divider from "../../../Trackalyze/Divider";
import TextField from "../../../Trackalyze/TextField";
import Typo from "../../../Trackalyze/Typo";
import Dialog from "../../../Trackalyze/Dialog";
import DialogToolbar from "../../../Trackalyze/DialogToolbar";
import { useTranslation } from "react-i18next";

const LabelsEditor = (props) => {
  const { t } = useTranslation();

  const [showConfirmModal, setShowConfirmModal] = useState("");
  const [labelEdit, setLabelEdit] = useState(0);

  const [addLabel, setAddLabel] = useState(false);
  const [editLabelText, setEditLabeltext] = useState("");
  const [newLabel, setNewLabel] = useState("");
  const [labelItems, setLabelItems] = useState([]);
  const [labelsList, setLabelsList] = useState([]);

  useEffect(() => {
    setLabelItems(props.labelRefsList);
    setLabelsList(props.labelsList);
  }, [props.labelRefsList, props.labelsList]);

  useEffect(() => {
    if (addLabel) {
      const tmpList = labelsList;
      if (newLabel !== "") {
        tmpList.push({ id: generateUid(), label: newLabel });
        setLabelsList(tmpList);

        setNewLabel("");
        setAddLabel(false);
      }
    }
  }, [addLabel]);

  useEffect(() => {
    console.log("newLabel", newLabel);
  }, [newLabel]);

  const toggleItems = (id) => {
    const newItems = [];
    let found = false;

    for (let i = 0; i < labelItems.length; i++) {
      if (labelItems[i] !== id) {
        newItems.push(labelItems[i]);
      } else {
        found = true;
      }
    }
    if (!found) {
      newItems.push(id);
    }
    setLabelItems(newItems);
  };

  const removeItem = (id) => {
    const newItems = [];

    for (let i = 0; i < labelsList.length; i++) {
      if (labelsList[i].id !== id) {
        newItems.push(labelsList[i]);
      }
    }

    setLabelsList(newItems);
  };

  const updateItem = (id) => {
    if (editLabelText !== "") {
      const newItems = [];

      for (let i = 0; i < labelsList.length; i++) {
        if (labelsList[i].id !== id) {
          newItems.push(labelsList[i]);
        } else {
          newItems.push({ id: id, label: editLabelText });
        }
      }

      setLabelsList(newItems);
    }
    setLabelEdit(0);
    setEditLabeltext("");
  };

  return (
    <>
      <div className="ta-flex ta-flex--column">
        <Toolbar
          action={{
            onClick: () => props.onClose(),
            symbol: "close",
          }}
          title={t("labels.title")}
        ></Toolbar>

        <div className="ta-flexitem ta-is-overflow ta-margin_bottom-s">
          <div className="ta-flex ta-flex--itemscenter ta-padding_leftright-xxxs">
            <TextField
              label={t("labels.form_newlabel")}
              value={newLabel}
              maxLength={20}
              onChange={(value) => setNewLabel(value)}
              className="ta-flexitem ta-margin_leftright-xs"
              reset
            />
            <Button
              onClick={() => {
                setAddLabel(true);
                window.setTimeout(() => {
                  console.log("labelsList", labelsList);
                  props.onUpdate(labelsList, labelItems);
                }, 100);
              }}
              icon
              symbol="done"
              disabled={newLabel === ""}
            />
          </div>
          <Divider className="ta-margin_topbottom-s" />
          {labelsList.length === 0 && (
            <Typo className="ta-color-text_on-surface-low ta-margin_s ta-is-center">
              {t("labels.nolabels")}
            </Typo>
          )}
          <div>
            {labelsList.map((label, idx) => {
              return (
                <div
                  key={idx}
                  className="ta-flex ta-flex--itemscenter ta-padding_leftright-xxxs"
                >
                  {label.id === labelEdit ? (
                    <>
                      <Button
                        onClick={() => setLabelEdit("")}
                        icon
                        symbol="close"
                      />
                      <TextField
                        value={label.label}
                        onChange={(value) => setEditLabeltext(value)}
                        className="ta-flexitem ta-margin_right-s"
                        maxLength={20}
                      />

                      <Button
                        onClick={() => updateItem(label.id)}
                        icon
                        symbol="done"
                        disabled={editLabelText === ""}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() => setShowConfirmModal(label.id)}
                        icon
                        symbol="delete"
                        className="ta-color-text_on-surface-low"
                      />
                      <Typo
                        onClick={() => setLabelEdit(label.id)}
                        id={"labeltext_" + idx}
                        className="ta-flexitem ta-padding_leftright-s"
                      >
                        {label.label}
                      </Typo>
                      <Button
                        onClick={() => setLabelEdit(label.id)}
                        icon
                        symbol="edit"
                        className="ta-color-text_on-surface-low"
                      />
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <Divider />
        </div>
        <div className="ta-flex ta-flex--end ta-padding_leftright-xs">
          <Button onClick={() => props.onClose()} text>
            Done
          </Button>
        </div>
      </div>

      {showConfirmModal !== "" &&
        guiManager(
          <Dialog animate={true} zIndex={2800}>
            <DialogToolbar title={t("labels.delete_title")} />
            <div className="ta-is-center">
              <Typo className="ta-margin_topbottom-s">
                <strong>{props.itemsCount}</strong>
                &nbsp;
                {t("labels.delete_prompt")}
              </Typo>
            </div>
            <div className="ta-flex ta-flex--right">
              <Button text onClick={() => setShowConfirmModal("")}>
                {t("labels.button_cancel")}
              </Button>
              <Button
                text
                onClick={() => {
                  removeItem(showConfirmModal);
                  setShowConfirmModal("");
                }}
              >
                {t("labels.button_confirm")}
              </Button>
            </div>
          </Dialog>,
          document.fullscreenElement ? "trackmap" : "root"
        )}
    </>
  );
};

export default LabelsEditor;
