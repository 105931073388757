import React, { useEffect, useState, useContext } from "react";
import { getPostTrackData } from "../js/utils/buildTrack";

import Localbase from "localbase";

import filterQueue from "../js/utils/filter";
import sortTrackItems from "../js/utils/sort";
import ConfigContext from "./ConfigContext";

// const DB_TracksIndex = "trackRefs";
const DB_TracksIndex = "tracksIndex";
const DB_TracksBase = "tracksBase";

const TrackContext = React.createContext();
import { useParams } from "react-router-dom";
import ProgressIndicator from "../js/Trackalyze/ProgressIndicator";
import Scrim from "../js/Trackalyze/Scrim";
import { labelsGetCollection, labelsRefsGet } from "../js/utils/localStorage";

export default TrackContext;

export const TrackProvider = (props) => {
  let idtmp = undefined;
  let { id } = useParams();
  id = id !== undefined ? Number(id) : id;

  console.log("useparams id", id);

  const { profile, setProfile } = useContext(ConfigContext);

  let db = new Localbase("GPXer");

  const [trackLabels, SetTrackLabels] = useState([]);
  const [trackLoading, setTrackLoading] = useState(false);
  const [handleGPX, setHandleGPX] = useState();
  const [track, setTrack] = useState();
  const [tracks, setTracks] = useState([]);
  const [tracksAll, setTracksAll] = useState([]);
  const [tracksPoints, setTracksPoints] = useState({});
  const [trackId, setTrackId] = useState(-1);
  const [updateTrack, setUpdateTrack] = useState(false);
  const [updateTracks, setUpdateTracks] = useState(false);
  const [tracksOrder, setTracksOrder] = useState({
    orderBy: "fileUploadedTime",
    direction: "desc",
    label: "Newest",
  });
  const [tracksFilter, setTracksFilter] = useState({
    labels: [],
    activities: [],
    favorites: false,
  });
  const [isFiltered, setIsFiltered] = useState(false);
  const [importDone, setImportDone] = useState(false);

  useEffect(() => {
    db.collection(DB_TracksIndex)
      .orderBy(tracksOrder.orderBy, tracksOrder.direction)
      .get()
      .then((tmpTracks) => {
        if (tmpTracks != null) {
          setTracksAll(tmpTracks);
        }
      });
  }, []);

  useEffect(() => {
    if (
      tracksAll !== undefined &&
      tracksOrder !== undefined &&
      tracksAll.length > 0
    ) {
      const sortedTracks = sortTrackItems(tracksOrder, tracksAll);

      setTracks(sortedTracks);

      // check for valid track id in localstorage
      if (sortedTracks.length > 0) {
        console.log("use id", id);
        console.log("use tracksAll", tracksAll);

        console.log(
          "use id filter",
          tracksAll.filter((trck) => trck.id === id).length > 0
        );
        const tmpTrackId = JSON.parse(localStorage.getItem("lastTrackId"));
        if (
          id !== undefined &&
          tracksAll.filter((trck) => trck.id === id).length > 0
        ) {
          setTrackId(id);
        } else if (
          tmpTrackId &&
          tracksAll.filter((trck) => trck.id === tmpTrackId).length > 0
        ) {
          console.log("use tmpTrackId", tmpTrackId);

          setTrackId(tmpTrackId);
        } else {
          getNewestTrack();
        }
      }
    } else {
      setTracks([]);
      setTrackId(-1);
      id = undefined;
      idtmp = id;
    }
  }, [tracksAll]);

  useEffect(() => {
    console.log("sort useeffect tracksOrder", tracksOrder);
    refreshTracks();
  }, [tracksOrder]);

  useEffect(() => {
    if (updateTracks === true) {
      //  setTrackId(-1);

      window.setTimeout(() => {
        refreshTracks();
      }, 500);
      setUpdateTracks(false);
    }
  }, [updateTracks]);

  const refreshTracks = async () => {
    console.log("sort refreshtracks DB_TracksIndex", tracksOrder);

    db.collection(DB_TracksIndex)
      .orderBy(tracksOrder.orderBy, tracksOrder.direction)
      .get()
      .then((tmpTracks) => {
        console.log("sort refreshtracks tmpTracks", tmpTracks);
        if (tmpTracks != null) {
          setTracksAll(tmpTracks);
        } else {
          console.log("sort refreshtracks failed", tmpTracks);

          setTracks([]);
        }
      });
  };

  useEffect(() => {
    if (tracks.length > 0) {
      const tracksTmp = [];
      const tracksStarts = [];
      let lonMin = 0;
      let lonMax = 0;
      let latMin = 0;
      let latMax = 0;

      let tmpTrack = null;
      for (let i = 0; i < tracks.length; i++) {
        tracksStarts.push({
          lat: tracks[i].coordinatesLatFirst,
          lng: tracks[i].coordinatesLngFirst,
          id: tracks[i].id,
        });
        tracksTmp.push({
          points: tracks[i].trackPointsSimple,
          id: tracks[i].id,
        });

        if (i === 0) {
          lonMin = tracks[i].coordinatesLngMin;
          lonMax = tracks[i].coordinatesLngMax;
          latMin = tracks[i].coordinatesLatMin;
          latMax = tracks[i].coordinatesLatMax;
        }

        // *** boundaries
        tracks[i].coordinatesLngMin < lonMin &&
          (lonMin = tracks[i].coordinatesLngMin);
        tracks[i].coordinatesLatMin < latMin &&
          (latMin = tracks[i].coordinatesLatMin);
        tracks[i].coordinatesLngMax > lonMax &&
          (lonMax = tracks[i].coordinatesLngMax);
        tracks[i].coordinatesLatMax > latMax &&
          (latMax = tracks[i].coordinatesLatMax);
        // *** END boundaries
      }

      setTracksPoints({
        points: tracksTmp,
        tracksStarts: tracksStarts,
        boundaries: {
          latMin: latMin,
          lngMin: lonMin,
          latMax: latMax,
          lngMax: lonMax,
        },
      });
    } else {
      setTracksPoints({});
    }
  }, [tracks]);

  useEffect(() => {
    if (trackId !== -1 || (trackId !== -1 && updateTrack)) {
      setTrackLoading(true);

      const newTrack = {};

      for (let i = 0; i < tracksAll.length; i++) {
        if (tracksAll[i].id === trackId) {
          newTrack.$ = tracksAll[i];
          newTrack.$.index = i + 1;
        }
      }
      id = trackId;
      idtmp = id;

      const postData = getPostTrackData(newTrack.$, profile);
      newTrack.$ = {
        ...newTrack.$,
        ...postData,
      };

      setTrack(newTrack);
      window.localStorage.setItem("lastTrackId", JSON.stringify(trackId));

      // labels
      buildLabels(trackId);
      setUpdateTrack(false);
    } else if (track !== undefined) {
      setTrack(undefined);
      setTrackLoading(false);
      window.localStorage.setItem("lastTrackId", JSON.stringify(-1));
    }
  }, [trackId, updateTrack]);

  useEffect(() => {
    if (track !== undefined) {
      setTrackLoading(false);
    }
  }, [track]);

  useEffect(() => {
    if (importDone) {
      //const tmpTracks = JSON.parse(localStorage.getItem("tracks")) || null;
      db.collection(DB_TracksIndex)
        .orderBy(tracksOrder.orderBy, tracksOrder.direction)
        .get()
        .then((tmpTracks) => {
          if (tmpTracks != null) {
            const sortedTracks = sortTrackItems(tracksOrder, tmpTracks);

            setTracksAll(sortedTracks);
          }
        });
      // console.log("newest", getNewestTrack());
      //setTrackId();
      setImportDone(false);
    }
  }, [importDone]);

  const getNewestTrack = () => {
    return db
      .collection(DB_TracksIndex)
      .orderBy("fileUploadedTime", "desc")
      .get()
      .then((tmpTracks) => {
        if (tmpTracks != null) {
          setTrackId(tmpTracks[0].id);
        }
      });
  };

  const clearLocalStorage = async () => {
    await db.collection(DB_TracksBase).delete();
    await db.collection(DB_TracksIndex).delete();

    setTrackId(-1);
    setTracksAll([]);
  };

  // *** filter
  useEffect(() => {
    if (tracksAll !== undefined && tracksAll.length > 0)
      filterQueue(tracksAll, tracksFilter).then((results) => {
        if (results.filtered) {
          setIsFiltered(true);
        } else {
          setIsFiltered(false);
        }
        setTracks(results.items);
      });
  }, [tracksFilter]);
  // *** END filter

  // *** labels
  const buildLabels = async (id) => {
    const [tmpListLabels, tmpListId] = await Promise.all([
      labelsGetCollection(),
      labelsRefsGet(id),
    ]);
    console.log("labels getlabels tmpListLabels", tmpListLabels);
    console.log("labels getlabels tmpListId", tmpListId);

    const lblsList = tmpListLabels.filter((label) =>
      tmpListId.includes(label.id)
    );

    console.log("labels getlabels lblsList", lblsList);
    SetTrackLabels(lblsList);
  };
  // *** end of labels
  return (
    <TrackContext.Provider
      value={{
        tracks,
        setTracks,
        tracksPoints,
        setTracksPoints,
        track,
        setTrack,
        trackId,
        setTrackId,
        handleGPX,
        setHandleGPX,
        updateTrack,
        setUpdateTrack,
        updateTracks,
        setUpdateTracks,
        clearLocalStorage,
        importDone,
        setImportDone,
        tracksOrder,
        setTracksOrder,
        tracksFilter,
        setTracksFilter,
        isFiltered,
        trackLabels,
        SetTrackLabels,
      }}
    >
      <>
        {trackLoading && (
          <Scrim animate zIndex={9999} className="ta-height_full">
            <ProgressIndicator
              circular
              className="ta-is-absolute ta-is-centered"
            />
          </Scrim>
        )}

        {props.children}
      </>
    </TrackContext.Provider>
  );
};
