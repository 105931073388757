import React, { useContext } from "react";

import Card from "../../../Trackalyze/Card";
import TrackToolbarStatistics from "../../MapWrapper/GuiMap/TrackToolbarStatistics";
import TrackToolbarTitle from "../../MapWrapper/GuiMap/TrackToolbarTitle";
import TrackContext from "../../../../Context/TrackContext";
import FavoriteButton from "../../MapWrapper/GuiMap/FavoriteButton";
import Button from "../../../Trackalyze/Button";
import GuiSplitContext from "../../../../Context/GuiSplitContext";

const DetailsHeader = (props) => {
  const { track } = useContext(TrackContext);
  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  return (
    <Card
      noFill
      style={{ overflow: "initial" }}
      className=" ta-padding_left-xxs"
    >
      <div className="ta-flex ta-flex--itemscenter">
        <div className="ta-flexitem ta-flex ta-flex--itemscenter">
          <TrackToolbarTitle />

          <div className="ta-flex ta-flex--itemscenter">
            {/*             <LabelsButton />
             */}

            <FavoriteButton
              style={{ marginRight: "0" }}
              trackId={track === undefined ? undefined : track.$.id}
              favorite={
                track !== undefined && "favorite" in track.$
                  ? track.$.favorite
                  : false
              }
            />
          </div>
        </div>
      </div>
      <TrackToolbarStatistics />
    </Card>
  );
};

export default React.memo(DetailsHeader);
