import React from "react";

import { useNavigate } from "react-router-dom";
import Button from "../../../Trackalyze/Button";

const OptionsButton = (props) => {
  const navigate = useNavigate();

  return (
    <Button
      icon
      symbol="more_vert"
      onClick={() => {
        navigate("/options");
      }}
      {...props}
    />
  );
};

export default React.memo(OptionsButton);
