export const formatDateToLocale = (value) => {
  return new Date(value).toLocaleDateString();
};

// time functions
export const formatDateTime = (time) => {
  return {
    date: new Date(time).toLocaleDateString(),
    time: new Date(time).toLocaleTimeString(),
    dateTime: new Date(time).toLocaleString(),
  };
};

// duration functions
export const formatDuration = (seconds, seperator = ":") => {
  const secForm = formatSecondsToHHMMSS(seconds, false, seperator);
  const secFormFixed = formatSecondsToHHMMSS(seconds, true, seperator);

  const splittedFixed = secFormFixed.split(seperator);
  const splitted = seconds > 59 ? secForm.split(seperator) : ["" + seconds];
  let verbose = 0;
  if (splitted.length === 3) {
    verbose =
      Number(splitted[0]) +
      " h " +
      Number(splitted[1]) +
      " min " +
      Number(splitted[2]) +
      " s";
  } else if (splitted.length === 2) {
    verbose = Number(splitted[0]) + " min " + Number(splitted[1]) + " s";
  } else if (splitted.length === 1) {
    verbose = Number(splitted[0]) + " s ";
  }

  return {
    duration: secForm,
    durationFixed: secFormFixed,
    durationVerbose: verbose,
    durationSeconds: seconds,
    durationHours: roundNumber(seconds / 60 / 60),
    durationMinutes: roundNumber(seconds / 60),
    durationSplittedHours: splitted[0],
    durationSplittedMinutes: splitted[1],
    durationSplittedSeconds: splitted[2],
    durationSplittedFixedHours: splittedFixed[0],
    durationSplittedFixedMinutes: splittedFixed[1],
    durationSplittedFixedSeconds: splittedFixed[2],
  };
};

// distance functions
export const formatDistance = (distance) => {
  return {
    distanceKm: roundNumber(distance / 1000),
  };
};

export const formatHHMMSSToSeconds = (str) => {
  let times = str.split(":");
  times.reverse();
  let x = times.length,
    y = 0,
    z;
  for (let i = 0; i < x; i++) {
    z = times[i] * Math.pow(60, i);
    y += z;
  }
  return y;
};

export const formatSecondsToHHMMSS = (secs, fixed = false, seperator = ":") => {
  let rest = 0;
  var sec_num = parseInt(secs, 10); // don't forget the second param
  var days = Math.floor(sec_num / 86400);
  rest = sec_num - days * 86400;
  var hours = Math.floor(rest / 3600);
  rest = rest - hours * 3600;
  var minutes = Math.floor(rest / 60);
  rest = rest - minutes * 60;
  var seconds = rest;

  let formatted = seconds;

  if (!fixed) {
    formatted = minutes > 0 ? minutes + seperator + formatted : formatted;
    formatted = hours > 0 ? hours + seperator + formatted : formatted;
  } else {
    if (days < 10) {
      days = "0" + days;
    }
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    formatted = hours + seperator + minutes + seperator + seconds;
  }
  return formatted;
};

// time functions
export const formatTime = (time) => {
  return {
    date: new Date(time).toLocaleDateString(),
    time: new Date(time).toLocaleTimeString(),
    datetime:
      new Date(time).toLocaleDateString() +
      ", " +
      new Date(time).toLocaleTimeString(),
  };
};

export const roundNumber = (num = 0, digits = 1) => {
  const dig = Math.pow(10, digits);
  return Math.round(num * dig) / dig;
};
