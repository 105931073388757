import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";

import BottomSheet from "../../../Trackalyze/BottomSheet";
import Toolbar from "../../../Trackalyze/Toolbar";
import MapTypes from "./MapTypes";

const MapTypesModal = () => {
  const { t } = useTranslation();

  const [isRenderSheet, setIsRenderSheet] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    if (isRenderSheet) {
      setIsRenderSheet(false);
      window.setTimeout(() => {
        navigate(-1);
      }, 100);
    }
  };

  return (
    <BottomSheet
      zIndex={2400}
      animate={isRenderSheet}
      onAfterAnimate={() => {
        goBack();
      }}
      noScrim
    >
      <Toolbar
        action={{ onClick: () => goBack(), symbol: "close" }}
        title={t("mapoverlay.title_maps")}
      />
      <div className="ta-margin_leftright-s ta-margin_bottom-l">
        <MapTypes callBack={() => goBack()} />
      </div>
    </BottomSheet>
  );
};

export default React.memo(MapTypesModal);
