import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TrackContext from "../../../../Context/TrackContext";

import BottomSheet from "../../../Trackalyze/BottomSheet";
import Button from "../../../Trackalyze/Button";
import Divider from "../../../Trackalyze/Divider";
import Toolbar from "../../../Trackalyze/Toolbar";
import Typo from "../../../Trackalyze/Typo";
import guiManager from "../../../utils/guiManager";

const SortButton = (props) => {
  const { t } = useTranslation();

  const sortItems = [
    { id: "favorite", label: t("trackmanager.sort_favorite") },
    {
      id: "activityType",
      label: t("trackmanager.sort_activityType"),
    },
    {
      id: "activityName",
      label: t("trackmanager.sort_activityName"),
    },
    {
      id: "activityStartTime",
      label: t("trackmanager.sort_activityStartTime"),
    },
    {
      id: "fileUploadedTime",
      label: t("trackmanager.sort_fileUploadedTime"),
    },
    {
      id: "durationSeconds",
      label: t("trackmanager.sort_durationSeconds"),
    },
    { id: "distance", label: t("trackmanager.sort_distance") },
    {
      id: "elevationUp",
      label: t("trackmanager.sort_elevationUp"),
    },
  ];

  const { tracks, tracksOrder, setTracksOrder } = useContext(TrackContext);

  const [renderSheet, setRenderSheet] = useState(false);
  const [filterActions, setFilterActions] = useState({});

  useEffect(() => {
    if ("direction" in tracksOrder) {
      setFilterActions(tracksOrder);
    }
  }, [tracksOrder]);

  useEffect(() => {
    if ("direction" in filterActions) {
      console.log("SortButton filterActions", filterActions);
      if (props.trackItems.length > 0) {
        //  props.callBack(filterActions);
        setTracksOrder(filterActions);
      }
    }
  }, [filterActions]);

  return (
    <>
      <Button
        iconRight
        symbol={filterActions.direction === "asc" ? "sort_asc" : "sort_desc"}
        onClick={(e) => {
          setRenderSheet(true);
        }}
        disabled={props.disabled}
      >
        By {filterActions.label}
      </Button>
      {renderSheet &&
        guiManager(
          <BottomSheet
            zIndex={2401}
            animate
            onAfterAnimate={() => {
              setRenderSheet(false);
            }}
          >
            <Toolbar
              action={{
                symbol: "close",
                onClick: () => {
                  setRenderSheet(false);
                },
              }}
              title="Sort tracks"
            />
            <div className="ta-padding_leftright-xxxs">
              {sortItems.map((sortItem, idx) => {
                return (
                  <div
                    key={"sortlist_" + idx}
                    className={
                      "ta-list_item" +
                      (filterActions.orderBy === sortItem.id
                        ? " ta-color_secondary-container ta-shape_l"
                        : "")
                    }
                    onClick={() => {
                      setFilterActions({
                        orderBy: sortItem.id,
                        direction:
                          filterActions.orderBy === sortItem.id
                            ? filterActions.direction === "asc"
                              ? "desc"
                              : "asc"
                            : filterActions.direction,
                        label: sortItem.label,
                      });
                      setRenderSheet(false);
                    }}
                  >
                    <div className="ta-flexitem ta-margin_leftright-xxs">
                      <Typo>{sortItem.label}</Typo>
                    </div>
                    {filterActions.orderBy === sortItem.id && (
                      <Button
                        icon
                        symbol={
                          filterActions.direction === "asc"
                            ? "sort_asc"
                            : "sort_desc"
                        }
                        text
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </BottomSheet>,
          document.fullscreenElement ? "trackmap" : "root"
        )}
    </>
  );
};

export default React.memo(SortButton);
