import React, { useContext, useState } from "react";

import MapLabelContext from "../../../../Context/MapLabelContext";
import TrackContext from "../../../../Context/TrackContext";
import Chip from "../../../Trackalyze/Chip";
import Divider from "../../../Trackalyze/Divider";

const RoundsSelectionMenu = (props) => {
  const { track } = useContext(TrackContext);
  const { setSegment } = useContext(MapLabelContext);
  const [index, setIndex] = useState(0);
  return track !== undefined
    ? track.$.roundsIndex.map((round, idx) => {
        return (
          <React.Fragment key={"round_" + idx}>
            <Chip
              small
              onClick={() => {
                setIndex(idx);
                props.callBack(idx);
                setSegment({ start: 0, end: 0 });
              }}
              className={
                idx === index
                  ? "roundsstep_animate roundsstep_animate--animate"
                  : ""
              }
              outline={idx !== index}
              primary={idx === index}
            >
              {round}
            </Chip>
            {idx < track.$.roundsIndex.length - 1 && (
              <Divider key={"divider_" + idx} />
            )}
          </React.Fragment>
        );
      })
    : null;
};

export default React.memo(RoundsSelectionMenu);
