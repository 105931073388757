import React, { useContext, useEffect, useState } from "react";
import MapLabelContext from "../../../../Context/MapLabelContext";

import {
  formatDistance,
  formatSecondsToHHMMSS,
} from "../../../utils/formatter";
import Typo from "../../../Trackalyze/Typo";
import Icon from "../../../Trackalyze/Icon";
import Button from "../../../Trackalyze/Button";

let durSecs = 0;
const RoundsTable = (props) => {
  durSecs = 0;

  const { setTrackPoint } = useContext(MapLabelContext);
  const [rows, setRows] = useState([]);

  const [filterTable, setFilterTable] = useState({
    pace: false,
    speed: true,
    hr: false,
    ele: false,
  });

  const onFilter = (value) => {
    setFilterTable({
      pace: value === "pace" ? !filterTable.pace : filterTable.pace,
      speed: value === "speed" ? !filterTable.speed : filterTable.speed,
      hr: value === "heartrate" ? !filterTable.hr : filterTable.hr,
      ele: value === "elevation" ? !filterTable.ele : filterTable.ele,
    });
  };

  useEffect(() => {
    if (props.track !== undefined) {
      setRows(props.track.$.roundsTrackPoints[props.index]);
    }
  }, [props.index]);

  useEffect(() => {
    if (props.track !== undefined) {
      setRows(props.track.$.roundsTrackPoints[0]);
    }
  }, [props.track]);

  return props.track !== undefined ? (
    <Typo size="s">
      <table className="ta-table ta-table--dense">
        <thead className="rounds_tableheader ta-color_surface-container">
          <tr>
            <th className="rounds_tablefirst ta-color_surface-container">
              Distanz
            </th>
            <th
              className={"timeStart" in props.track.$ ? "" : " ta-is-disabled"}
            >
              Time
            </th>
            <th
              className={"timeStart" in props.track.$ ? "" : " ta-is-disabled"}
            >
              Duration
            </th>

            <th colSpan={filterTable.ele ? "6" : "1"}>
              <div
                className={
                  "ta-flex ta-flex--itemscenter" +
                  ("elevationUp" in props.track.$ ? " " : " ta-is-disabled")
                }
              >
                Ele
                <Button onClick={() => onFilter("elevation")} pure>
                  <Icon
                    className="ta-margin_left-xxxs ta-color-text_primary"
                    symbol={filterTable.ele ? "cell_collapse" : "cell_expand"}
                    size="s"
                    dense
                  />
                </Button>
              </div>
            </th>

            <th colSpan={filterTable.pace ? "2" : "1"}>
              <div
                className={
                  "ta-flex ta-flex--itemscenter" +
                  ("timeStart" in props.track.$ ? " " : " ta-is-disabled")
                }
              >
                Tempo
                <Button onClick={() => onFilter("pace")} pure>
                  <Icon
                    className="ta-margin_left-xxxs ta-color-text_primary"
                    symbol={filterTable.pace ? "cell_collapse" : "cell_expand"}
                    size="s"
                  />
                </Button>
              </div>
            </th>
            <th colSpan={filterTable.hr ? "3" : "1"}>
              <div
                className={
                  "ta-flex ta-flex--itemscenter" +
                  ("heartrateAvg" in props.track.$ ? " " : " ta-is-disabled")
                }
              >
                Hr
                <Button onClick={() => onFilter("heartrate")} pure>
                  <Icon
                    className="ta-margin_left-xxxs ta-color-text_primary"
                    symbol={filterTable.hr ? "cell_collapse" : "cell_expand"}
                    size="s"
                  />
                </Button>
              </div>
            </th>
          </tr>
          <tr>
            <th className="rounds_cell rounds_tableheader  rounds_tablefirst ta-color_surface-container">
              &nbsp;
              <div className="ta-color-text_on-surface-lower">km</div>
            </th>
            <th
              className={
                "rounds_cell rounds_tableheader" +
                ("timeStart" in props.track.$ ? " " : " ta-is-disabled")
              }
            >
              &nbsp;
              <div className="ta-color-text_on-surface-lower">h/m/s</div>
            </th>
            <th
              className={
                "rounds_cell rounds_tableheader" +
                ("timeStart" in props.track.$ ? " " : " ta-is-disabled")
              }
            >
              &nbsp;
              <div className="ta-color-text_on-surface-lower">h/m/s</div>
            </th>
            <th
              className={
                "rounds_cell rounds_tableheader" +
                ("elevationUp" in props.track.$ ? " " : " ta-is-disabled")
              }
            >
              <span className="ta-color-text_on-surface-low">Avg</span>
              <div className="ta-color-text_on-surface-lower">m</div>
            </th>
            {filterTable.ele && (
              <>
                <th className="rounds_cell rounds_tableheader">
                  <span className="ta-color-text_on-surface-low">Diff</span>
                  <div className="ta-color-text_on-surface-lower">m</div>
                </th>
                <th className="rounds_cell rounds_tableheader">
                  <span className="ta-color-text_on-surface-low">Up</span>
                  <div className="ta-color-text_on-surface-lower">m</div>
                </th>
                <th className="rounds_cell rounds_tableheader">
                  <span className="ta-color-text_on-surface-low">Down</span>
                  <div className="ta-color-text_on-surface-lower">m</div>
                </th>
                <th className="rounds_cell rounds_tableheader">
                  <span className="ta-color-text_on-surface-low">Min</span>
                  <div className="ta-color-text_on-surface-lower">m</div>
                </th>
                <th className="rounds_cell rounds_tableheader">
                  <span className="ta-color-text_on-surface-low">Max</span>
                  <div className="ta-color-text_on-surface-lower">m</div>
                </th>
              </>
            )}
            <th
              className={
                "rounds_cell rounds_tableheader" +
                ("timeStart" in props.track.$ ? " " : " ta-is-disabled")
              }
            >
              <span className="ta-color-text_on-surface-low">Pace</span>
              <div className="ta-color-text_on-surface-lower">min/km</div>
            </th>
            {filterTable.pace && (
              <th className="rounds_cell rounds_tableheader">
                <span className="ta-color-text_on-surface-low">Speed</span>

                <div className="ta-color-text_on-surface-lower">km/h</div>
              </th>
            )}

            <th
              className={
                "rounds_cell rounds_tableheader" +
                ("heartrateAvg" in props.track.$ ? " " : " ta-is-disabled")
              }
            >
              <span className="ta-color-text_on-surface-low">Avg</span>
              <div className="ta-color-text_on-surface-lower">BPM</div>
            </th>
            {filterTable.hr && (
              <>
                <th className="rounds_cell rounds_tableheader">
                  <span className="ta-color-text_on-surface-low">Min</span>
                  <div className="ta-color-text_on-surface-lower">BPM</div>
                </th>
                <th className="rounds_cell rounds_tableheader">
                  <span className="ta-color-text_on-surface-low">Max</span>
                  <div className="ta-color-text_on-surface-lower">BPM</div>
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {rows.map((point, idx) => {
            console.log("point", point);
            let breakClass = "rounds_cell";
            durSecs = durSecs + point.$.durationSeconds;
            if (idx === parseInt(rows.length / 2)) {
              breakClass = breakClass + " rounds_break";
            }
            return (
              <tr
                key={idx}
                onClick={() => {
                  setTrackPoint({
                    latlong: point.latlong,
                    segment: {
                      start: point.start,
                      end: point.end,
                      points: rows.slice(point.start, point.end),
                    },
                  });
                }}
              >
                {/*                 <td className={breakClass}>{idx + 1}</td>
                 */}
                <td
                  className={
                    breakClass +
                    " ta-color_surface-container rounds_tablefirst "
                  }
                >
                  <strong>
                    {formatDistance(point.$.distance * (idx + 1)).distanceKm}
                  </strong>{" "}
                </td>
                {"time" in point ? (
                  <>
                    <td className={"details-background_duration " + breakClass}>
                      {formatSecondsToHHMMSS(durSecs)}
                    </td>
                    <td className={"details-background_duration " + breakClass}>
                      {formatSecondsToHHMMSS(point.$.durationSeconds)}
                    </td>
                  </>
                ) : (
                  <>
                    <td
                      className={
                        "ta-is-disabled details-background_duration " +
                        breakClass
                      }
                    >
                      --:--
                    </td>
                    <td
                      className={
                        "ta-is-disabled details-background_duration " +
                        breakClass
                      }
                    >
                      --:--
                    </td>
                  </>
                )}
                {"elevationUp" in props.track.$ &&
                props.track.$.elevationUp > 0 ? (
                  <>
                    <td
                      className={"details-background_elevation " + breakClass}
                    >
                      {point.$.elevationStart}
                    </td>
                    {filterTable.ele && (
                      <>
                        <td
                          className={
                            "details-background_elevation " + breakClass
                          }
                        >
                          {point.$.elevationAdded}
                        </td>
                        <td
                          className={
                            "details-background_elevation " + breakClass
                          }
                        >
                          {point.$.elevationUp}
                        </td>
                        <td
                          className={
                            "details-background_elevation " + breakClass
                          }
                        >
                          {point.$.elevationDown}
                        </td>
                        <td
                          className={
                            "details-background_elevation " + breakClass
                          }
                        >
                          {point.$.elevationMin}
                        </td>
                        <td
                          className={
                            "details-background_elevation " + breakClass
                          }
                        >
                          {point.$.elevationMax}
                        </td>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <td
                      className={
                        "ta-is-disabled details-background_pace " + breakClass
                      }
                    >
                      --:--
                    </td>
                  </>
                )}
                {"paceAvg" in point.$ ? (
                  <>
                    <td className={"details-background_pace " + breakClass}>
                      {point.$.paceAvg}
                    </td>
                    {filterTable.pace && (
                      <td className={"details-background_speed " + breakClass}>
                        {point.$.speedAvg}
                      </td>
                    )}
                  </>
                ) : (
                  <>
                    <td
                      className={
                        "ta-is-disabled details-background_pace " + breakClass
                      }
                    >
                      --:--
                    </td>
                  </>
                )}

                {"heartrateAvg" in point.$ ? (
                  <>
                    <td
                      className={"details-background_heartrate " + breakClass}
                    >
                      {point.$.heartrateAvg}
                    </td>
                    {filterTable.hr && (
                      <>
                        <td
                          className={
                            "details-background_heartrate " + breakClass
                          }
                        >
                          {point.$.heartrateMin}
                        </td>
                        <td
                          className={
                            "details-background_heartrate " + breakClass
                          }
                        >
                          {point.$.heartrateMax}
                        </td>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <td
                      className={
                        "ta-is-disabled details-background_pace " + breakClass
                      }
                    >
                      --:--
                    </td>
                  </>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Typo>
  ) : null;
};

export default React.memo(RoundsTable);
