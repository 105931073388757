import React, { useContext, useEffect, useState } from "react";

import { TRACK_CLASSIFICATION_MAPPING } from "../../../config/mapTypes";
import TrackContext from "../../../../Context/TrackContext";
import Icon from "../../../Trackalyze/Icon";

const ActivityTypeIcon = (props) => {
  const { track } = useContext(TrackContext);
  const [typeSymbol, setTypeSymbol] = useState("landscape");

  useEffect(() => {
    let symbol = "app_logo";
    let actType = "";

    if (props.type) {
      console.log("type actType rops.type", props.type);

      if (props.type in TRACK_CLASSIFICATION_MAPPING) {
        actType = TRACK_CLASSIFICATION_MAPPING["" + props.type];
      }
    } else if (track !== undefined) {
      console.log(
        "type rack.$.activityType",
        track.$.activityType.toLowerCase()
      );

      if (track.$.activityType in TRACK_CLASSIFICATION_MAPPING) {
        actType =
          TRACK_CLASSIFICATION_MAPPING["" + track.$.activityType.toLowerCase()];
      }
    }

    if (actType !== "") {
      console.log("type actType", actType);
      symbol = "type_" + actType;
    }
    setTypeSymbol(symbol);
  }, [props, track]);

  return props.simple ? (
    <Icon symbol={typeSymbol} size={props.size} className={props.className} />
  ) : (
    <Icon symbol={typeSymbol} />
  );
};

export default React.memo(ActivityTypeIcon);
