import React, { useContext, useEffect, useState } from "react";
import { updateFavorites } from "../../../utils/localStorage";

import TrackContext from "../../../../Context/TrackContext";
import Snackbar from "../../../Trackalyze/Snackbar";
import ProgressIndicator from "../../../Trackalyze/ProgressIndicator";
import Dialog from "../../../Trackalyze/Dialog";
import DialogToolbar from "../../../Trackalyze/DialogToolbar";
import { useTranslation } from "react-i18next";

const TracksFavorite = (props) => {
  const { t } = useTranslation();

  const [showFavoritesFeedback, setShowFavoritesFeedback] = useState(-1);

  const { setUpdateTracks } = useContext(TrackContext);

  useEffect(() => {
    if (props.items.length > 0) {
      setFavoritesToStorage(props.items);
    }
  }, [props.items]);

  const setFavoritesToStorage = async (items) => {
    setShowFavoritesFeedback(0);
    await updateFavorites(items);
    setShowFavoritesFeedback(items.length);

    window.setTimeout(() => {
      setUpdateTracks(true);
    }, 200);
  };
  return (
    <>
      {showFavoritesFeedback === 0 && (
        <Dialog animate zIndex={2400}>
          <DialogToolbar
            symbol="star"
            title={t("trackmanager.favorites_progress_title")}
          />
          <ProgressIndicator />
        </Dialog>
      )}
      {showFavoritesFeedback > 0 && (
        <Snackbar
          zIndex={2400}
          timeOut
          animate
          fixed
          onAfterAnimate={() => setShowFavoritesFeedback(-1)}
        >
          {t("trackmanager.favorites_progress_info", {
            count: showFavoritesFeedback,
          })}
        </Snackbar>
      )}
    </>
  );
};

export default React.memo(TracksFavorite);
