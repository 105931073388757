import React, { useContext, useState } from "react";

import AllVisiblityContext from "../../../../Context/AllVisiblityContext";
import OverlayVisibilityContext from "../../../../Context/OverlayVisibilityContext";
import TrackContext from "../../../../Context/TrackContext";
import TrackMarkerVisibilityContext from "../../../../Context/TrackMarkerVisibilityContext";
import Button from "../../../Trackalyze/Button";
import LongPressButton from "../../../utils/LongPressButton";
const VisibilityButton = (props) => {
  const { tracks } = useContext(TrackContext);

  const { isAllVisible, setAllVisibility } = useContext(AllVisiblityContext);
  const { isTrackMarkerVisible, setTrackMarkerVisibility } = useContext(
    TrackMarkerVisibilityContext
  );

  const { isOverlayVisibile, setOverlayVisibility } = useContext(
    OverlayVisibilityContext
  );

  return (
    <LongPressButton
      duration={500}
      onPress={() => {
        setOverlayVisibility(!isOverlayVisibile);
        !isAllVisible && setAllVisibility(!isAllVisible);
      }}
      onLongPress={() => {
        setOverlayVisibility(!isAllVisible);
        setAllVisibility(!isAllVisible);
      }}
    >
      <Button
        disabled={tracks.length < 1}
        tonal
        elevation
        icon
        symbol={
          isOverlayVisibile && isAllVisible ? "visibility" : "visibility_off"
        }
        {...props}
      />
    </LongPressButton>
  );
};

export default VisibilityButton;
