import { labelsRefsGetCollection } from "../../js/utils/localStorage";

const filterQueue = async (filterItems = [], filterActions) => {
  let filtered = false;

  let tmpActions = [];

  if (filterActions.favorites) {
    filtered = true;
    filterItems = await filterFavorite(filterItems).then((tmpTracks) => {
      return tmpTracks;
    });
  }

  tmpActions = filterActions.activities.filter((item) => item.selected);
  if (tmpActions.length > 0) {
    filtered = true;

    filterItems = await filterActivities(filterItems, tmpActions).then(
      (tmpTracks) => {
        return tmpTracks;
      }
    );
  }

  tmpActions = filterActions.labels.filter((item) => item.selected);
  if (tmpActions.length > 0) {
    filtered = true;

    filterItems = await filterLabels(filterItems, tmpActions).then(
      (tmpTracks) => {
        return tmpTracks;
      }
    );
  }

  return { items: filterItems, filtered: filtered };
};

const filterActivities = async (tracksItems, filterAction) => {
  // let tracksItems = props.trackItems;
  let tmpTracks = [];
  if (filterAction.length > 0) {
    tmpTracks = [];
    for (let i = 0; i < tracksItems.length; i++) {
      if (
        filterAction.filter(
          (activity, idx) =>
            activity.id === tracksItems[i].type && activity.selected
        ).length > 0
      ) {
        tmpTracks.push(tracksItems[i]);
      }
    }
  }
  return tmpTracks;
};

// filter label
const filterLabels = async (tracksItems, filterAction) => {
  // let tracksItems = props.trackItems;
  let tmpTracks = [];

  // *** filter label

  if (filterAction.length > 0) {
    // get list with labels && ids
    const tmpRefsList = await labelsRefsGetCollection();
    const tmpItems = [];
    for (let i = 0; i < tmpRefsList.length; i++) {
      if (
        filterAction.filter((item) => item.id === tmpRefsList[i].id).length > 0
      ) {
        tmpItems.push(tmpRefsList[i]);
      }
    }
    // find tracks with matching id
    if (tmpItems.length > 0) {
      for (let i = 0; i < tracksItems.length; i++) {
        if (
          tmpItems.filter((item) => item.trackId === tracksItems[i].id).length >
          0
        ) {
          tmpTracks.push(tracksItems[i]);
        }
      }
    }
  }
  return tmpTracks;
};
// *** END filter label

// favorite filter

const filterFavorite = async (tracksItems) => {
  let tmpTracks = [];
  for (let i = 0; i < tracksItems.length; i++) {
    if (tracksItems[i].favorite) {
      tmpTracks.push(tracksItems[i]);
    }
  }
  return tmpTracks;
};

export default filterQueue;
