import React, { useEffect, useState } from "react";

import DeviceContext from "./DeviceContext";

const TABLET_MIN_SIZE = 600;
const LARGE_MIN_SIZE = 840;

const DeviceProvider = (props) => {
  const getTablet = () => {
    return window.innerWidth >= TABLET_MIN_SIZE &&
      window.innerHeight >= TABLET_MIN_SIZE
      ? true
      : false;
  };

  const getPortrait = () => {
    return window.innerWidth < window.innerHeight ? true : false;
  };

  const getDeviceLarge = () => {
    return window.innerWidth >= LARGE_MIN_SIZE;
  };

  const [windowWidth, setWindowWidth] = useState({ width: window.innerWidth });
  const [windowHeight, setWindowHeight] = useState({
    height: window.innerHeight,
  });

  const [isDeviceTablet, setIsDeviceTablet] = useState(getTablet());

  const [isDeviceLarge, setIsDeviceLarge] = useState(getDeviceLarge());

  const [isDeviceMobile, setIsDeviceMobile] = useState(
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  );

  const [isDevicePortrait, setIsDevicePortrait] = useState(getPortrait());

  useEffect(() => {
    // updateDimensions();
    computeWindowSizeClasses();

    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const updateDimensions = () => {
    setIsDeviceTablet(getTablet());
    setIsDevicePortrait(getPortrait());
    setIsDeviceLarge(getDeviceLarge());
    computeWindowSizeClasses();
  };

  const computeWindowSizeClasses = () => {
    // Get window width and height in dp
    const dpi = window.devicePixelRatio;
    const widthDp = window.innerWidth;
    const heightDp = window.innerHeight;

    // Compute width window size class
    const WindowWidthClass = {
      compact: false,
      medium: false,
      expanded: false,
    };
    if (widthDp < 600) {
      WindowWidthClass.compact = true;
    } else if (widthDp < 840) {
      WindowWidthClass.medium = true;
    } else {
      WindowWidthClass.expanded = true;
    }

    // Compute height window size class

    const WindowHeightClass = {
      compact: false,
      medium: false,
      expanded: false,
    };
    if (heightDp < 480) {
      WindowHeightClass.compact = true;
    } else if (heightDp < 900) {
      WindowHeightClass.medium = true;
    } else {
      WindowHeightClass.expanded = true;
    }

    setWindowWidth({ width: window.innerWidth, ...WindowWidthClass });
    setWindowHeight({ height: window.innerHeight, ...WindowHeightClass });
  };

  return (
    <DeviceContext.Provider
      value={{
        isDeviceTablet,
        isDevicePortrait,
        isDeviceMobile,
        isDeviceLarge,
        windowWidth,
        windowHeight,
      }}
    >
      {props.children}
    </DeviceContext.Provider>
  );
};

export default DeviceProvider;
