import React, { useContext } from "react";

import TrackNextButton from "./TrackNextButton";
import TrackPreviousButton from "./TrackPreviousButton";
import TracksButton from "./TracksButton";
import TrackContext from "../../../../Context/TrackContext";

const TracksNavigation = (props) => {
  const { isFiltered } = useContext(TrackContext);

  return (
    <div className="ta-elevation_2 ta-elevation--shadow ta-flex ta-flex--itemscenter ta-no-overflow ta-shape_l">
      <TrackPreviousButton tracksVisible={true} text={false} />
      <TracksButton
        simple={props.simple}
        className={isFiltered ? "ta-color_error ta-color-text_on-error" : ""}
      />

      <TrackNextButton tracksVisible={true} text={false} />
    </div>
  );
};

export default React.memo(TracksNavigation);
