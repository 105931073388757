import React, { useEffect, useState } from "react";

const Expandable = (props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(props.animate);
  }, [props.animate]);

  useEffect(() => {
    buildClasses();
  }, [animate]);

  const buildClasses = () => {
    const classes = ["ta-expandable"];
    animate && classes.push("ta-expandable--animate");
    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <div className={buildClasses()}>
      <div className="ta-no-overflow">{props.children}</div>
    </div>
  );
};

export default Expandable;
