import React, { useState } from "react";

const MapDragContext = React.createContext();
export default MapDragContext;

export const MapDragProvider = (props) => {
  const [mapDrag, setMapDrag] = useState({ dragged: false, type: "" });

  return (
    <MapDragContext.Provider
      value={{
        mapDrag,
        setMapDrag,
      }}
    >
      {props.children}
    </MapDragContext.Provider>
  );
};
