import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "../../Trackalyze/Button";
import Dialog from "../../Trackalyze/Dialog";
import DialogToolbar from "../../Trackalyze/DialogToolbar";
import ProgressIndicator from "../../Trackalyze/ProgressIndicator";
import Typo from "../../Trackalyze/Typo";
import guiManager from "../../utils/guiManager";
import { handleGPXResult } from "../../utils/localStorage";

const TrackUpload = (props) => {
  const { t } = useTranslation();

  const [fileQueue, setFileQueue] = useState([]);
  const [queuePointer, setQueuePointer] = useState(-1);
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    if (props.clicked) {
      document.getElementById("file").click();
    }
  }, []);

  useEffect(() => {
    if (fileQueue.length > 0 && queuePointer === -1) {
      setQueuePointer(0);
    }
  }, [fileQueue]);

  useEffect(() => {
    if (fileQueue.length === 0 && queuePointer === 0) {
      props.onDone(0);
    } else {
      if (queuePointer > -1 && queuePointer < fileQueue.length) {
        var reader = new FileReader();

        // reader.onloadstart = e => setIsTrackLoading(true);
        reader.onprogress = (e) => uploadProgress(e);

        //       reader.onabort = e => uploadError(e);
        //       reader.onerror = e => uploadError(e);

        reader.onloadend = (e) => uploadDone(e);

        reader.readAsText(fileQueue[queuePointer]);
      } else {
        setQueuePointer(-1);
        if (fileQueueFailed().length === 0 && fileQueue.length > 0) {
          window.setTimeout(() => {
            props.onDone(fileQueueDone().length);
          }, 100);
        }
      }
    }
  }, [queuePointer]);

  const uploadDone = async (e) => {
    const tmpQueue = fileQueue;
    tmpQueue[queuePointer].done = true;
    tmpQueue[queuePointer].error = false;
    setFileQueue(tmpQueue);

    // parse file and store to cache
    const trackIdResult = await handleGPXResult(e.target.result, {
      url: fileQueue[queuePointer].name,
      fileName: fileQueue[queuePointer].name,
      fileUploadedTime: fileQueue[queuePointer].lastModified,
      fileUploadedDate: fileQueue[queuePointer].lastModifiedDate,
      fileSize: fileQueue[queuePointer].size,
      lastModified: fileQueue[queuePointer].lastModified,
      lastModifiedDate: fileQueue[queuePointer].lastModifiedDate,
      size: fileQueue[queuePointer].size,
    });

    console.log("success trackIdResult", trackIdResult);

    setQueuePointer(queuePointer + 1);
  };

  const uploadProgress = (e) => {
    const loaded = e.loaded;
    const size = fileQueue[queuePointer].size;
    const percent = parseInt((loaded * 100) / size);
    setPercent(percent);
  };

  const fileQueueDone = () => {
    return fileQueue.filter(function (item, index) {
      return item.done === true;
    });
  };

  const fileQueueFailed = () => {
    return fileQueue.filter(function (item, index) {
      return item.error === true;
    });
  };

  const fileQueueRemove = (idx) => {
    const tmpQueue = fileQueue.filter(function (item, index) {
      return index !== idx;
    });

    setFileQueue(tmpQueue);
  };

  return (
    <>
      <>
        {/* queuePointer > -1 &&
          queuePointer < fileQueue.length */}
        {queuePointer > -1 &&
          queuePointer < fileQueue.length &&
          guiManager(
            <Dialog animate zIndex={2600}>
              <DialogToolbar title={t("import.title")} symbol="upload" />
              <div className="ta-is-center">
                <div className="ta-is-center ta-margin_topbottom-l">
                  <Typo className="ta-margin_bottom-s">
                    {queuePointer + 1}
                    <span className="ta-color-text_on-surface-lower">
                      &nbsp;/&nbsp;
                    </span>
                    {fileQueue.length}
                  </Typo>

                  {fileQueue.length === 1 ? (
                    <ProgressIndicator percent={percent} />
                  ) : (
                    <ProgressIndicator
                      percent={parseInt(
                        ((fileQueueDone().length + 1) * 100) / fileQueue.length
                      )}
                    />
                  )}
                </div>
                <Button
                  outline
                  onClick={() => props.onDone(fileQueueDone().length)}
                >
                  {t("general.button_cancel")}
                </Button>
              </div>
            </Dialog>
          )}
      </>

      <form
        style={{ zIndex: "4", opacity: "0" }}
        encType="multipart/form-data"
        className="ta-is-absolute ta-is-topleft ta-width_full ta-height_full"
      >
        <input
          className="ta-is-absolute ta-width_full ta-height_full"
          style={{ opacity: "0" }}
          accept=".tcx, .gpx"
          type="file"
          name="file"
          id="file"
          multiple
          onChange={(e) => {
            console.log("import files", Array.from(event.target.files));
            setFileQueue(Array.from(event.target.files));
          }}
        />
      </form>
    </>
  );
};

export default React.memo(TrackUpload);
