import React, { useContext } from "react";

import { useNavigate } from "react-router-dom";
import Button from "../../../Trackalyze/Button";
import TrackContext from "../../../../Context/TrackContext";
import Icon from "../../../Trackalyze/Icon";
import Typo from "../../../Trackalyze/Typo";
import GuiSplitContext from "../../../../Context/GuiSplitContext";

const TracksButton = (props) => {
  const navigate = useNavigate();
  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  const { track, tracks, isFiltered } = useContext(TrackContext);

  const getTrackIndex = (id, tracks) => {
    let idx = -1;

    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].id === id) {
        idx = i;
      }
    }
    return idx + 1;
  };

  return props.simple ? (
    <Button
      onClick={() => {
        console.log("tracks");
        // navigate("/tracks");
        setGuiSplit({
          ...guiSplit,
          tracks: !guiSplit.tracks,
        });
      }}
      icon
      symbol="playlist_play"
    />
  ) : (
    <Button
      pure
      onClick={() => {
        console.log("tracks");
        // navigate("/tracks");
        setGuiSplit({
          ...guiSplit,
          tracks: !guiSplit.tracks,
        });
      }}
      className={
        "ta-flexitem ta-flex--nowrap " +
        (props.className ? props.className : "")
      }
    >
      <div className="ta-flex ta-flex--itemscenter">
        {/*   {isFiltered ? (
          <Icon
            symbol="filter_list"
            size="s"
            dense
            className="ta-margin_right-xxxs"
          />
        ) : (
          <Icon
            symbol="playlist_play"
            className="ta-color-text_primary ta-margin_right-xxxs"
          />
        )} */}
        <Icon symbol="playlist_play" className="ta-margin_right-xxxs" />
        <Typo type="label" size="m" noWrap>
          {track !== undefined ? (
            <>
              {track !== undefined ? getTrackIndex(track.$.id, tracks) : -1} /{" "}
              {tracks.length}
            </>
          ) : (
            <span>Tracks</span>
          )}
        </Typo>
      </div>
    </Button>
  );
};

export default React.memo(TracksButton);
