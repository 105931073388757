import React, { useContext } from "react";
import GuiSplitContext from "../../../Context/GuiSplitContext";

import DetailsHeader from "../Components/Details/DetailsHeader";
import MainNavigationTrack from "./GuiMap/MainNavigationTrack";

const PanelWrapper = (props) => {
  const { guiSplit, setGuiSplit } = useContext(GuiSplitContext);

  return (
    <div
      id="guiSplit"
      className={
        "ta-is-relative layout_panel layout_panel--side ta-flexitem " +
        (guiSplit.expanded ? "" : " layout_panel--sidemax ")
      }
    >
      <div className="ta-is-relative ta-flex ta-flex--column ta-flexitem ta-height_full">
        <MainNavigationTrack />
        <DetailsHeader
          simple
          callBack={() =>
            setGuiSplit({
              ...guiSplit,
              details: false,
              profiles: false,
              rounds: false,
              expanded: false,
            })
          }
        />

        {props.children}
      </div>
    </div>
  );
};

export default PanelWrapper;
