import React, { useEffect, useState } from "react";

import configProfile from "../js/config/profile";

const ConfigContext = React.createContext();
export default ConfigContext;

export const ConfigProvider = (props) => {
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (profile === null) {
      if (localStorage.getItem("configProfile") !== null) {
        setProfile(JSON.parse(localStorage.getItem("configProfile")));
      } else {
        window.localStorage.setItem(
          "configProfile",
          JSON.stringify(configProfile)
        );
        setProfile(configProfile);
      }
    } else if (JSON.parse(localStorage.getItem("configProfile")) !== profile) {
      window.localStorage.setItem("configProfile", JSON.stringify(profile));
    }
  }, [profile]);

  return (
    <ConfigContext.Provider
      value={{
        profile,
        setProfile,
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};
