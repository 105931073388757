import React, { useState } from "react";

const AllVisiblityContext = React.createContext();
export default AllVisiblityContext;

export const AllVisiblityProvider = (props) => {
  const [isAllVisible, setAllVisibility] = useState(true);

  return (
    <AllVisiblityContext.Provider
      value={{
        isAllVisible,
        setAllVisibility,
      }}
    >
      {props.children}
    </AllVisiblityContext.Provider>
  );
};
