import React, { useEffect, useState } from "react";
import Button from "../../../Trackalyze/Button";
import ProgressIndicator from "../../../Trackalyze/ProgressIndicator";

let watchId = null;

const LocationButton = (props) => {
  const [locationState, setLocationState] = useState(0);
  const [isPending, setIsPending] = useState(false);

  useEffect(() => {
    if (props.init) {
      getCurrentLocation();
    }
    return () => {
      resetLocationFix();
    };
  }, []);

  const getCurrentLocation = () => {
    if (navigator.geolocation) {
      if (locationState === 1) {
        resetLocationFix();
      } else {
        setIsPending(true);
        navigator.geolocation.clearWatch(watchId);

        watchId = navigator.geolocation.watchPosition(
          (position) => {
            setIsPending(false);
            setLocationState(1);
            console.log("location", position);
            props.callBack({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              radius: position.coords.accuracy,
              heading: position.coords.heading,
              type: "location",
            });
          },
          (error) => {
            console.log("error".error);
          },
          {
            enableHighAccuracy: false,
            timeout: 1000,
            maximumAge: 0,
          }
        );
        if (window.navigator.vibrate) {
          window.navigator.vibrate(1000);
        }
      }
    } else {
      (error) => console.log(error);
    }
  };

  const resetLocationFix = () => {
    setLocationState(0);
    setIsPending(false);
    navigator.geolocation.clearWatch(watchId);
    props.callBack({});
  };

  return (
    <div className="ta-is-relative">
      <Button
        elevation
        disabled={isPending}
        {...props}
        icon
        tonal={locationState === 1 ? false : true}
        tertiary={locationState !== 1 ? false : true}
        symbol={locationState ? "my_location" : "location_searching"}
        onClick={() => {
          getCurrentLocation();
        }}
      />
      {isPending && (
        <div className="ta-is-absolute ta-is-topleft">
          <ProgressIndicator circular />
        </div>
      )}
    </div>
  );
};

export default React.memo(LocationButton);
