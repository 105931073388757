import React, { useContext, useEffect, useState } from "react";

import Chart from "./Chart";
import MapLabelContext from "../../../../Context/MapLabelContext";
import TrackContext from "../../../../Context/TrackContext";
import QuickInfoItems from "../../MapWrapper/GuiMap/QuickInfoItems";
import ChartsPointInfo from "./ChartsPointInfo";
import { useTranslation } from "react-i18next";

const ChartsSwitch = (props) => {
  const { t } = useTranslation();

  const { setTrackPoint, trackPoint } = useContext(MapLabelContext);

  const { track } = useContext(TrackContext);

  const [calcedRounds, setCalcedRounds] = useState();
  const [payLoad, setPayLoad] = useState({});
  // const [totalItems, setTotalItems] = useState([]);
  const [rounds, setRounds] = useState([]);

  useEffect(() => {
    console.log("ue payload", payLoad);
  }, [payLoad]);

  useEffect(() => {
    if (track !== undefined) {
      setPayLoad({});
      /*  if (props.type === "elevation") {
        setTotalItems([
          {
            key: "elevationUp",
            value: track.$.elevationUp,
          },
          {
            key: "elevationDown",
            value: track.$.elevationDown,
          },
          {
            key: "elevationMin",
            value: track.$.elevationMin,
          },
          {
            key: "elevationMax",
            value: track.$.elevationMax,
          },
        ]);
      }
      if (props.type === "pace") {
        setTotalItems([
          {
            key: "paceMax",
            value: track.$.paceMax,
          },
          { key: "pace", value: track.$.paceAvg },
          {
            key: "paceMin",
            value: track.$.paceMin,
          },
        ]);
      }
      if (props.type === "speed") {
        setTotalItems([
          {
            key: "speedMax",
            value: track.$.speedMax,
          },
          { key: "speed", value: track.$.speedAvg },
          {
            key: "speedMin",
            value: track.$.speedMin,
          },
        ]);
      }
      if (props.type === "heartrate") {
        setTotalItems([
          {
            key: "hrMin",
            value: track.$.heartrateMin,
          },
          {
            key: "hrAvg",
            value: track.$.heartrateAvg,
          },
          {
            key: "hrMax",
            value: track.$.heartrateMax,
          },
        ]);
      } */
    }
  }, [props.type, track]);

  useEffect(() => {
    if (track !== undefined) {
      const clcRnds = track.$.chartsTrackPoints;
      const tmpRounds = clcRnds.rounds;
      setRounds(tmpRounds);
      setCalcedRounds(clcRnds);
    }
  }, [track]);

  return calcedRounds !== undefined ? (
    <div
      className="ta-typeface_body-s ta-is-relative scroll-snap_item ta-is-center"
      onClick={() => {
        // setPayLoad({})
      }}
    >
      {props.type === "elevation" && (
        <div id="chartID_elevation" className="chart">
          <Chart
            id={props.type}
            data={rounds}
            xAxis="distanceAddedKm"
            yAxis="elevationHeight"
            xUnit={t("units.distance")}
            xDomain={[0, track.$.formatted.distanceKm]}
            yUnit=""
            yDomain={[track.$.elevationMin, track.$.elevationMax]}
            fill="#11e445"
            stroke="#11e445"
            onClick={(payload) => {
              console.log("payload 2", payload);

              setTrackPoint({ latlong: payload.latlong, ...payload });
            }}
            trackPoint={trackPoint.distanceKm}
            onToolTip={(payload) => {
              console.log("payload 3", payload);

              setPayLoad(payload);
            }}
          />
        </div>
      )}
      {props.type === "pace" && (
        <div id="chartID_pace" className="chart">
          <Chart
            id={props.type}
            data={rounds}
            xAxis="distanceAddedKm"
            yAxis="paceAvg"
            xUnit={t("units.distance")}
            yUnit=""
            xDomain={[0, track.$.formatted.distanceKm]}
            yDomain={[track.$.paceMin, track.$.paceMaX]}
            fill="#d9d904"
            stroke="#d9d904"
            onClick={(payload) => {
              setTrackPoint({ latlong: payload.latlong });
            }}
            onToolTip={(payload) => {
              setPayLoad(payload);
            }}
          />
        </div>
      )}
      {props.type === "speed" && (
        <div id="chartID_speed" className="chart">
          <Chart
            id={props.type}
            data={rounds}
            xAxis="distanceAddedKm"
            yAxis="speed"
            xUnit={t("units.distance")}
            yUnit=""
            xDomain={[0, track.$.formatted.distanceKm]}
            yDomain={[track.$.speedMin, track.$.speedMax]}
            fill="#ffc004"
            stroke="#ffc004"
            onClick={(payload) => {
              setTrackPoint({ latlong: payload.latlong });
            }}
            onToolTip={(payload) => {
              setPayLoad(payload);
            }}
          />
        </div>
      )}
      {props.type === "heartrate" && (
        <div id="chartID_heartrate" className="chart">
          <Chart
            id={props.type}
            data={rounds}
            xAxis="distanceAddedKm"
            yAxis="heartrateAvg"
            xUnit={t("units.distance")}
            yUnit=""
            xDomain={[0, track.$.formatted.distanceKm]}
            yDomain={[track.$.heartrateMin, track.$.heartrateMax]}
            fill="#f50021"
            stroke="#f50021"
            onClick={(payload) => {
              setTrackPoint({ latlong: payload.latlong });
            }}
            onToolTip={(payload) => {
              setPayLoad(payload);
            }}
          />
        </div>
      )}
      {props.totalItems && (
        <div className="ta-margin_topbottom-xxs">
          <QuickInfoItems items={props.totalItems} />
        </div>
      )}
    </div>
  ) : null;
};

export default React.memo(ChartsSwitch);
