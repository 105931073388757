import React, { useState, useRef } from "react";

const LongPressButton = ({ duration, onLongPress, onPress, children }) => {
  const [isPressing, setIsPressing] = useState(false);
  const pressTimerRef = useRef(null);

  const handlePressStart = () => {
    console.log("press start");
    setIsPressing(true);
    pressTimerRef.current = setTimeout(handleLongPress, duration);
  };

  const handlePressEnd = () => {
    console.log("press end");

    if (isPressing) {
      clearTimeout(pressTimerRef.current);
      setIsPressing(false);
      if (onPress) {
        console.log("isTrackMarkerVisible press end");

        onPress();
      }
    }
  };

  const handleLongPress = () => {
    setIsPressing(false);
    if (onLongPress) {
      console.log("press long");

      onLongPress();
    }
  };

  const isTouchEnabled = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  return (
    <span
      onContextMenu={(e) => e.preventDefault()}
      onClick={(e) => e.preventDefault()}
      onTouchStart={isTouchEnabled() ? handlePressStart : undefined}
      onTouchEnd={isTouchEnabled() ? handlePressEnd : undefined}
      onMouseDown={!isTouchEnabled() ? handlePressStart : undefined}
      onMouseUp={!isTouchEnabled() ? handlePressEnd : undefined}
      onMouseLeave={!isTouchEnabled() ? handlePressEnd : undefined}
    >
      {children}
    </span>
  );
};

export default LongPressButton;
