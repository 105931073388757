import React, { useContext, useEffect, useState } from "react";

import MapWrapper from "./MapWrapper";

import TrackContext from "../../../Context/TrackContext";
import ProgressIndicator from "../../Trackalyze/ProgressIndicator";
import PositionAbsolute from "../../Trackalyze/patterns/PositionAbsolute";

const TrackMap = () => {
  const [renderTrack, setRenderTrack] = useState(false);

  const { track, tracks, tracksPoints } = useContext(TrackContext);

  useEffect(() => {
    setRenderTrack(true);
  }, [tracks]);

  return renderTrack ? (
    <MapWrapper track={track} tracks={tracks} tracksPoints={tracksPoints} />
  ) : (
    <PositionAbsolute center>
      <ProgressIndicator circular />
    </PositionAbsolute>
  );
};

export default TrackMap;
