export const buildInfoItems = (items) => {
  const INFO_ITEMS = {
    dateStart: {
      disabled: false,
      value: "--.--.----",
      label: "Day",
      symbol: "date_range",
      fill: "ta-color-text_on-surface",
    },
    duration: {
      disabled: false,
      value: "--:--",
      unit: "h/m/s",
      label: "Duration",
      symbol: "duration",
      fill: "details-color_duration",
    },
    distance: {
      disabled: false,
      value: "--:--",
      unit: "km",
      label: "Distance",
      symbol: "distance",
      fill: "details-color_distance",
    },
    elevationUp: {
      disabled: false,
      value: "--",
      unit: "m",
      label: "Up",
      symbol: "elevation_up",
      fill: "details-color_elevation",
    },
    elevationDown: {
      disabled: false,
      value: "--",
      unit: "m",
      label: "Down",
      symbol: "elevation_down",
      fill: "details-color_elevation",
    },
    elevationMin: {
      disabled: false,
      value: "--",
      unit: "m",
      label: "Min",
      symbol: "elevation_min",
      fill: "details-color_elevation",
    },
    elevationMax: {
      disabled: false,
      value: "--",
      unit: "m",
      label: "Max",
      symbol: "elevation_max",
      fill: "details-color_elevation",
    },
    pace: {
      disabled: false,
      value: "--.--",
      unit: "min/km",
      label: "Pace",
      symbol: "pace",
      fill: "details-color_pace",
    },
    paceMin: {
      disabled: false,
      value: "--.--",
      unit: "min/km",
      label: "Min pace",
      symbol: "turtle",
      fill: "details-color_pace",
    },
    paceMax: {
      disabled: false,
      value: "--.--",
      unit: "min/km",
      label: "Max pace",
      symbol: "rabbit",
      fill: "details-color_pace",
    },
    speed: {
      disabled: false,
      value: "--.--",
      unit: "km/h",
      label: "Speed",
      symbol: "speed",
      fill: "details-color_speed",
    },
    speedMin: {
      disabled: false,
      value: "--.--",
      unit: "km/h",
      label: "Min speed",
      symbol: "turtle",
      fill: "details-color_speed",
    },
    speedMax: {
      disabled: false,
      value: "--.--",
      unit: "km/h",
      label: "Max speed",
      symbol: "rabbit",
      fill: "details-color_speed",
    },
    hrMin: {
      disabled: false,
      value: "--",
      unit: "BPM",
      label: "Min",
      symbol: "heartrate_min",
      fill: "details-color_heartrate",
    },
    hrAvg: {
      disabled: false,
      value: "--",
      unit: "BPM",
      label: "Average",
      symbol: "heartrate_average",
      fill: "details-color_heartrate",
    },
    hrMax: {
      disabled: false,
      value: "--",
      unit: "BPM",
      label: "Max",
      symbol: "heartrate_max",
      fill: "details-color_heartrate",
    },
  };

  const newItems = items.map((item) => {
    if (item.divider) {
      return { divider: true };
    }

    const tmpItem = INFO_ITEMS[item.key];

    if (item.fill) {
      tmpItem.fill = item.fill;
    }
    if (!item.disabled) {
      tmpItem.value = item.value;
      tmpItem.disabled = false;
    } else {
      tmpItem.fill = "ta-color-text_on-surface-lowest";
      tmpItem.disabled = true;
    }

    return tmpItem;
  });
  return newItems;
};
