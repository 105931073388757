import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BottomSheet from "../../../Trackalyze/BottomSheet";
import Button from "../../../Trackalyze/Button";
import List from "../../../Trackalyze/List";
import Toolbar from "../../../Trackalyze/Toolbar";
import guiManager from "../../../utils/guiManager";

const SelectedTracksOptions = (props) => {
  const { t } = useTranslation();

  const [renderSheet, setRenderSheet] = useState(false);
  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    const items = [];
    items.push(
      {
        action: () => {
          props.selectAllItems();
          setRenderSheet(false);
        },
        symbol: "select_all",
        headline: t("trackmanager.selection_options_all"),
      },
      {
        action: () => {
          props.invertSelectedItems();
          setRenderSheet(false);
        },
        symbol: "unselect_all",
        headline: t("trackmanager.selection_options_invert"),
      }
    );

    setItemsList(items);
  }, [props]);
  return (
    <>
      <Button
        icon
        symbol={"more_vert"}
        onClick={() => {
          setRenderSheet(!renderSheet);
        }}
      />

      {guiManager(
        <BottomSheet zIndex={2400} animate={renderSheet}>
          <Toolbar
            elevation={1}
            title={t("trackmanager.selection_options_title")}
            action={{ symbol: "close", onClick: () => setRenderSheet(false) }}
          />

          {itemsList.length > 0 && <List items={itemsList} />}
        </BottomSheet>,
        document.fullscreenElement ? "trackmap" : "root"
      )}
    </>
  );
};

export default React.memo(SelectedTracksOptions);
