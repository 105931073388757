import React, { useEffect, useState } from "react";
import Button from "./Button";
import Typo from "./Typo";

const Snackbar = (props) => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (props.animate !== animate && props.animate === false) {
      close();
    } else if (props.animate === true) {
      window.setTimeout(() => {
        setAnimate(true);
      }, 100);
      console.log("snacki");
      window.setTimeout(() => {
        console.log("snacki off", animate);

        close();
      }, 3100);
    }
  }, [props.animate]);

  useEffect(() => {
    if (animate === true && props.timeOut) {
      let timer = 3000;

      if (typeof props.timeOut === "number") {
        timer = props.timeOut;
      }
      window.setTimeout(() => {
        console.log("snacki off", animate);

        close();
      }, timer);
    }
  }, [animate]);

  const close = () => {
    if (animate) {
      console.log("snacki close");

      setAnimate(false);
      if (props.onAfterAnimate) {
        window.setTimeout(() => {
          props.onAfterAnimate();
        }, 500);
      }
    }
  };

  const buildClasses = () => {
    const classes = ["ta-snackbar"];
    props.fixed && classes.push("ta-snackbar--fixed");
    typeof props.fixed === "string"
      ? classes.push("ta-snackbar--fixed-" + props.fixed)
      : classes.push("ta-snackbar--fixed-frombottom");

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <div
      className={`${buildClasses()} ${animate ? " ta-snackbar--animate" : ""}`}
      style={props.zIndex ? { zIndex: props.zIndex } : {}}
    >
      <Typo type="label" className="ta-snackbar_label">
        {props.children}
      </Typo>
      <nav className="ta-snackbar_action">
        {props.action && (
          <Button
            onClick={props.action.onClick}
            className="ta-color-text_inverse-primary"
          >
            {props.action.label}
          </Button>
        )}
        {props.close && (
          <Button
            onClick={() => close()}
            symbol="close"
            icon
            iconProps={{ className: "ta-color-text_inverse-on-surface" }}
          />
        )}
      </nav>
    </div>
  );
};

export default Snackbar;
