import React, { useState } from "react";

const TrackVisiblityContext = React.createContext();
export default TrackVisiblityContext;

export const TrackVisiblityProvider = (props) => {
  const [isTrackVisible, setTrackVisibility] = useState(true);

  return (
    <TrackVisiblityContext.Provider
      value={{
        isTrackVisible,
        setTrackVisibility,
      }}
    >
      {props.children}
    </TrackVisiblityContext.Provider>
  );
};
