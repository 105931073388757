import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import LocationContext from "../../../../Context/LocationContext";
import TrackContext from "../../../../Context/TrackContext";
import BottomSheet from "../../../Trackalyze/BottomSheet";
import List from "../../../Trackalyze/List";

const OptionsModal = () => {
  const { t } = useTranslation();

  const { track } = useContext(TrackContext);

  const { showMyLocation, setShowMyLocation } = useContext(LocationContext);

  const [isRenderSheet, setIsRenderSheet] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    if (isRenderSheet) {
      setIsRenderSheet(false);
      window.setTimeout(() => {
        navigate("/");
      }, 100);
    }
  };
  return (
    <BottomSheet
      zIndex={2400}
      animate={isRenderSheet}
      onAfterAnimate={() => {
        goBack();
      }}
    >
      <List
        items={[
          /*           {
            action: () => {
              navigate("/");

              window.setTimeout(() => {
                navigate("/visibility");
              }, 100);
            },
            symbol: "visibility_manage",
            headline: t("options.overlay"),
            disabled: track === undefined,
          }, */
          {
            action: () => {
              navigate("/");

              window.setTimeout(() => {
                navigate("/labels");
              }, 100);
            },
            symbol: "label",
            headline: t("options.labels"),
            disabled: track === undefined,
          },
          /*           {
            action: () => {
              setShowMyLocation(!showMyLocation);
              // goBack(false);
            },
            symbol:
              showMyLocation === true ? "my_location" : "location_disabled",
            headline: t("options.location"),
            switch: showMyLocation,
          }, */
          {
            divider: true,
          },
          {
            action: () => {
              navigate("/");
              window.setTimeout(() => {
                navigate("/settings");
              }, 100);
            },
            symbol: "account_manage",
            headline: t("options.settings"),
          },
        ]}
      />
    </BottomSheet>
  );
};

export default React.memo(OptionsModal);
