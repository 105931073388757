// *** unused

const separator2Tag = (textString) => {
  let parts = [];
  for (var i = 0; i < textString.length; i++) {
    if (textString[i] === ":" || textString[i] === ".") {
      parts[i] = (
        <span className="match" key={i}>
          {textString[i]}
        </span>
      );
    } else {
      parts[i] = textString[i];
    }
  }
  return parts;
};
export default separator2Tag;

export const sysLog = (text = "", value = "", color) => {
  let style = "";
  if (color) {
    style = "padding:4px;background: " + color;
  }
  console.log("%c " + text, style, value);
};

export const generateUid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substr(2);
};
