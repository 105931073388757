import React, { useState } from "react";

const MapPanContext = React.createContext();
export default MapPanContext;

export const MapPanProvider = (props) => {
  const [mapPanBy, setMapPanBy] = useState("");

  return (
    <MapPanContext.Provider
      value={{
        mapPanBy,
        setMapPanBy,
      }}
    >
      {props.children}
    </MapPanContext.Provider>
  );
};
