import Button from "./Button";
import Divider from "./Divider";
import Icon from "./Icon";
import React from "react";
import Switch from "./Switch";
import Typo from "./Typo";

const List = (props) => {
  const buildClasses = () => {
    const classes = ["ta-list"];
    props.disabled && classes.push("ta-is-disabled");
    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  const action = () => {
    props.action && props.action();
  };

  const initId = new Date().getTime();

  return (
    <div className={buildClasses()}>
      {props.items.map((item, idx) => {
        return props.dividers && idx < props.items.length - 1 ? (
          <Divider key={"divider_" + idx * 2} />
        ) : (
          <React.Fragment key={initId + "_list_item_" + idx * 2}>
            {item.divider ? (
              <Divider />
            ) : (
              <div
                className={
                  "ta-list_item ta-flex ta-flex--itemscenter " +
                  (item.disabled ? " ta-is-disabled" : "")
                }
                onClick={item.action && (() => item.action())}
              >
                {item.symbol && (
                  <Icon
                    className={
                      "ta-margin_xxs " +
                      (item.symbolFill ? item.symbolFill : "")
                    }
                    symbol={item.symbol}
                  />
                )}

                <div className="ta-flexitem ta-margin_leftright-xxs">
                  <Typo noWrap={props.noWrap || item.noWrap}>
                    {item.headline}
                  </Typo>
                  {item.supporting && (
                    <Typo
                      size="m"
                      noWrap={props.noWrap || item.noWrap}
                      className="ta-color-text_on-surface-low"
                    >
                      {item.supporting}
                    </Typo>
                  )}
                </div>
                {"headerRight" in item && (
                  <Typo className="ta-color-text_on-surface-low ta-margin_right-s">
                    {item.headerRight}
                  </Typo>
                )}
                {typeof item.switch === "boolean" && (
                  <Switch
                    className="ta-margin_leftright-xxs"
                    switched={item.switch}
                  />
                )}
                {item.trailingIcon && (
                  <Icon
                    className="ta-margin_leftright-xxs"
                    symbol={item.trailingIcon}
                  />
                )}
                {item.secondary && (
                  <Button
                    pure
                    onClick={
                      item.secondary.action && (() => item.secondary.action())
                    }
                  >
                    <Icon
                      className="ta-margin_right-s"
                      symbol={item.secondary.symbol}
                    />
                  </Button>
                )}
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default List;
