import React, { useEffect, useState, useContext } from "react";
import RoundsContext from "../../../../Context/RoundsContext";
import Icon from "../../../Trackalyze/Icon";
import Button from "../../../Trackalyze/Button";
import Typo from "../../../Trackalyze/Typo";
import Chip from "../../../Trackalyze/Chip";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
const RoundsSelection = (props) => {
  const { t } = useTranslation();

  const { mapRounds, setMapRounds } = useContext(RoundsContext);

  const [roundsItems, setRoundsItems] = useState([]);

  useEffect(() => {
    const items = [];

    for (let i = 0; i < props.roundsIndex.length; i++) {
      props.roundsIndex[i] !== "" &&
        items.push({
          label: props.roundsIndex[i].toString(),
          active:
            i === (mapRounds.auto ? mapRounds.index : mapRounds.selection),
        });
      setRoundsItems(items);
    }
  }, [mapRounds]);

  return roundsItems.length > 0 ? (
    <>
      <div
        onClick={() => {
          setMapRounds({
            ...mapRounds,
            visible: !mapRounds.visible,
          });
        }}
        className="ta-flex ta-flex--itemscenter ta-margin_xxxs"
      >
        <div className="ta-flexitem ta-flex ta-flex--out ta-flex--itemscenter">
          <Typo className="ta-margin_left-xs">
            {t("mapoverlay.trackrounds_visibility")}
          </Typo>

          <Button
            icon
            text={mapRounds.visible}
            symbol={mapRounds.visible ? "visibility" : "visibility_off"}
          />
        </div>
      </div>

      <div className="ta-flex--center ta-is-center ta-margin_bottom-s">
        <Chip
          small
          onClick={() => {
            setMapRounds({
              ...mapRounds,
              auto: !mapRounds.auto,
            });
          }}
          disabled={!mapRounds.visible}
          primary={mapRounds.auto}
          outline={!mapRounds.auto}
        >
          {t("rounds.chip_auto")}
        </Chip>
        {roundsItems.map((value, idx) => {
          return (
            <Chip
              small
              key={"rndsel_" + idx}
              disabled={!mapRounds.visible}
              primary={value.active && !mapRounds.auto}
              outline={!value.active || mapRounds.auto}
              onClick={() => {
                console.log("rounds", mapRounds);
                setMapRounds({
                  ...mapRounds,
                  auto: false,
                  selection: idx,
                });
              }}
            >
              {value.label}
            </Chip>
          );
        })}
      </div>
    </>
  ) : null;
};

export default React.memo(RoundsSelection);
