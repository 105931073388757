import React, { useContext } from "react";

import MapLabelContext from "../../../../Context/MapLabelContext";
import TrackContext from "../../../../Context/TrackContext";
import Typo from "../../../Trackalyze/Typo";
import Divider from "../../../Trackalyze/Divider";

const WaypointsList = (props) => {
  const { track } = useContext(TrackContext);
  const { setTrackPoint } = useContext(MapLabelContext);

  return track.$.markerWaypoints ? (
    <>
      <div className="ta-margin_leftright-s ta-is-overflow">
        {track.$.markerWaypoints.map((wpt, idx) => (
          <React.Fragment key={"WaypointsItem_" + idx}>
            <div
              onClick={() => {
                setTrackPoint({
                  ...wpt,
                  latlong: { lat: wpt.lat, lng: wpt.lng },
                });
              }}
              id={"WaypointsItem_" + (idx + 1)}
              className="ta-padding_xxs ta-flex scroll-snap_item"
            >
              {/*        <Typo className="ta-color-text_on-surface-low ta-padding_left-xxxs ta-padding_right-m">
                {idx + 1}.
              </Typo> */}
              <div className="ta-flex ta-flex--column">
                <Typo>{wpt.name}</Typo>
                {wpt.desc != "" && (
                  <Typo size="s" className="ta-color-text_on-surface-low">
                    {wpt.desc}
                  </Typo>
                )}
              </div>
            </div>
            {idx < track.$.markerWaypoints.length - 1 && (
              <Divider
                key={"divider_" + idx}
                className="ta-margin_topbottom-xxs"
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </>
  ) : (
    <Typo
      size="s"
      className="ta-is-center ta-color-text_on-surface-lower ta-padding_s"
    >
      No waypoints in this track.
    </Typo>
  );
};

export default WaypointsList;
