import React, { useState } from "react";

const OverlayVisibilityContext = React.createContext();
export default OverlayVisibilityContext;

export const OverlayVisibilityProvider = (props) => {
  const [isOverlayVisibile, setOverlayVisibility] = useState(true);

  return (
    <OverlayVisibilityContext.Provider
      value={{
        isOverlayVisibile,
        setOverlayVisibility,
      }}
    >
      {props.children}
    </OverlayVisibilityContext.Provider>
  );
};
