import React, { useEffect, useState } from "react";

import Icon from "../../../Trackalyze/Icon";
import Typo from "../../../Trackalyze/Typo";
import { buildInfoItems } from "../../../utils/InfoManager";

let infoItems = [];
const QuickInfoItems = (props) => {
  const [item, setItems] = useState(
    "items" in props ? buildInfoItems(props.items) : []
  );

  useEffect(() => {
    infoItems = buildInfoItems(props.items);
    console.log("infoItems", infoItems);
    setItems(infoItems);
  }, [props.items]);
  return (
    <div className="QuickInfoItems ta-flex ta-flex--center ta-flex--itemscenter">
      {item.map((item, idx) => {
        return (
          <div
            key={idx}
            className={
              "ta-flex ta-flex--itemscenter ta-flex--center ta-margin_leftright-xxs" +
              (props.stacked ? " ta-margin_leftright-xs ta-flex--column" : "") +
              (item.disabled ? " ta-is-disabled" : "")
            }
          >
            <>
              <Icon
                symbol={item.symbol}
                size="s"
                className={item.fill + " ta-margin_right-xxxs"}
              />
              <Typo
                size="s"
                className="ta-flex ta-flex--colum ta-flex--itemsstart"
              >
                {item.value}
                <span className="ta-color-text_on-surface-low">
                  &nbsp;{item.unit}
                </span>
              </Typo>
            </>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(QuickInfoItems);
