import React, { useState } from "react";

const TracksVisiblityContext = React.createContext();
export default TracksVisiblityContext;

export const TracksVisiblityProvider = (props) => {
  const [isTracksVisible, setTracksVisibility] = useState(true);

  return (
    <TracksVisiblityContext.Provider
      value={{
        isTracksVisible,
        setTracksVisibility,
      }}
    >
      {props.children}
    </TracksVisiblityContext.Provider>
  );
};
