import React, { useEffect, useState } from "react";

const GuiSplitContext = React.createContext();
export default GuiSplitContext;

export const GuiSplitProvider = (props) => {
  const [guiSplit, setGuiSplit] = useState({
    details: false,
    profiles: false,
    rounds: false,
    expanded: false,
  });
  const [panel, setPanel] = useState({
    left: false,
    right: false,
    bottom: false,
  });

  useEffect(() => {
    console.log("guiSplit", guiSplit);
  }, [guiSplit]);

  return (
    <GuiSplitContext.Provider
      value={{
        guiSplit,
        setGuiSplit,
        panel,
        setPanel,
      }}
    >
      {props.children}
    </GuiSplitContext.Provider>
  );
};
