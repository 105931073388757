import React, { useContext, useEffect, useState } from "react";

import ConfigContext from "../../../Context/ConfigContext";
import TrackContext from "../../../Context/TrackContext";
import guiManager from "../../utils/guiManager";
import { Outlet, useNavigate } from "react-router-dom";
import Toolbar from "../../Trackalyze/Toolbar";
import Card from "../../Trackalyze/Card";
import Icon from "../../Trackalyze/Icon";
import Typo from "../../Trackalyze/Typo";
import Button from "../../Trackalyze/Button";
import Snackbar from "../../Trackalyze/Snackbar";
import Expandable from "../../Trackalyze/Expandable";
import ThemeSwitch from "./ThemeSwitch";

import { useTranslation } from "react-i18next";
import SettingsLanguages from "./SettingsLanguages";
import SettingsProfile from "./SettingsProfile";
import SettingsStorage from "./SettingsStorage";
import CardExpandable from "../Components/CardExpandable";

const Settings = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [removalFeedback, setRemovalFeedback] = useState(-1);

  const { profile } = useContext(ConfigContext);

  const goBack = () => {
    navigate("/");
  };

  return (
    <div className="ta-background ta-flex ta-flex--column">
      <Toolbar
        action={{ onClick: () => goBack(), symbol: "arrow_back" }}
        title={t("settings.title")}
      />

      <div className="ta-is-overflow ta-flexitem">
        <div className="ta-view ta-view--size-l ta-padding_s">
          {profile !== null && (
            <CardExpandable title={t("profile.title")} symbol="account_manage">
              <div className="ta-padding_leftright-xs">
                <SettingsProfile />
              </div>
            </CardExpandable>
          )}

          <Card className="ta-padding_xxs">
            <div className="ta-flex ta-flex--itemscenter ta-padding_topbottom-xxs ta-padding_right-xxxs">
              <Icon symbol="light_mode" className="ta-margin_leftright-xs" />
              <Typo className="ta-margin_left-xxs ta-flexitem">
                {t("settings.theme.title")}
              </Typo>
              <ThemeSwitch />
            </div>
          </Card>
          <CardExpandable title={t("settings.languages")} symbol="language">
            <div className="ta-padding_left-xl ta-padding_right-xxs">
              <SettingsLanguages />
            </div>
          </CardExpandable>
          <CardExpandable title={t("settings.storage.title")} symbol="storage">
            <div className="ta-padding_leftright-xs">
              <SettingsStorage />
            </div>
          </CardExpandable>

          <Card className="ta-padding_xxs">
            <div
              onClick={() => navigate("/settings/opensource")}
              className="ta-flex ta-flex--itemscenter"
            >
              <Icon symbol="description" className="ta-margin_leftright-xs" />
              <Typo className="ta-margin_left-xxs ta-flexitem">
                {t("settings.opensource.title")}
              </Typo>
              <Button icon symbol="keyboard_arrow_right" />
            </div>
          </Card>

          <Outlet />
          <div className="ta-flex ta-flex--column ta-flex--itemscenter ta-margin_top-s">
            <div className="ta-margin_topbottom-xs ta-flex ta-flex--column ta-flex--itemscenter">
              <Icon
                style={{
                  opacity: "0.75",
                  filter: "grayscale(.5)",
                }}
                symbol="logo"
                size="xxxl"
                className="ta-shape_xl"
              />
            </div>
            <Typo
              type="label"
              size="s"
              className="ta-flexitem ta-color-text_on-surface-lowest"
            >
              {t("general.app_name")} V3
            </Typo>
          </div>
          {removalFeedback === -2 &&
            guiManager(
              <Snackbar fixed animate timeOut>
                {t("settings.storage.snackbar_cache_cleared")}
              </Snackbar>
            )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Settings);
