import React, { useContext } from "react";

import TrackContext from "../../../../Context/TrackContext";
import Typo from "../../../Trackalyze/Typo";
import { useTranslation } from "react-i18next";

const TrackToolbarTitle = (props) => {
  const { t } = useTranslation();

  const { track } = useContext(TrackContext);
  return (
    <div className="ta-width_full ta-flexitem">
      <div className="ta-flex-ta-flex--itemscenter">
        <Typo
          inline
          type={"label"}
          size="s"
          className="ta-color-text_primary"
          noWrap
        >
          {track.$.activityType !== ""
            ? t("activities." + track.$.activityType)
            : "Actitvity"}
        </Typo>

        {"timeStart" in track.$ && (
          <Typo
            inline
            type="label"
            size="s"
            className="ta-margin_left-xxs ta-color-text_on-surface-lower"
          >
            {track.$.formatted.timeStart.dateTime}
          </Typo>
        )}
      </div>
      <Typo
        size="m"
        type={track !== undefined ? "title" : "title"}
        className={"" + (props.titleCenter ? " ta-is-center" : "")}
        noWrap={props.simple == undefined || !props.simple}
      >
        {track.$.activityName}
      </Typo>
    </div>
  );
};

export default React.memo(TrackToolbarTitle);
