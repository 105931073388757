import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import TrackContext from "../../../../Context/TrackContext";

import Dialog from "../../../Trackalyze/Dialog";
import DialogToolbar from "../../../Trackalyze/DialogToolbar";
import Divider from "../../../Trackalyze/Divider";
import Icon from "../../../Trackalyze/Icon";
import Typo from "../../../Trackalyze/Typo";
import {
  formatDistance,
  formatSecondsToHHMMSS,
} from "../../../utils/formatter";

const TracksInfoModal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { tracks } = useContext(TrackContext);
  const [tracksItems, setTracksItems] = useState([]);
  const [isRenderSheet, setIsRenderSheet] = useState(true);
  const [trackItems, setTrackItems] = useState([]);

  useEffect(() => {
    if (tracks !== undefined) {
      const items = [];
      for (let i = 0; i < tracks.length; i++) {
        items.push({ ...tracks[i], checked: false });
      }
      setTrackItems(items);
    } else {
      setTrackItems([]);
    }
  }, [tracks]);

  useEffect(() => {
    const tracksDetails = {};
    const tracksInfos = [];
    let dist = 0;
    let eleUp = 0;
    let eleDown = 0;
    let pace = 0;
    let speed = 0;
    let duration = 0;
    let timeCnt = 0;
    let heartrate = 0;
    let heartrateCnt = 0;

    for (let i = 0; i < trackItems.length; i++) {
      if ("distance" in trackItems[i]) {
        dist = dist + formatDistance(trackItems[i].distance).distanceKm;
      }

      if ("elevationUp" in trackItems[i]) {
        eleUp = eleUp + parseFloat(trackItems[i].elevationUp);
        eleDown = eleDown + parseFloat(trackItems[i].elevationDown);
      }

      if ("durationSeconds" in trackItems[i]) {
        pace = pace + parseFloat(trackItems[i].paceAvg);
        speed = speed + parseFloat(trackItems[i].speedAvg);
        duration = duration + trackItems[i].durationSeconds;
        timeCnt++;
      }

      if ("heartrateAvg" in trackItems[i]) {
        heartrate = heartrate + parseFloat(trackItems[i].heartrateAvg);
        heartrateCnt++;
      }
    }

    tracksDetails.duration = formatSecondsToHHMMSS(duration);

    tracksInfos.push({
      label: t("track.time_duration"),
      symbol: "duration",
      fill: "duration",
      value: formatSecondsToHHMMSS(duration),
      disabled: !(duration > 0),
    });

    tracksInfos.push({
      label: t("track.distance"),
      symbol: "distance",
      fill: "distance",
      value: dist.toFixed(2),
      unit: t("units.distance"),
    });
    tracksInfos.push(
      {
        label: t("track.elevation_up"),
        symbol: "elevation_up",
        fill: "elevation",
        value: eleUp,
        unit: t("units.elevation"),
        disabled: !(eleUp > 0),
      },
      {
        label: t("track.elevation_down"),
        symbol: "elevation_down",
        fill: "elevation",
        value: eleDown,
        unit: t("units.elevation"),
        disabled: !(eleUp > 0),
      }
    );

    tracksInfos.push({
      label: t("track.pace"),
      symbol: "pace",
      fill: "pace",
      value: pace > 0 ? (pace / timeCnt).toFixed(2) : "--",
      unit: t("units.pace"),
      disabled: !(pace > 0),
    });
    tracksInfos.push({
      label: t("track.speed"),
      symbol: "speed",
      fill: "speed",
      value: speed > 0 ? (speed / timeCnt).toFixed(2) : "--",
      unit: t("units.speed"),
      disabled: !(speed > 0),
    });

    tracksInfos.push({
      label: t("track.heartrate"),
      symbol: "heartrate",
      fill: "heartrate",
      value: (heartrate / timeCnt).toFixed(2),
      unit: t("units.heartrate"),
      disabled: !(heartrate > 0),
    });

    setTracksItems(tracksInfos);
  }, [trackItems]);

  const goBack = () => {
    if (isRenderSheet) {
      setIsRenderSheet(false);
      window.setTimeout(() => {
        navigate(-1);
      }, 100);
    }
  };
  return (
    <Dialog
      zIndex={2600}
      animate={isRenderSheet}
      onAfterAnimate={() => {
        goBack();
      }}
      className="ta-flex ta-flex--column"
    >
      <DialogToolbar
        title={t("tracksinfo.title", { count: trackItems.length })}
        symbol="info"
      />
      <div className="ta-flexitem ta-is-overflow">
        {tracksItems.map((item, idx) => {
          return (
            <React.Fragment key={"tracksinfolist_" + idx}>
              <div
                className={
                  "ta-flex ta-flex--itemscenter ta-margin_topbottom-s" +
                  (item.disabled ? " ta-is-disabled" : "")
                }
              >
                <Icon
                  symbol={item.symbol}
                  className={"ta-margin_right-s details-color_" + item.fill}
                />
                <Typo
                  size="m"
                  className="ta-margin_left-xxxs ta-color-text_on-surface-low ta-flexitem"
                >
                  {item.label}
                </Typo>
                <Typo size="m">
                  {item.value}&nbsp;
                  <span className="ta-color-text_on-surface-low">
                    {item.unit}
                  </span>
                </Typo>
              </div>
              {idx < tracksItems.length - 1 && <Divider />}
            </React.Fragment>
          );
        })}
      </div>
    </Dialog>
  );
};

export default React.memo(TracksInfoModal);
