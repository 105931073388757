import React from "react";

const Badge = (props) => {
  const buildClasses = () => {
    const classes = ["ta-badge"];
    props.className && classes.push(props.className);

    props.small && classes.push("ta-badge--small");

    return classes.join(" ");
  };

  return (
    <div style={props.style} className={buildClasses()}>
      {props.children}
    </div>
  );
};

export default Badge;
