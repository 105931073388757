import React, { useContext, useState } from "react";

import TrackContext from "../../../../Context/TrackContext";
import { useNavigate } from "react-router-dom";
import BottomSheet from "../../../Trackalyze/BottomSheet";
import VisibilityOverlays from "./VisibilityOverlays";
import Dialog from "../../../Trackalyze/Dialog";
import DeviceContext from "../../../../Context/DeviceContext";
import DialogToolbar from "../../../Trackalyze/DialogToolbar";
import { useTranslation } from "react-i18next";
import Divider from "../../../Trackalyze/Divider";
import MapTypes from "./MapTypes";
import Typo from "../../../Trackalyze/Typo";

const VisibilityModal = () => {
  const { t } = useTranslation();
  const { windowWidth } = useContext(DeviceContext);

  const [isRenderSheet, setIsRenderSheet] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    if (isRenderSheet) {
      setIsRenderSheet(false);
      navigate(-1);
    }
  };

  return windowWidth.expanded_ ? (
    <Dialog
      id={"bsVisibility"}
      zIndex={2400}
      animate={isRenderSheet}
      onAfterAnimate={() => {
        goBack();
      }}
      className="overlays_dialog"
    >
      <DialogToolbar title={t("mapoverlay.title")} symbol="layers" />

      <Divider />
      <div className="ta-margin_s">
        <Typo className="ta-margin_bottom-s">{t("mapoverlay.title_maps")}</Typo>
        <MapTypes callBack={() => goBack()} />
      </div>

      <VisibilityOverlays goBack={() => goBack()} />
    </Dialog>
  ) : (
    <BottomSheet
      id={"bsVisibility"}
      zIndex={2400}
      animate={isRenderSheet}
      onAfterAnimate={() => {
        goBack();
      }}
    >
      <div className="ta-margin_s">
        <Typo className="ta-margin_bottom-s">{t("mapoverlay.title_maps")}</Typo>
        <MapTypes callBack={() => goBack()} />
      </div>
      <VisibilityOverlays goBack={() => goBack()} />
    </BottomSheet>
  );
};

export default React.memo(VisibilityModal);
