import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import DeviceContext from "../../../Context/DeviceContext";
import Dialog from "../../Trackalyze/Dialog";
import DialogToolbar from "../../Trackalyze/DialogToolbar";
import Toolbar from "../../Trackalyze/Toolbar";
import LabelsWrapper from "../MapWrapper/GuiMap/LabelsWrapper";

const LabelsModal = () => {
  const { t } = useTranslation();

  const { windowWidth } = useContext(DeviceContext);

  const [isRenderSheet, setIsRenderSheet] = useState(true);
  const navigate = useNavigate();

  const goBack = () => {
    if (isRenderSheet) {
      setIsRenderSheet(false);
      window.setTimeout(() => {
        navigate(-1);
      }, 100);
    }
  };

  return windowWidth.expanded ? (
    <Dialog
      onAfterAnimate={() => goBack()}
      className="ta-padding_0"
      animate={isRenderSheet}
      zIndex={2400}
    >
      <LabelsWrapper
        onDone={() => {
          goBack();
        }}
      />
    </Dialog>
  ) : (
    <Dialog
      onAfterAnimate={() => goBack()}
      animate={isRenderSheet}
      fullScreen="slideFromBottom"
      zIndex={2400}
    >
      <LabelsWrapper
        onDone={() => {
          goBack();
        }}
      />
    </Dialog>
  );
};

export default React.memo(LabelsModal);
