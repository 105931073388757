import React, { useContext } from "react";

import TrackContext from "../../../../Context/TrackContext";
import Button from "../../../Trackalyze/Button";

const TrackPreviousButton = (props) => {
  const { tracks, trackId, setTrackId } = useContext(TrackContext);

  const prevTrack = () => {
    let newId = 0;
    for (let i = 0; i < tracks.length; i++) {
      if (tracks[i].id === trackId) {
        newId = i - 1;
        if (newId < 0) {
          newId = tracks.length - 1;
        }
      }
    }
    setTrackId(tracks[newId].id);
  };

  return (
    <Button
      icon
      text
      disabled={props.disabled || tracks.length < 2}
      symbol="keyboard_arrow_left"
      onClick={() => {
        console.log("this.onclick");
        prevTrack();
      }}
      {...props}
    />
  );
};

export default React.memo(TrackPreviousButton);
