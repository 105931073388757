import React, { useEffect, useState } from "react";

const Scrim = (props) => {
  const [animate, setAnimate] = useState(false);

  const buildClasses = () => {
    const classes = ["ta-scrim"];
    props.className && classes.push(props.className);
    props.transparent && classes.push("ta-scrim--transparent");

    return classes.join(" ");
  };

  useEffect(() => {
    if (props.animate !== animate && props.animate === false) {
      setAnimate(false);
      window.setTimeout(() => {
        props.onClick();
      }, 100);
    } else if (props.animate === true) {
      setAnimate(true);
    }
  }, [props.animate]);

  return (
    <div
      className={`${buildClasses()} ${animate ? " ta-scrim--animate" : ""}`}
      onClick={(event) => {
        event.stopPropagation();
        props.onClick();
      }}
      style={props.zIndex ? { zIndex: props.zIndex + 1 } : {}}
    >
      {props.children}
    </div>
  );
};

export default Scrim;
