import React from "react";

import Icon from "../../../Trackalyze/Icon";
import OptionsButton from "./OptionsButton";
import Typo from "../../../Trackalyze/Typo";
import { useTranslation } from "react-i18next";
import SettingsButton from "./SettingsButton";
import TracksImportButton from "./TracksImportButton";

const AppToolbar = (props) => {
  const { t } = useTranslation();
  return (
    <div className="ta-shape_l ta-elevation_1 ta-padding_xxxs ta-flex ta-flex--out ta-flex--itemscenter">
      <div className="ta-flex ta-flex--itemscenter">
        <Icon
          className="ta-margin_left-xxs ta-margin_right-s ta-color-text_on-primary ta-color_primary ta-shape_m"
          symbol="app_logo"
          size="l"
        />
        <Typo type="title">{t("general.app_name")}</Typo>
      </div>
      <div className="ta-margin_left-m">
        <TracksImportButton simple />
      </div>
    </div>
  );
};

export default React.memo(AppToolbar);
