import React, { useContext, useEffect, useState } from "react";

import ActivityTypeIcon from "../../MapWrapper/GuiMap/ActivityTypeIcon";
import TrackContext from "../../../../Context/TrackContext";
import { updateFavorite } from "../../../utils/localStorage";
import {
  formatDateTime,
  formatDistance,
  formatDuration,
} from "../../../utils/formatter";
import Typo from "../../../Trackalyze/Typo";
import Button from "../../../Trackalyze/Button";
import Icon from "../../../Trackalyze/Icon";
import Divider from "../../../Trackalyze/Divider";
import { useTranslation } from "react-i18next";

const TracksList = (props) => {
  const { t } = useTranslation();

  const { setUpdateTracks, trackId } = useContext(TrackContext);
  const [listItems, setListItems] = useState([]);
  useEffect(() => {
    setListItems(props.trackItems);
  }, [props]);

  let entryDownTimer = 0;

  const toggleItemDelete = (id) => {
    props.itemToggle(id);
  };

  const handleTouchStart = (id) => {
    // isClick = false;
    entryDownTimer = 1;
    window.setTimeout(() => {
      //
      if (entryDownTimer > 0) {
        props.itemToggle(id);
        entryDownTimer = 0;
      }
    }, 500);
  };

  const handleTouchEnd = (e) => {
    // isClick = false;
    entryDownTimer = 0;
  };

  const handleTouchMove = (e) => {
    // isClick = false;

    entryDownTimer = 0;
  };

  const onTouchHold = () => {
    entryDownTimer = parseInt((new Date().getTime() - entryDownTimer) / 1000);
  };

  return (
    <div className="ta-padding_xxxs">
      {listItems.map((trck, idx) => (
        <React.Fragment key={"tracksItem_" + idx}>
          <div
            id={"tracksItem_" + idx}
            className={
              "ta-flex ta-flex--row ta-flex--itemscenter ta-padding_topbottom-xxs ta-margin_topbottom-xxs ta-no-overflow scroll-snap_item  " +
              (trck.id === trackId
                ? " ta-color_primary-container ta-shape_l"
                : "")
            }
          >
            {trck.checked ? (
              <Button
                icon
                text
                symbol="check_circle"
                onClick={() => toggleItemDelete(trck.id)}
              />
            ) : (
              <Button
                pure
                onClick={() => toggleItemDelete(trck.id)}
                className={
                  trck.activityType === "" ? "ta-padding_leftright-xxs" : ""
                }
              >
                {trck.activityType !== "" ? (
                  <ActivityTypeIcon
                    simple
                    className={
                      "ta-margin_xxs " +
                      (trck.activityType !== ""
                        ? "ta-color-text_primary"
                        : "ta-color-text_on-surface-lowest")
                    }
                    type={trck.activityType}
                  />
                ) : (
                  <Icon
                    symbol="help"
                    className="ta-color-text_on-surface-lower"
                  />
                )}
              </Button>
            )}

            <div className="ta-padding_left-xxs ta-flexitem">
              <div className="ta-flex ta-flex--out ta-flex--itemscenter">
                <div
                  className="ta-is-singleline ta-flex ta-flex--column"
                  onClick={() => {
                    props.edit
                      ? props.itemToggle(trck.id)
                      : props.callBack(trck.id);
                  }}
                  onContextMenu={(e) => e.preventDefault()}
                  onTouchStart={() => handleTouchStart(trck.id)}
                  onTouchEnd={handleTouchEnd}
                  onTouchMove={handleTouchMove}
                >
                  {"timeStart" in trck && (
                    <Typo
                      size="s"
                      className="ta-color-text_on-surface-lower ta-flex ta-flex--itemscenter"
                    >
                      {formatDateTime(trck.timeStart).dateTime}
                    </Typo>
                  )}

                  <Typo noWrap className="ta-flexitem">
                    {idx + 1}. {trck.activityName}
                  </Typo>
                  <Typo
                    size="s"
                    noWrap
                    className="ta-color-text_on-surface-low ta-flex ta-flex--itemscenter"
                  >
                    {formatDistance(trck.distance).distanceKm}
                    <span className="ta-color-text_on-surface-lower">
                      &nbsp;{t("units.distance")}
                    </span>
                    {"timeStart" in trck && (
                      <>
                        &nbsp;in&nbsp;
                        {formatDuration(trck.durationSeconds).duration}
                      </>
                    )}
                    {"paceAvg" in trck && (
                      <div className="ta-margin_left-xxs ta-flex ta-flex--itemscenter">
                        <Icon
                          className="details-color_pace ta-margin_right-xxxs"
                          size="xs"
                          symbol="pace"
                        />
                        {trck.paceAvg}&nbsp;
                        <div className="ta-color-text_on-surface-lower">
                          {t("units.pace")}
                        </div>
                      </div>
                    )}

                    {"elevationUp" in trck && trck.elevationUp > 0 && (
                      <div className="ta-margin_left-xxs ta-flex ta-flex--itemscenter">
                        <Icon
                          className="details-color_elevation ta-margin_right-xxxs"
                          symbol="elevation_up"
                          size="xs"
                        />
                        {trck.elevationUp} &nbsp;
                        <span className="ta-color-text_on-surface-lower">
                          {t("units.elevation")}
                        </span>
                      </div>
                    )}
                    {"heartrateAvg" in trck && (
                      <div className="ta-margin_left-xxs ta-flex ta-flex--itemscenter">
                        <Icon
                          className="details-color_heartrate ta-margin_right-xxxs"
                          symbol="heartrate"
                          size="xs"
                        />
                        {trck.heartrateAvg}&nbsp;
                        <span className="ta-color-text_on-surface-lower">
                          {t("units.heartrate")}
                        </span>
                      </div>
                    )}
                  </Typo>
                </div>
                <Button
                  onClick={() => {
                    updateFavorite(trck.id, !trck.favorite);
                    setUpdateTracks(true);
                  }}
                  text={trck.favorite}
                  symbol={trck.favorite ? "star" : "star_border"}
                  icon
                  className="ta-color-text_on-surface-lower"
                />
              </div>
            </div>
          </div>
          {idx < props.trackItems.length - 1 && (
            <Divider
              style={{ width: "calc(100% - 56px)", marginLeft: "56px" }}
              key={"divider_" + idx}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(TracksList);
