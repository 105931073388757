import React from "react";

const Card = (props) => {
  const buildClasses = () => {
    const classes = ["ta-card"];

    props.className && classes.push(props.className);
    props.elevation &&
      !props.noFill &&
      classes.push("ta-elevation_" + props.elevation);

    !props.elevation && !props.noFill && classes.push("ta-elevation_1");

    return classes.join(" ");
  };

  return (
    <div className={buildClasses()} style={props.style}>
      {props.children}
    </div>
  );
};

export default Card;
