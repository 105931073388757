import React, { useContext } from "react";

import LocationButton from "./LocationButton";
import VisibilityButton from "./VisibilityButton";
import TrackFitsButton from "./TrackFitsButton";
import TrackContext from "../../../../Context/TrackContext";

import LocationContext from "../../../../Context/LocationContext";
import PositionAbsolute from "../../../Trackalyze/patterns/PositionAbsolute";
import AppToolbar from "./AppToolbar";
import VisibilityOptionsButton from "./VisibilityOptionsButton";
import SettingsButton from "./SettingsButton";
import DeviceContext from "../../../../Context/DeviceContext";
import DetailsHeader from "../../Components/Details/DetailsHeader";
import GuiSplitContext from "../../../../Context/GuiSplitContext";
import TrackMarkerVisibilityLarge from "./TrackMarkerVisibilityLarge";
import RoundsSelectionButton from "./RoundsSelectionButton";
import { useTranslation } from "react-i18next";
import MainNavigationTrack from "./MainNavigationTrack";

const GuiMapPortrait = (props) => {
  const { t } = useTranslation();

  const { guiSplit } = useContext(GuiSplitContext);

  const { isDevicePortrait, isDeviceTablet } = useContext(DeviceContext);

  const { track, tracks } = useContext(TrackContext);
  const { showMyLocation } = useContext(LocationContext);

  return (
    <div
      id="trackmapGui"
      className="ta-flex ta-width_full ta-height_full ta-flex--column ta-is-absolute"
    >
      <div className="ta-margin_xxs ta-is-relative ta-flexitem">
        {track !== undefined && !isDevicePortrait && (
          <PositionAbsolute top left style={{ maxWidth: "calc(100% - 56px)" }}>
            {!guiSplit.details && !guiSplit.rounds && !guiSplit.profiles && (
              <div className="ta-flex ta-flex--column ta-elevation_1 ta-elevation_shadow-1 ta-shape_m">
                <DetailsHeader />
                <MainNavigationTrack simple />
              </div>
            )}
          </PositionAbsolute>
        )}

        {track !== undefined && !isDevicePortrait && (
          <PositionAbsolute top left style={{ maxWidth: "calc(100% - 56px)" }}>
            {!guiSplit.details &&
              !guiSplit.rounds &&
              guiSplit.profiles &&
              isDeviceTablet && (
                <div className="ta-flex ta-flex--column ta-elevation_1 ta-elevation_shadow-1 ta-shape_m">
                  <DetailsHeader />
                  <MainNavigationTrack simple />
                </div>
              )}
          </PositionAbsolute>
        )}

        {tracks.length < 1 && (
          <PositionAbsolute top left>
            <AppToolbar />
          </PositionAbsolute>
        )}

        <PositionAbsolute top right>
          <div className="ta-flex ta-flex--column">
            <SettingsButton semi elevation />

            <VisibilityOptionsButton
              tonal={false}
              semi
              elevation
              text={false}
              track={track !== undefined}
            />
          </div>
        </PositionAbsolute>

        <PositionAbsolute bottom center className="ta-width_full">
          <div className="ta-flex ta-flex--column">
            <div className="ta-flex ta-flex--itemscenter ta-flex--out  ta-margin_bottom-xxs">
              {tracks.length > 0 && (
                <>
                  <div className="ta-flex ta-flexitem ta-flex--itemscenter">
                    <TrackFitsButton tonal={false} semi />
                    <div className="markervisibility" style={{ zIndex: 2 }}>
                      {track !== undefined && (
                        <div className="ta-flex">
                          <RoundsSelectionButton />
                          <VisibilityButton
                            tonal={false}
                            semi
                            className="markervisibility_button"
                          />
                          <TrackMarkerVisibilityLarge />
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
              {(showMyLocation || true) && (
                <>
                  <div></div>
                  <LocationButton
                    tonal={false}
                    semi
                    mapLocation={props.mapLocation}
                    callBack={(value) =>
                      props.updateMapLocation({ ...value, showMarker: true })
                    }
                  />
                </>
              )}
            </div>
            {track !== undefined &&
              isDevicePortrait &&
              !guiSplit.tracks &&
              !guiSplit.details &&
              !guiSplit.profiles &&
              !guiSplit.rounds && (
                <div className="ta-elevation_1 ta-elevation--shadow ta-shape_l">
                  <DetailsHeader />
                  <MainNavigationTrack simple />
                </div>
              )}
          </div>
        </PositionAbsolute>
      </div>
    </div>
  );
};

export default React.memo(GuiMapPortrait);
