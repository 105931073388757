import React, { useContext, useState } from "react";
import TrackContext from "../../../../Context/TrackContext";
import Chip from "../../../Trackalyze/Chip";

const TrackLabelsList = (props) => {
  const { track, trackLabels } = useContext(TrackContext);

  return (
    <>
      {track !== undefined && "favorite" in track.$ && track.$.favorite && (
        <Chip small secondary symbol="star">
          Favorite
        </Chip>
      )}

      {trackLabels.map((label, idx) => {
        return (
          <Chip
            input={props.input ? "delete_circle" : ""}
            small
            secondary
            symbol="label"
            onClick={() => props.callBack()}
          >
            {label.label}
          </Chip>
        );
      })}
    </>
  );
};

export default React.memo(TrackLabelsList);
