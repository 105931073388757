import React, { useState } from "react";

import SelectedTracksOptions from "./SelectedTracksOptions";
import guiManager from "../../../utils/guiManager";
import Dialog from "../../../Trackalyze/Dialog";
import Toolbar from "../../../Trackalyze/Toolbar";
import Button from "../../../Trackalyze/Button";
import Typo from "../../../Trackalyze/Typo";
import RadioButton from "../../../Trackalyze/RadioButton";
import Divider from "../../../Trackalyze/Divider";
import DialogToolbar from "../../../Trackalyze/DialogToolbar";
import { useTranslation } from "react-i18next";

const TracksSelectionToolbar = (props) => {
  const { t } = useTranslation();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [animateConfirm, setAnimateConfirm] = useState(false);
  const [showFavoritesModal, setShowFavoritesModal] = useState(false);
  const [animateFavorites, setAnimateFavorites] = useState(false);
  const [favAction, setFavAction] = useState("favor");

  return (
    <>
      <Toolbar
        title={t("trackmanager.selection_title", { count: props.itemsCount })}
        action={{
          symbol: "cancel",
          onClick: () => props.onClose(),
        }}
      >
        <div className="ta-flex ta-flex--itemscenter">
          <Button
            icon
            iconProps={
              showConfirmModal ? {} : { className: "ta-color-text_error" }
            }
            symbol="delete"
            error={showConfirmModal}
            onClick={() => {
              setShowConfirmModal(true);
              setAnimateConfirm(true);
            }}
          />
          <Button
            icon
            symbol="star"
            tertiary={showFavoritesModal}
            onClick={() => {
              setShowFavoritesModal(true);
              setAnimateFavorites(true);
            }}
          />

          <SelectedTracksOptions
            invertSelectedItems={() => props.invertSelection()}
            selectAllItems={() => props.selectAll()}
            unselectItems={() => props.removeSelection()}
          />
        </div>
      </Toolbar>

      {showConfirmModal &&
        guiManager(
          <Dialog
            onAfterAnimate={() => setShowConfirmModal(false)}
            animate={animateConfirm}
            zIndex={2600}
          >
            <DialogToolbar
              symbol="delete"
              title={t("trackmanager.delete_title")}
            />
            <div className="ta-margin_bottom-m">
              <Typo>
                {t("trackmanager.delete_info", {
                  count: props.itemsCount,
                })}
              </Typo>
            </div>
            <div className="ta-flex ta-flex--right">
              <Button onClick={() => setAnimateConfirm(false)}>
                {t("trackmanager.delete_button_cancel")}
              </Button>
              <Button
                error
                onClick={() => {
                  setAnimateConfirm(false);
                  props.removeFromStorage();
                }}
              >
                {t("trackmanager.delete_button_confirm")}
              </Button>
            </div>
          </Dialog>
        )}
      {showFavoritesModal &&
        guiManager(
          <Dialog
            onAfterAnimate={() => setShowFavoritesModal(false)}
            animate={animateFavorites}
            zIndex={2600}
          >
            <DialogToolbar
              symbol="star"
              title={t("trackmanager.favorites_title")}
            />
            <div className="ta-is-center">
              <Typo>
                {t("trackmanager.favorites_info", { count: props.itemsCount })}
              </Typo>
              <Divider className="ta-margin_topbottom-s" />

              <div className="ta-margin_top-">
                <div
                  className="ta-flex ta-flex--itemscenter"
                  onClick={(value) => setFavAction("favor")}
                >
                  <RadioButton
                    name="favaction"
                    value="favor"
                    checked={favAction}
                    callBack={() => setFavAction("favor")}
                  />
                  <Typo className="ta-margin_left-xxxs">
                    {t("trackmanager.favorites_option_favor")}
                  </Typo>
                </div>
                <div
                  className="ta-flex ta-flex--itemscenter"
                  onClick={(value) => setFavAction("flip")}
                >
                  <RadioButton
                    name="favaction"
                    value="flip"
                    checked={favAction}
                    callBack={() => setFavAction("flip")}
                  />
                  <Typo className="ta-margin_left-xxxs">
                    {t("trackmanager.favorites_option_flip")}
                  </Typo>
                </div>
                <div
                  className="ta-flex ta-flex--itemscenter"
                  onClick={(value) => setFavAction("unfavor ")}
                >
                  <RadioButton
                    name="favaction"
                    value="unfavor"
                    checked={favAction}
                    callBack={() => setFavAction("unfavor")}
                  />
                  <Typo className="ta-margin_left-xxxs">
                    {t("trackmanager.favorites_option_unfavor")}
                  </Typo>
                </div>
              </div>
            </div>
            <Divider className="ta-margin_topbottom-xxs" />
            <div className="ta-flex ta-flex--right">
              <Button text onClick={() => setAnimateFavorites(false)}>
                {t("trackmanager.favorites_button_cancel")}
              </Button>
              <Button
                text
                onClick={() => {
                  setAnimateFavorites(false);
                  props.setFavoritesToStorage(favAction);
                }}
              >
                {t("trackmanager.favorites_button_confirm")}
              </Button>
            </div>
          </Dialog>
        )}
    </>
  );
};

export default TracksSelectionToolbar;
