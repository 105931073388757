const sortTrackItems = (value, trackItems) => {
  console.log("sortTrackItems value", value);
  console.log("sortTrackItems trackItems", trackItems);
  let sortedItems = [];
  if (trackItems.length > 0) {
    sortedItems = trackItems.sort((a, b) => {
      console.log("sortedItems value", value);
      console.log("sortedItems a", a);
      console.log("sortedItems b", b);
      // sort by name
      if (value.orderBy === "activityName") {
        if (value.direction === "asc") {
          if (a.activityName.toUpperCase() > b.activityName.toUpperCase())
            return 1;
          if (a.activityName.toUpperCase() < b.activityName.toUpperCase())
            return -1;
        } else {
          if (a.activityName.toUpperCase() < b.activityName.toUpperCase())
            return 1;
          if (a.activityName.toUpperCase() > b.activityName.toUpperCase())
            return -1;
        }
      }
      console.log("sortedItems", sortedItems);

      // sort by type
      if (value.orderBy === "activityType") {
        if (value.direction === "asc") {
          if (a.activityType > b.activityType) return 1;
          if (a.activityType < b.activityType) return -1;
        } else {
          if (a.activityType < b.activityType) return 1;
          if (a.activityType > b.activityType) return -1;
        }
      }

      // sort by distance
      if (value.orderBy === "distance") {
        if (value.direction === "asc") {
          return a.distance - b.distance;
        } else {
          return b.distance - a.distance;
        }
      }

      // sort by duration
      if (value.orderBy === "durationSeconds") {
        if (value.direction === "asc") {
          return a.durationSeconds - b.durationSeconds;
        } else {
          return b.durationSeconds - a.durationSeconds;
        }
      }

      // sort by elevation up
      if (value.orderBy === "elevationUp") {
        if (value.direction === "asc") {
          return a.elevationUp - b.elevationUp;
        } else {
          return b.elevationUp - a.elevationUp;
        }
      }

      // sort by upload date
      if (value.orderBy === "fileUploadedTime") {
        if (value.direction === "asc") {
          return a.fileUploadedTime - b.fileUploadedTime;
        } else {
          return b.fileUploadedTime - a.fileUploadedTime;
        }
      }

      // sort by start date
      if (value.orderBy === "activityStartTime") {
        if (value.direction === "asc") {
          return a.activityStartTime - b.activityStartTime;
        } else {
          return b.activityStartTime - a.activityStartTime;
        }
      }

      // sort by favorite

      if (value.orderBy === "favorite") {
        if (value.direction === "asc") {
          return a.favorite - b.favorite;
        } else {
          return b.favorite - a.favorite;
        }
      }
    });
    console.log("sortTrackItems sortedItems", sortedItems);
    const sortedNewItems = [];
    for (let i = 0; i < sortedItems.length; i++) {
      sortedNewItems.push(sortedItems[i]);
    }
    // setTrackItems(sortedNewItems);
    return sortedNewItems;
  }
};
export default sortTrackItems;
