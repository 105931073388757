import React, { useContext } from "react";

import TrackContext from "../../../../Context/TrackContext";
import Typo from "../../../Trackalyze/Typo";
import Icon from "../../../Trackalyze/Icon";
import { useTranslation } from "react-i18next";

const TrackToolbarStatistics = (props) => {
  const { t } = useTranslation();

  const { track } = useContext(TrackContext);
  console.log("track.$", track.$);
  return (
    <Typo
      type="label"
      size="s"
      block
      sHigh
      secondary
      className={
        "ta-flex ta-flex--center_ ta-flex--itemscenter ta-no-wrap ta-color-text_on-surface-low ta-no-overflow " +
        (props.titleCenter ? " ta-flex--center" : "")
      }
    >
      {/*       <Icon
        symbol="distance"
        size="s"
        className="ta-margin_right-xxxs details-color_distance"
      /> */}
      {track.$.formatted.distanceKm} {t("units.distance")}
      {"timeStart" in track.$ && (
        <>
          <span className="ta-color-text_on-surface-lower">&nbsp;in&nbsp;</span>
          {track.$.formatted.duration.durationFixed}
        </>
      )}
      {"elevationUp" in track.$ && track.$.elevationUp > 0 && (
        <>
          <Icon
            symbol="elevation_up"
            size="s"
            className="ta-margin_left-xxs ta-margin_right-xxxs details-color_elevation"
          />
          {track.$.elevationUp} {t("units.elevation")}
        </>
      )}
      {"paceAvg" in track.$ && (
        <>
          <Icon
            symbol="pace"
            size="s"
            className="ta-margin_left-xxs ta-margin_right-xxxs details-color_pace"
          />
          {track.$.paceAvg} {t("units.pace")}
        </>
      )}
      {/*             {"speedAvg" in track.$ && (
              <>
                <Icon
                  symbol="speed"
                  size="s"
                  className="ta-margin_left-xxs ta-margin_right-xxxs details-color_speed"
                />
                {track.$.speedAvg} {t("units.speed")}
              </>
            )}
       */}
      {"heartrateAvg" in track.$ && (
        <>
          <Icon
            symbol="heartrate"
            size="s"
            className="ta-margin_left-xxs ta-margin_right-xxxs details-color_heartrate"
          />
          {track.$.heartrateAvg} {t("units.heartrate")}
        </>
      )}
    </Typo>
  );
};

export default React.memo(TrackToolbarStatistics);
