import React from "react";
import Icon from "./Icon";
import Typo from "./Typo";

const DialogToolbar = (props) => {
  const buildClasses = () => {
    const classes = ["ta-is-center ta-margin_bottom-m"];

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <div className="ta-is-center">
      {props.symbol && (
        <Icon
          symbol={props.symbol}
          className="ta-margin_bottom-m ta-color-text_on-surface-lower"
          size="m"
        />
      )}

      <Typo
        noWrap={!props.symbol}
        type="headline"
        size="s"
        className={buildClasses()}
        style={props.style}
      >
        {props.title}
      </Typo>
    </div>
  );
};

export default DialogToolbar;
