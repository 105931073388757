const configProfile = {
  step: 76.2,
  age: 35,
  height: 172,
  weight: 75,
  gender: "male",
  hrMax: 185,
};

export default configProfile;
