import React, { useContext } from "react";

import TrackContext from "../../../../Context/TrackContext";
import MapLabelContext from "../../../../Context/MapLabelContext";
import { formatDistance, formatDateTime } from "../../../utils/formatter";
import Typo from "../../../Trackalyze/Typo";

const BreaksList = () => {
  const { track } = useContext(TrackContext);
  const { setTrackPoint } = useContext(MapLabelContext);
  return track !== undefined && "markerStops" in track.$ ? (
    <>
      <div className="ta-flexitem ta-is-overflow ta-margin_leftright-xxxs">
        <Typo size="s">
          <table className="ta-is-relative ta-table ta-table--dense">
            <thead className="rounds_tableheader ta-color_surface-container">
              <tr>
                <th className="rounds_tablefirst ta-color_surface-container">
                  Nr.
                </th>
                <th>Distance</th>
                <th>Time</th>
                <th>Duration</th>
                <th>Elevation</th>
                <th>Heartrate</th>
              </tr>
              <tr>
                <th className="rounds_cell rounds_tablefirst ta-color_surface-container">
                  <div className="ta-color-text_on-surface-lower"></div>
                </th>
                <th className="rounds_cell ta-color_surface-container rounds_tableheader">
                  <div className="ta-color-text_on-surface-lower">km</div>
                </th>
                <th className="rounds_cell ta-color_surface-container rounds_tableheader">
                  <div className="ta-color-text_on-surface-lower">hh/mm/ss</div>
                </th>
                <th className="rounds_cell ta-color_surface-container rounds_tableheader">
                  <div className="ta-color-text_on-surface-lower">minutes</div>
                </th>
                <th className="rounds_cell ta-color_surface-container rounds_tableheader">
                  <div className="ta-color-text_on-surface-lower">m</div>
                </th>
                <th className="rounds_cell ta-color_surface-container rounds_tableheader">
                  <div className="ta-color-text_on-surface-lower">bpm</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {track.$.markerStops.map((brk, idx) => {
                let breakClass = "rounds_cell";

                return (
                  <tr
                    key={idx}
                    onClick={() => {
                      setTrackPoint({
                        ...brk,
                        latlong: { lat: brk.$.lat, lng: brk.$.lng },
                      });
                    }}
                  >
                    {/*                 <td className={breakClass}>{idx + 1}</td>
                     */}
                    <td
                      className={
                        breakClass +
                        " rounds_tablefirst ta-color_surface-container "
                      }
                    >
                      {idx + 1}
                    </td>
                    <td className={breakClass}>
                      {formatDistance(brk.$.distance).distanceKm}
                    </td>
                    <td className={breakClass}>
                      {formatDateTime(brk.$.time).time}
                    </td>

                    <td className={breakClass}>{brk.$.durationMinutes}</td>
                    <td className={breakClass}>{parseInt(brk.$.ele)}</td>
                    <td className={breakClass}>{brk.$.hr}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Typo>
      </div>
    </>
  ) : (
    <div></div>
  );
};

export default BreaksList;
