import React, { useEffect, useState } from "react";

import Typo from "../../Trackalyze/Typo";
import RadioButton from "../../Trackalyze/RadioButton";

import { useTranslation } from "react-i18next";

const SettingsLanguages = () => {
  const { t, i18n } = useTranslation();

  const [languages, setLanguages] = useState([]);

  useEffect(() => {
    setLanguages(i18n.options.languages);
  }, []);

  const switchLanguage = (lng) => {
    console.log("switchLanguage", lng);
    i18n.changeLanguage(lng);
    localStorage.setItem("language", JSON.stringify(lng));
  };

  return languages.map((lng, idx) => {
    return (
      <div
        onClick={() => switchLanguage(lng.id)}
        className="ta-flex ta-flex--out ta-flex--itemscenter"
      >
        <div className="ta-flex ta-flex--itemscenter">
          <img
            src={"/icons/flag_" + lng.id + ".png"}
            width="24"
            className="ta-margin_left-xs ta-shape_xl ta-aspect_1"
          />
          <Typo size="m" className="ta-margin_left-m">
            {lng.label}
          </Typo>
        </div>
        <RadioButton name="language" value={lng.id} checked={i18n.language} />
      </div>
    );
  });
};

export default React.memo(SettingsLanguages);
