import Icon from "./Icon";
import React from "react";

const Chip = (props) => {
  const buildClasses = () => {
    const classes = [];
    !props.pure && classes.push("ta-chip");
    !props.error &&
      !props.primary &&
      !props.secondary &&
      !props.outline &&
      classes.push("ta-surface ta-surface--1");
    props.disabled && classes.push("ta-is-disabled");
    props.primary && classes.push("ta-chip--primary");
    props.secondary && classes.push("ta-chip--secondary");
    props.active && classes.push("ta-chip--active");
    props.outline && classes.push("ta-chip--outline");
    props.small && classes.push("ta-chip--small");
    props.error && classes.push("ta-color_error ta-color-text_on-error");

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  const action = (e) => {
    props.onClick({
      clientWidth: e.currentTarget.clientWidth,
      clientHeight: e.currentTarget.clientHeight,
      offsetLeft: e.currentTarget.offsetLeft,
      offsetTop: e.currentTarget.offsetTop,
      clientX: e.nativeEvent.clientX,
      clientY: e.nativeEvent.clientY,
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    });
  };

  return (
    <button onClick={props.onClick && action} className={buildClasses()}>
      <>
        {props.symbol && (
          <Icon
            symbol={props.symbol}
            {...props.iconProps}
            className="ta-chip_icon"
          />
        )}
        {props.children && (
          <label className="ta-chip_label">{props.children}</label>
        )}
        {props.input && <Icon symbol={props.input} className="ta-chip_input" />}
      </>
    </button>
  );
};

export default Chip;
