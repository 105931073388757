import React from "react";

const ProgressIndicator = (props) => {
  const buildClasses = () => {
    const classes = [];
    props.circular
      ? classes.push("ta-progress-circular")
      : classes.push("ta-progress-linear");
    props.className && classes.push(props.className);

    return classes.join(" ");
  };
  return props.circular ? (
    <div className={buildClasses()} style={props.style}>
      {props.percent !== undefined ? (
        <svg viewBox="28 28 44 44" style={{ transform: "rotate(-90deg)" }}>
          <circle
            style={{ strokeDasharray: props.percent * 1.25 + ", 200" }}
            className="ta-progress-circular_finite"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeMiterlimit="10"
          />
        </svg>
      ) : (
        <svg className="ta-progress-circular_icon" viewBox="28 28 44 44">
          <circle
            className="ta-progress-circular_path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeMiterlimit="10"
          />
        </svg>
      )}
    </div>
  ) : (
    <div className={buildClasses()}>
      {props.percent !== undefined ? (
        <div
          style={{ width: props.percent + "%" }}
          className="ta-progress-linear_finite"
        />
      ) : (
        <div className="ta-progress-linear_infinite" />
      )}
    </div>
  );
};

export default ProgressIndicator;
