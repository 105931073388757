import React, { useEffect, useState, useContext } from "react";
import TrackMarkerVisibilityContext from "../../../../Context/TrackMarkerVisibilityContext";
import TrackContext from "../../../../Context/TrackContext";
import ToggleButton from "./ToggleButton";

import { useTranslation } from "react-i18next";
import TrackVisiblityContext from "../../../../Context/TrackVisiblityContext";
import TracksVisiblityContext from "../../../../Context/TracksVisiblityContext";
import AllVisiblityContext from "../../../../Context/AllVisiblityContext";
import Divider from "../../../Trackalyze/Divider";
import Switch from "../../../Trackalyze/Switch";
import VisibilityButton from "./VisibilityButton";
import Button from "../../../Trackalyze/Button";
import RoundsContext from "../../../../Context/RoundsContext";

const TrackMarkerVisibilityLarge = () => {
  const { t } = useTranslation();
  const { mapRounds, setMapRounds } = useContext(RoundsContext);

  const { isTrackMarkerVisible, setTrackMarkerVisibility } = useContext(
    TrackMarkerVisibilityContext
  );
  const { isTrackVisible, setTrackVisibility } = useContext(
    TrackVisiblityContext
  );
  const { isTracksVisible, setTracksVisibility } = useContext(
    TracksVisiblityContext
  );

  const { isAllVisible, setAllVisibility } = useContext(AllVisiblityContext);

  const { track, tracks } = useContext(TrackContext);

  const [itemsList, setItemsList] = useState([]);

  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    const items = [];

    items.push({
      disabled: !isAllVisible,
      xsmall: true,
      action: () => setTrackVisibility(!isTrackVisible),
      symbol: "linear_scale",
      iconProps: { className: "ta-color-text_on-surface-low" },
      header: "Track",
      switch: true,
      controlValue: isTrackVisible,
    });

    if (tracks.length > 1) {
      items.push({
        disabled: !isAllVisible,
        xsmall: true,
        action: () => setTracksVisibility(!isTracksVisible),
        symbol: "public",
        iconProps: { className: "ta-color-text_on-surface-low" },
        header: "Tracks",
        switch: true,
        controlValue: isTracksVisible,
      });
    }

    if ("markerWaypoints" in track.$) {
      items.push({
        disabled: !("markerWaypoints" in track.$) || !isAllVisible,
        xsmall: true,
        action: () =>
          setTrackMarkerVisibility({
            ...isTrackMarkerVisible,
            waypoints: !isTrackMarkerVisible.waypoints,
          }),
        symbol: "marker_place",
        iconProps: { className: "ta-color-text_on-surface-low" },
        header: t("mapoverlay.trackmarker_waypoints"),
        switch: true,
        controlValue:
          "markerWaypoints" in track.$ && isTrackMarkerVisible.waypoints,
      });
    }

    items.push({
      disabled: !isAllVisible,
      xsmall: true,
      action: () =>
        setMapRounds({
          ...mapRounds,
          visible: !mapRounds.visible,
        }),
      symbol: "rounds",
      iconProps: { className: "ta-color-text_on-surface-low" },
      header: t("mapoverlay.trackrounds_visibility"),
      switch: true,
      controlValue: mapRounds.visible,
    });

    if ("markerStops" in track.$) {
      items.push({
        disabled: !("markerStops" in track.$) || !isAllVisible,
        xsmall: true,
        action: () =>
          setTrackMarkerVisibility({
            ...isTrackMarkerVisible,
            stops: !isTrackMarkerVisible.stops,
          }),
        symbol: "coffee",
        header: t("mapoverlay.trackmarker_stops"),
        switch: true,
        controlValue: "markerStops" in track.$ && isTrackMarkerVisible.stops,
      });
    }

    items.push({
      xsmall: true,
      action: () =>
        setTrackMarkerVisibility({
          ...isTrackMarkerVisible,
          directions: !isTrackMarkerVisible.directions,
        }),
      symbol: "track_direction",
      header: t("mapoverlay.trackmarker_direction"),
      switch: true,
      controlValue: isTrackMarkerVisible.directions,
      disabled: !isAllVisible,
    });

    items.push({
      xsmall: true,
      action: () =>
        setTrackMarkerVisibility({
          ...isTrackMarkerVisible,
          startStop: !isTrackMarkerVisible.startStop,
        }),
      symbol: "outlined_flag",
      header: t("mapoverlay.trackmarker_startstop"),
      switch: true,
      controlValue: isTrackMarkerVisible.startStop,
      disabled: !isAllVisible,
    });
    console.log("marker items", items);

    setItemsList(items);
  }, [
    isTrackMarkerVisible,
    isTrackVisible,
    isTracksVisible,
    isAllVisible,
    track,
    mapRounds,
  ]);
  return (
    <>
      <div
        className={
          "markervisibility_toggles ta-margin_left-xs  ta-elevation_shadow-1 -ta-padding_xxxs  ta-color_semi ta-shape_l ta-flex ta-flex--center ta-flex--itemscenter" +
          (!isAllVisible ? " xta-is-disabled" : "")
        }
      >
        <VisibilityButton xsmall elevation={false} tonal={false} semi />

        <div
          className={
            "ta-flex--itemscenter " +
            (isExpanded ? "ta-flex " : " ta-not-displayed")
          }
        >
          <Switch
            switched={isAllVisible}
            className="ta-margin_left-xxxs ta-margin_leftright-s"
            callBack={(value) => setAllVisibility(value)}
          />

          {itemsList.map((item, idx) => {
            return !item.divider ? (
              <ToggleButton
                key={"togglebutton" + idx}
                label={item.header}
                disabled={item.disabled || !isAllVisible}
                active={item.controlValue}
                symbol={item.symbol}
                onClick={() => {
                  item.action();
                }}
                small
              />
            ) : null;
          })}
        </div>
        <Button
          onClick={() => setIsExpanded(!isExpanded)}
          icon
          symbol={!isExpanded ? "keyboard_arrow_right" : "keyboard_arrow_left"}
        />
      </div>
    </>
  );
};

export default React.memo(TrackMarkerVisibilityLarge);
