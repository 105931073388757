import React from "react";

const Typo = (props) => {
  let tagName = "div";
  if (props.inline) {
    tagName = "span";
  } else if (props.level) {
    tagName = "h" + props.level;
  }

  const buildClasses = () => {
    const classes = [];
    props.className && classes.push(props.className);

    props.xHigh && classes.push("ta-typo--xhigh");
    props.sHigh && classes.push("ta-typo--shigh");
    props.noWrap && classes.push("ta-typeface--singleline");

    let typoClass = "ta-typeface_body";
    if (props.type) {
      typoClass = "ta-typeface_" + props.type;
    }
    if (props.size) {
      typoClass = typoClass + "-" + props.size;
    } else {
      typoClass = typoClass + "-l";
    }

    classes.push(typoClass);

    return classes.join(" ");
  };

  const TypoTag = ({ children, ...props }) => {
    return React.createElement(tagName, props, children);
  };

  return <TypoTag className={buildClasses()}>{props.children}</TypoTag>;
};

export default Typo;
