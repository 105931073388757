import React from "react";

const Divider = (props) => {
  const buildClasses = () => {
    const classes = ["ta-divider"];
    props.vertical && classes.push("ta-divider--vertical");

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <hr className={buildClasses()} style={props.style}>
      {props.children}
    </hr>
  );
};

export default Divider;
