import React, { useState } from "react";

import FilterWrapper from "./FilterWrapper";
import guiManager from "../../../utils/guiManager";
import Button from "../../../Trackalyze/Button";
import Badge from "../../../Trackalyze/Badge";
import BottomSheet from "../../../Trackalyze/BottomSheet";
import { useTranslation } from "react-i18next";

const FilterButton = (props) => {
  const { t } = useTranslation();

  const [animate, setAnimate] = useState(false);

  return (
    <>
      <Button
        className="ta-margin_leftright-xxs"
        symbol="filter_list"
        onClick={() => {
          setAnimate(true);
        }}
        outline
      >
        {t("trackmanager.filter_button")}
        {props.isFiltered && (
          <Badge className="ta-margin_left-xxs">
            {props.trackItems.length}
          </Badge>
        )}
      </Button>
      {guiManager(
        <BottomSheet
          zIndex={2401}
          animate={animate}
          onAfterAnimate={() => {
            setAnimate(false);
          }}
        >
          <FilterWrapper
            trackItems={props.trackItems}
            onClose={() => {
              setAnimate(false);
            }}
            isFiltered={props.isFiltered}
            filterItems={props.filterItems}
            onFilter={(filter) => props.onFilterItems(filter)}
          />
        </BottomSheet>,
        document.fullscreenElement ? "trackmap" : "root"
      )}
    </>
  );
};

export default React.memo(FilterButton);
