import React, { useEffect, useState, useContext } from "react";
import TrackMarkerVisibilityContext from "../../../../Context/TrackMarkerVisibilityContext";
import TrackContext from "../../../../Context/TrackContext";
import ToggleButton from "./ToggleButton";
import Icon from "../../../Trackalyze/Icon";
import Button from "../../../Trackalyze/Button";
import Typo from "../../../Trackalyze/Typo";
import { useTranslation } from "react-i18next";
import TrackVisiblityContext from "../../../../Context/TrackVisiblityContext";
import TracksVisiblityContext from "../../../../Context/TracksVisiblityContext";
import AllVisiblityContext from "../../../../Context/AllVisiblityContext";

const TrackMarkerVisibility = () => {
  const { t } = useTranslation();

  const { isTrackMarkerVisible, setTrackMarkerVisibility } = useContext(
    TrackMarkerVisibilityContext
  );
  const { isTrackVisible, setTrackVisibility } = useContext(
    TrackVisiblityContext
  );
  const { isTracksVisible, setTracksVisibility } = useContext(
    TracksVisiblityContext
  );

  const { isAllVisible, setAllVisibility } = useContext(AllVisiblityContext);

  const { track } = useContext(TrackContext);

  const [itemsList, setItemsList] = useState([]);

  useEffect(() => {
    const items = [];

    items.push({
      disabled: !isAllVisible,
      action: () => setTrackVisibility(!isTrackVisible),
      symbol: "linear_scale",
      iconProps: { className: "ta-color-text_on-surface-low" },
      header: "Track",
      switch: true,
      controlValue: isTrackVisible,
    });

    items.push({
      disabled: !isAllVisible,
      action: () => setTracksVisibility(!isTracksVisible),
      symbol: "public",
      iconProps: { className: "ta-color-text_on-surface-low" },
      header: "Tracks",
      switch: true,
      controlValue: isTracksVisible,
    });

    items.push({
      disabled: !("markerWaypoints" in track.$),
      action: () =>
        setTrackMarkerVisibility({
          ...isTrackMarkerVisible,
          waypoints: !isTrackMarkerVisible.waypoints,
        }),
      symbol: "marker_place",
      iconProps: { className: "ta-color-text_on-surface-low" },
      header: t("mapoverlay.trackmarker_waypoints"),
      switch: true,
      controlValue: isTrackMarkerVisible.waypoints,
    });

    items.push({
      disabled: !("markerStops" in track.$),
      action: () =>
        setTrackMarkerVisibility({
          ...isTrackMarkerVisible,
          stops: !isTrackMarkerVisible.stops,
        }),
      symbol: "coffee",
      header: t("mapoverlay.trackmarker_stops"),
      switch: true,
      controlValue: isTrackMarkerVisible.stops,
    });

    items.push({
      action: () =>
        setTrackMarkerVisibility({
          ...isTrackMarkerVisible,
          directions: !isTrackMarkerVisible.directions,
        }),
      symbol: "track_direction",
      header: t("mapoverlay.trackmarker_direction"),
      switch: true,
      controlValue: isTrackMarkerVisible.directions,
    });

    items.push({
      action: () =>
        setTrackMarkerVisibility({
          ...isTrackMarkerVisible,
          startStop: !isTrackMarkerVisible.startStop,
        }),
      symbol: "outlined_flag",
      header: t("mapoverlay.trackmarker_startstop"),
      switch: true,
      controlValue: isTrackMarkerVisible.startStop,
    });

    setItemsList(items);
  }, [
    isTrackMarkerVisible,
    isTrackVisible,
    isTracksVisible,
    isAllVisible,
    track,
  ]);
  return (
    <>
      <div
        onClick={() => setAllVisibility(!isAllVisible)}
        className="ta-flex ta-flex--itemscenter ta-margin_xxxs"
      >
        <div className="ta-flexitem ta-flex ta-flex--out ta-flex--itemscenter">
          <Typo className="ta-margin_left-xs">
            {t("mapoverlay.title_trackandmarker")}
          </Typo>

          <Button
            icon
            text={isAllVisible}
            symbol={isAllVisible ? "visibility" : "visibility_off"}
          />
        </div>
      </div>
      <div
        className={
          "ta-flex ta-flex--center ta-flex--wrap ta-margin_bottom-s " +
          (!isAllVisible ? "ta-is-disabled" : "")
        }
      >
        {itemsList.map((item, idx) => {
          return !item.divider ? (
            <ToggleButton
              key={"togglebutton" + idx}
              label={item.header}
              disabled={item.disabled}
              active={item.controlValue}
              symbol={item.symbol}
              onClick={() => {
                item.action();
              }}
              small={item.small ? true : false}
            />
          ) : null;
        })}
      </div>
      <div
        className={
          "ta-margin_bottom-xs " + (!isAllVisible ? "ta-is-disabled" : "")
        }
      ></div>
    </>
  );
};

export default React.memo(TrackMarkerVisibility);
