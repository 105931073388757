export const ACTIVITY_TYPES = [
  {
    id: "biking",
    label: "Biking",
    symbol: "type_biking",
  },
  { id: "hiking", label: "Hiking", symbol: "type_hiking" },
  { id: "walking", label: "Walking", symbol: "type_walking" },
  { id: "running", label: "Running", symbol: "type_running" },
];

const TRACK_CLASSIFICATION_TYPES = {
  biking: "type_biking",
  hiking: "type_hiking",
  walking: "type_walking",
  running: "type_running",
};
export default TRACK_CLASSIFICATION_TYPES;

export const TRACK_CLASSIFICATION_MAPPING = {
  cycling: "biking",
  biking: "biking",
  mountain_biking: "biking",
  hiking: "hiking",
  longDistanceHikingTrail: "hiking",
  longdistancehikingtrail: "hiking",
  hikingtourtrail: "hiking",
  walking: "walking",
  running: "running",
};
