import React, { useState, useEffect } from "react";

const Switch = (props) => {
  const [switched, setSwitched] = useState();
  useEffect(() => {
    if (switched !== undefined) {
      if (props.switched !== switched) {
        console.log("theme props.switched", props.switched);
        setSwitched(props.switched);
      }
    }
  }, [props.switched]);

  useEffect(() => {
    if (switched !== undefined) {
      if (props.callBack && props.switched !== switched) {
        console.log("theme switched", switched);

        props.callBack(switched);
      }
    } else if (props.switched) {
      setSwitched(props.switched);
    }
  }, [switched]);

  const buildClasses = () => {
    const classes = ["ta-switch"];
    props.disabled && classes.push("ta-is-disabled");

    props.className && classes.push(props.className);

    return classes.join(" ");
  };

  return (
    <div
      onClick={() => setSwitched(!switched)}
      className={buildClasses() + (switched ? " ta-switch--switched" : "")}
    />
  );
};

export default Switch;
