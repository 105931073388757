import React, { useState } from "react";

const TrackMarkerVisibilityContext = React.createContext();
export default TrackMarkerVisibilityContext;

export const TrackMarkerVisibilityProvider = (props) => {
  const [isTrackMarkerVisible, setTrackMarkerVisibility] = useState({
    waypoints: false,
    directions: true,
    stops: false,
    startStop: true,
    some: true,
  });

  return (
    <TrackMarkerVisibilityContext.Provider
      value={{
        isTrackMarkerVisible,
        setTrackMarkerVisibility,
      }}
    >
      {props.children}
    </TrackMarkerVisibilityContext.Provider>
  );
};
